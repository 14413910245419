.MobileAnnouncementCardContentWidget {
    height: 120px;

    color: var(--primaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: 0 4px 20px 0 rgba(37, 45, 50, 0.07);
    border-radius: 15px;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    transition: all ease 0.4s
}

.MobileAnnouncementCardContentWidget__leftContent {
    flex: 2 0 0;

    display: flex;
    flex-direction: column;
    height: 120px;

    opacity: .8;

    padding: 15px;
}

.MobileAnnouncementCardContentWidget__rightContent {
    flex: 1 0 0;
    height: 120px;
    overflow: hidden;
}

.MobileAnnouncementCardContentWidget__content {
    margin-top: 8px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileAnnouncementCardContentWidget__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileAnnouncementCardContentWidget__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}