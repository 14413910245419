.MobileWatchModelInfoWidget {
    padding: 0 15px;
}

.MobileWatchModelInfoWidget__wrapper {
    padding: 15px;

    border-radius: 16px;
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 0 30px;
    grid-gap: 10px;
}

.MobileWatchModelInfoWidget__wrapper.open {
    grid-auto-flow: row;
    grid-template-columns: auto;
}

.MobileWatchModelInfoWidget__title {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 10px;
    place-items: center start;
}

.MobileListingWatchInfoWidget__title > h6 {
    width: 100%;
}

.MobileWatchModelInfoWidget__infoSection {
    display: grid;
    overflow: hidden;

    max-height: 0;
}

.MobileWatchModelInfoWidget__infoSection.open {
    max-height: 1000px;

    transition: all .4s ease-in-out;
}

.MobileWatchModelInfoWidget__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid var(--altSeparatorColor);
    place-items: center start;
    padding: 8px 0;
}

.MobileWatchModelInfoWidget__info:last-child {
    border-bottom: none;
}

.MobileWatchModelInfoWidget__key,
.MobileWatchModelInfoWidget__value {
}

.MobileWatchModelInfoWidget__key {

}

.MobileWatchModelInfoWidget__value {

}

.MobileWatchModelInfoWidget__bottomSection {
    place-self: center end;

    transition: transform .25s ease-in-out;
}

.MobileWatchModelInfoWidget__bottomSection.open {
    transform: rotateZ(180deg);
}
