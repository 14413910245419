.WideSimpleCard {
    position: relative;

    color: var(--primaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    overflow: hidden;

    display: flex;
    align-items: center;

    padding: 10px;

    cursor: pointer;
}

.WideSimpleCard > :not(:last-child) {
    margin-right: 10px;
}

.WideSimpleCard__imgWrapper {
    display: grid;
    place-items: center;
}

.WideSimpleCard__img {
    height: 60px;
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: var(--borderRadiusCard);

    display: grid;
    place-items: center;
}

.WideSimpleCard__info {
    place-self: center start;
    display: grid;
    grid-gap: 3px;
}

.WideSimpleCard__description {
    opacity: 0.7;
}

.WideSimpleCard__content {
    margin-left: auto;
    display: grid;
    place-items: center;
    height: 100%;

    white-space: nowrap;
}