.MobileWatchModelDetailsWidget {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 10px;

    padding: 0 15px;
}

.MobileWatchModelDetailsWidget__socials {
    display: flex;
    margin-bottom: -5px;
    margin-left: 10px;
}

.MobileWatchModelDetailsWidget__social {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    margin-right: 15px;
}

.MobileWatchModelDetailsWidget__social > svg {
    display: grid;
    place-items: center;
}

.MobileWatchModelDetailsWidget > .MobileWatchModelDetailsWidget__details,
.MobileWatchModelDetailsWidget > .MobileWatchModelDetailsWidget__description {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 15px;
    display: grid;
    grid-gap: 5px;
    place-items: center;
}

.MobileWatchModelDetailsWidget__description {
    white-space: pre-wrap;
    padding: 20px;
}

.MobileWatchModelDetailsWidget > .MobileWatchModelDetailsWidget__details > * {
    text-align: center;
    line-height: 20px;
}

.MobileWatchModelDetailsWidget__details__name {
    font-weight: 700;
}

.MobileWatchModelDetailsWidget__details__currentPrice {
    line-height: 10px !important;
    margin-bottom: -6px;
}

.MobileWatchModelDetailsWidget__details__price {
    font-size: 20px;
    padding: 4px 0;
}

.MobileWatchModelDetailsWidget__brand {
}

.MobileWatchModelDetailsWidget__purchaseOptions {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 15px;
}

.MobileWatchModelDetailsWidget__purchaseOptions > div {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    width: 100%;
    text-align: center;

    transition: all .25s;
}

.MobileWatchModelDetailsWidget__purchaseOptions > div:hover,
.MobileWatchModelDetailsWidget__purchaseOptions > div:active,
.MobileWatchModelDetailsWidget__contactSeller:hover,
.MobileWatchModelDetailsWidget__contactSeller:active,
.MobileWatchModelDetailsWidget__placeBid:hover,
.MobileWatchModelDetailsWidget__placeBid:active {
    opacity: 0.8;
    cursor: pointer;
}

.MobileWatchModelDetailsWidget__contactSeller {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;
}

.MobileWatchModelDetailsWidget__placeBid {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    position: sticky;
    top: calc(54px + 10px);
    z-index: 1;
}

.MobileWatchModelDetailsWidget__seller__subscribeButton {
    background-color: var(--altBackgroundColor);
    border-radius: var(--borderRadiusCard);
    padding: 5px 15px;

    transition: all .25s;
}

.MobileWatchModelDetailsWidget__seller__subscribeButton:active,
.MobileWatchModelDetailsWidget__seller__subscribeButton:hover {
    opacity: 0.8;
    cursor: pointer;
}

.MobileWatchModelDetailsWidget__bookmarkActive {
    color: var(--altAccentColor);
}

.MobileWatchModelDetailsWidget__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;
    margin: 10px 0;
}
