/*
    MobileBrandsView
 */

.MobileBrandsLayout {
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileBrandsLayout__about {
    white-space: pre-wrap;
    padding-bottom: 50px;
}

/*
    BrandsContent
*/

.BrandsContent {
    padding: 20px;
}

/*
    BrandsHeader
*/

.BrandsHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 10px;
}

.BrandsHeader__info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    justify-items: center;

    place-self: center;
    text-align: center;
    margin-top: 20px;
}

.BrandsHeader__infoImage {
    max-width: 200px;
    max-height: 160px;

    padding: 20px;
    object-fit: contain;
}

.BrandsHeader__infoName {

}

.BrandsHeader__infoAlias {

}

.BrandsHeader__infoFriends {
    margin-top: 10px;
    padding: 5px 0;
    text-align: center;

    border-radius: var(--borderRadiusCard);
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
}

.BrandsHeader__infoYou {
    margin-top: 10px;
    padding: 5px 0;

    width: 10ch;
    text-align: center;

    border-radius: var(--borderRadiusCard);
    background-color: var(--loaderBackground);
    color: var(--secondaryBackgroundColor);
}

.BrandsHeader__infoChat {
	margin-top: 10px;
	padding: 5px 0;
	
	width: 10ch;
	text-align: center;
	
	border-radius: var(--borderRadiusCard);
	background-color: var(--accentColor);
	color: var(--secondaryBackgroundColor);
}

.BrandsHeader__backIcon {
    place-self: start;
    margin: 10px;
}

.BrandsHeader__moreIcon {
    place-self: start end;
    margin: 10px;
}

/*
    BrandsInfo
*/

.BrandsInfo {
    padding-top: 20px;
}

.BrandsInfoHeader {
    --BrandsInfoMaxHeight: 180px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;

    padding: 15px;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    max-height: var(--BrandsInfoMaxHeight);
    position: relative;
    overflow: hidden;

    transition: all .25s ease;
}

.BrandsInfoHeader.selected {
    max-height: 1000px;
}

.BrandsInfoHeader__website {
    line-height: 18px;
    word-wrap: break-word;
    color: var(--accentColor);

    transition: all .2s;
}

.BrandsInfoHeader__website:active {
    opacity: 0.6;
}

.BrandsInfoHeader__infoText {
    display: grid;
    gap: 5px;
    overflow: hidden !important;
    max-height: calc(var(--BrandsInfoMaxHeight) - 30px);

    mask-image: none;

    transition: all .25s ease;
}

.BrandsInfoHeader__infoText.selected {
    max-height: 1000px;

    mask-image: none;
}

.BrandsInfoHeader__infoText.fade {
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.BrandsInfoHeader__item {
    display: grid;
    grid-template-columns: 8ch 1fr;
    grid-gap: 10px;
}

.BrandsInfoHeader__bio {
    display: grid;
    grid-template-columns: 8ch 1fr;
    grid-gap: 10px;
    overflow: hidden;

    /* line-height of bio * lines to show */
    max-height: calc(18px * 5);

    transition: all .25s ease;
}

.BrandsInfoHeader__bio.selected {
    overflow: visible;
    max-height: 1000px;
}

.BrandsInfoHeader__key {
    color: var(--accentColor);
    line-height: 18px;
}

.BrandsInfoHeader__value {
    line-height: 18px;
    word-wrap: break-word;
}

.BrandsInfoHeader__buttonOptions {
    padding-top: 5px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: calc(var(--BrandsInfoMaxHeight) - 30px);

    transition: all .25s ease;
}

.BrandsInfoHeader__buttonOptions.selected {
    max-height: 1000px;
}

.BrandsInfoHeader__editIcon {
    place-self: start;
}

.BrandsInfoHeader__moreIcon {
    place-self: end;

    transition: all .25s ease;
}

.BrandsInfoHeader__moreIcon.selected {
    transform: rotateZ(180deg);
}

/**

    BrandsSocialButtons

 */

.BrandsSocialButtons {
    margin: 10px -20px 0;
}

.BrandsSocialButton {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 5px 15px;

    display: grid;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 10px;
}

.BrandsSocialButton > p {
    display: grid;
    place-items: center;
    place-self: start;
}

.BrandsSocialButton > svg {
    width: 18px;
    height: 18px;
    place-self: start;
}

/*
    BrandsInfoButtons
 */

.BrandsInfoButtons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 20px;
}

.BrandsInfoButton {
    display: grid;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    text-align: center;

    cursor: pointer;
}

.BrandsInfoButton.selected {
    color: var(--accentColor);
}

.BrandsInfoButton::before {
    content: "";
    padding-bottom: 100%;
    display: block;

    grid-area: 1 / 1 / 2 / 2;
}

.BrandsInfoButton__info {
    grid-area: 1 / 1 / 2 / 2;
    display: grid;
    place-items: center;
}

.BrandsInfoButton__infoWrapper {
    display: grid;
    place-items: center;
    grid-gap: 5px;
}

.BrandsInfoButton__name {
}

.BrandsInfoButton__amount {

}

/*
    BrandsItem
 */

.BrandsItem {
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 200px;
}

/*
    Feed
 */

.BrandsLibrarySection {
    padding: 20px;
    margin-bottom: 50px;
}

.BrandsLibrarySection h4 {
    margin-left: 10px;
}

.BrandsWatchesSection {
    padding: 20px;
    margin-bottom: 50px;

    display: grid;
    grid-gap: 20px;
}


.BrandsCollectorsSection {
    padding: 20px;
    margin-bottom: 50px;

    display: grid;
    grid-gap: 10px;
}

.BrandsSaleSection {
    margin-bottom: 50px;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-auto-flow: row dense;
}

.BrandsFeedSection {
    padding: 20px;
    margin-bottom: 50px;

    display: grid;
    grid-gap: 20px;
}

/*
    Brands Collector
 */

.BrandsCollector {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.BrandsCollector__image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: var(--borderRadiusCard);
}

.BrandsCollector__collected {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: auto;
}

.BrandsCollector__collected > svg {
    opacity: 0.8;
}
