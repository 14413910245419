
@keyframes expandWidth {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 1;
    }
}

.MobileToolbarLayout {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    z-index: 2;
    height: var(--toolbarHeight);
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(23, 1fr);
    place-items: center;
    backdrop-filter: blur(var(--mobileToolbarBackgroundBlur));
    box-shadow: 0 -4px 8px 9px rgba(0, 0, 0, 0.05);
    clip-path: inset(0px 0px -8px 0px);
}

.MobileToolbarTool {
    display: grid;
    place-items: center;
}

.ActionButtonWidget {
    grid-column: 2/4;
    cursor: pointer;
}

.ActionButtonWidget a {
    color: var(--secondaryColor);
}

.MobileToolbarActionButton {
    display: grid;
    place-items: center;
}

.ToolbarTitleWidget {
    grid-column: 4/21;
    color: var(--primaryTextColor);
    display: grid;
    place-items: center;
}

.ToolbarTitleWidget__logo {
    max-width: 160px;
    height: 100%;
}

.ToolbarTitleWidget__imgLogo {
    height: calc(var(--toolbarHeight) * 0.6);
    object-fit: contain;
}

.SearchBarWidget {
    width: 100%;
    height: var(--toolbarHeight);
    grid-column: 5/22;
    display: grid;
    place-items: center;
    color: var(--primaryTextColor);
    animation: expandWidth 200ms ease-in;
}

.SearchBarWidget .field, .SearchBarWidget .control {
    display: grid;
    place-items: center;
    width: 100%;
    margin: 0;
}

.SearchBarWidget input {
    width: 100%;
    background: var(--accentBackgroundColor);
}

.SearchWidget {
    grid-column: 21/23;
}

.SearchWidget svg {
    cursor: pointer;
}

