
.MobileWatchVaultLoaderLayout {
}

.MobileWatchVaultLoaderWidget {
    z-index: 15;
}

.MobileWatchVaultLoaderContainer {
    display: grid;
    place-items: center;
    grid-gap: 10px;
}

.MobileWatchVaultLoaderBrandElement{
    position: absolute;
    display: grid;
    background: #000000;
    place-items: center;
    color: white;
    inset: 0;
    padding: 20%;
    /*margin-bottom: 15px;*/
}

.MobileWatchVaultLoaderBrandElement img {
    position: relative;
    width: 100%;
    max-width: 250px;
}
