
.SimpleMenuEntry {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 4fr 3fr;
    height: 50px;
    cursor: pointer;

}

.SimpleMenuEntry__icon {
    grid-column: 1/2;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    color: var(--secondaryColor);
}

.SimpleMenuEntry__icon svg {
    transform: scale(1.2);
}

.SimpleMenuEntry__title {
    grid-column: 2/6;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.SimpleMenuEntry__title h3 {
    font-size: 20px;
    color: var(--primaryTextColor);
}

.SimpleMenuEntry__appendix {
    grid-column: 6/8;
    display: grid;
    place-items: center;
}
