.MobileOfferingNegotiationWidget {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background-color: white;
    border: 4px solid #ffcc00;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileOfferingNegotiationWidget__offer {
    display: grid;
    grid-gap: 5px;
}

.MobileOfferingNegotiationWidget__divider {
    width: 80%;
    opacity: 0.8;
    height: 1px;
    background-color: var(--altSeparatorColor);
}

.MobileOfferingNegotiationWidget__buttons {
    display: flex;
    gap: 10px;
}

.MobileOfferingNegotiationWidget__buttons > div {
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.MobileOfferingNegotiationWidget__buttons > div:active {
    transform: scale(0.87);
}

.MobileOfferingNegotiationWidget__accept {
    background-color: var(--acceptColor);
    color: white;
}

.MobileOfferingNegotiationWidget__deny {
    background-color: var(--denyColor);
    color: white;
}

.MobileOfferingNegotiationWidget__counter {
    background-color: var(--primaryBackgroundColor);
}
