.MobileVaultWatchLayout {
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileVaultWatchLayout__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;

    opacity: 0.5;
}

.MobileVaultWatchLayout__modifications {
    padding: 15px;
    display: grid;
    grid-gap: 20px;
}

.MobileVaultWatchLayout__modificationsTitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
}

.MobileVaultWatchLayout__sellButton {
     margin: 0 15px;
     padding: 10px 20px;
     text-align: center;
     color: white;
     background-color: var(--accentColor);
     border-radius: var(--borderRadiusCard);
     box-shadow: var(--cardBoxShadow);

     transition: all .2s;
 }

.MobileVaultWatchLayout__sellButton:active {
    opacity: 0.7;
}

.MobileVaultWatchLayout__delete {
    padding: 10px 20px;
    text-align: center;
    color: white;
    background-color: var(--incorrectInputRed);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.MobileVaultWatchLayout__delete:active {
    opacity: 0.7;
}

.MobileVaultWatchLayout__reUpload {
    padding: 10px 20px;
    text-align: center;
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.MobileVaultWatchLayout__reUpload:active {
    opacity: 0.7;
}

.MobileVaultWatchLayout__message {
    display: grid;
    grid-gap: 10px;

    margin: 0 15px;
}
