.MobileWatchAnalyticsTimespanWidget {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
}

.MobileWatchAnalyticsTimespanWidget__time {
    transition: all .2s;
    border-radius: 10px;
    display: grid;
    place-items: center;
    width: 40px;
    height: 30px;
}

.MobileWatchAnalyticsTimespanWidget__time.selected {
    background-color: rgba(24, 160, 251, 0.2);
}

.MobileWatchAnalyticsTimespanWidget__time.disabled {
    pointer-events: none;
    opacity: 0.4;
}