/*
    MobileProfileView
 */

.MobileProfileLayout {
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

/*
    ProfileContent
*/

.ProfileContent {
    padding: 20px;
}

/*
    ProfileHeader
*/

.ProfileHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ProfileHeader__info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    justify-items: center;

    place-self: center;
    text-align: center;
    margin-top: 20px;
}

.ProfileHeader__infoImage {
    width: 112px;
    height: 112px;

    border-radius: 35px;
    padding: 2px;
    border: 3px solid var(--loaderBackground);
    object-fit: cover;

    text-align: center;
}

.ProfileHeader__infoImage.online {
    border: 3px solid var(--profileActiveColor);
}

.ProfileHeader__infoName {
}

.ProfileHeader__infoAlias {

}

.ProfileHeader__infoButtons {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	margin-top: 10px;
}

.ProfileHeader__infoFriends {
	white-space: nowrap;
    padding: 5px 10px;
	
    text-align: center;
	
	border-radius: var(--borderRadiusCard);
	background-color: var(--secondaryBackgroundColor);
}

.ProfileHeader__infoMakeFriends {
	white-space: nowrap;
	padding: 5px 10px;
	
	text-align: center;
	
	border-radius: var(--borderRadiusCard);
	background-color: var(--accentColor);
	color: var(--secondaryBackgroundColor);
}

.ProfileHeader__infoYou {
    padding: 5px 0;

    width: 10ch;
    text-align: center;

    border-radius: var(--borderRadiusCard);
    background-color: var(--loaderBackground);
    color: var(--secondaryBackgroundColor);
}

.ProfileHeader__infoChat {
	padding: 5px 0;
	
	width: 13ch;
	text-align: center;
	
	border-radius: var(--borderRadiusCard);
	background-color: var(--secondaryBackgroundColor);
}

.ProfileHeader__backIcon {
    place-self: start;
    margin: 10px;
}

.ProfileHeader__moreIcon {
    place-self: start end;
    margin: 10px;
}

/*
    ProfileInfo
*/

.ProfileInfo {
    padding-top: 20px;
}

.ProfileInfoHeader {
    --ProfileInfoMaxHeight: 180px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;

    padding: 15px;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    max-height: var(--ProfileInfoMaxHeight);
    position: relative;
    overflow: hidden;

    transition: all .25s ease;
}

.ProfileInfoHeader.selected {
    max-height: 1000px;
}

.ProfileInfoHeader__infoText {
    display: grid;
    gap: 5px;
    overflow: hidden !important;
    max-height: calc(var(--ProfileInfoMaxHeight) - 30px);

    mask-image: none;

    transition: all .25s ease;
}

.ProfileInfoHeader__infoText.selected {
    max-height: 1000px;

    mask-image: none;
}

.ProfileInfoHeader__infoText.fade {
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.ProfileInfoHeader__item {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.ProfileInfoHeader__bio {
    display: grid;
    grid-template-columns: 1fr 5fr;
    overflow: hidden;

    /* line-height of bio * lines to show */
    max-height: calc(18px * 5);

    transition: all .25s ease;
}

.ProfileInfoHeader__bio.selected {
    overflow: visible;
    max-height: 1000px;
}

.ProfileInfoHeader__key {
    color: var(--accentColor);
    line-height: 18px;
}

.ProfileInfoHeader__value {
    line-height: 18px;
    word-wrap: break-word;
}

.ProfileInfoHeader__buttonOptions {
    padding-top: 5px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: calc(var(--ProfileInfoMaxHeight) - 30px);

    transition: all .25s ease;
}

.ProfileInfoHeader__buttonOptions.selected {
    max-height: 1000px;
}

.ProfileInfoHeader__editIcon {
    place-self: start;
}

.ProfileInfoHeader__moreIcon {
    place-self: end;

    transition: all .25s ease;
}

.ProfileInfoHeader__moreIcon.selected {
    transform: rotateZ(180deg);
}

/*
    ProfileInfoButtons
 */

@media only screen and (max-width: 350px) {
    .ProfileInfoButtons {
        gap: 10px !important;
    }

    .ProfileInfoButton__name {
        font-size: 9px;
    }

    .ProfileInfoButton__amount {
        font-size: 13px;
    }

    .ProfileInfoButton {
        border-radius: 30% !important;
    }
}

.ProfileInfoButtons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 20px;
}

.ProfileInfoButton {
    display: grid;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    text-align: center;

    cursor: pointer;
}

.ProfileInfoButton.selected {
    color: var(--accentColor);
}

.ProfileInfoButton::before {
    content: "";
    padding-bottom: 100%;
    display: block;

    grid-area: 1 / 1 / 2 / 2;
}

.ProfileInfoButton__infoWrapper {
    display: grid;
    place-items: center;
    grid-gap: 5px;
}


.ProfileInfoButton__info {
    grid-area: 1 / 1 / 2 / 2;
    display: grid;
    place-items: center;
}

.ProfileInfoButton__name {
}

.ProfileInfoButton__amount {

}

/*
    ProfileItem
 */

.ProfileItem {
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 200px;
}

/*
    Feed
 */

.Vault {
    padding: 20px;
}

.Vault__gridView {
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-auto-flow: row dense;
    padding-bottom: 100px;
}

.Posts {
    padding: 20px;
}

.Friends {
    padding: 20px;
}

.Friends__requests {
    display: grid;
    place-items: center;
    position: relative;

    background-color: var(--secondaryBackgroundColor);
    padding: 15px;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.Friends__requests > svg {
    position: absolute;
    place-self: center start;
    margin-left: 20px;
}

.Groups {
    padding: 20px;
}

.Feed {
    padding-top: 20px;
}

.Feed__updateTitle, .Feed__updateTimestamp {
    margin-left: 30px;
}

.Feed .MobilePostCardLayout {
    margin: 20px;
}

/**
    ProfileFriendRequest
 */

.ProfileFriendRequest {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.ProfileFriendRequest__img {
    width: 50px;
    height: 50px;
    border-radius: var(--borderRadiusCard);
    object-fit: cover;
}

.ProfileFriendRequest__title {
    font-weight: var(--fontWeightSemiBold);
}

.ProfileFriendRequest__details {
    display: grid;
    grid-gap: 3px;
}

.ProfileFriendRequest__details > p {

}

.ProfileFriendRequest__resolution {
    margin-left: auto;
    margin-right: 10px;
    display: grid;
    place-items: center;
    padding: 5px;
    background-color: var(--denyColor);
    color: white;
    border-radius: 50%;
}

.ProfileFriendRequest__resolution > svg {
    width: 14px;
    height: 14px;
}

.ProfileFriendRequest__resolution.accept {
    background-color: var(--acceptColor);
}

.ProfileFriendRequest__buttons {
    margin-left: auto;
    display: flex;
    gap: 10px;
    place-items: center end;
}

.ProfileFriendRequest__button {
    --acceptColor: #06c666;
    --denyColor: #f93939;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;

    color: white;

    transition: all .2s;
}

.ProfileFriendRequest__button:active {
    transform: scale(0.9);
    filter: brightness(0.9);
}

.ProfileFriendRequest__button.accept {
    background-color: var(--acceptColor);
}

.ProfileFriendRequest__button.deny {
    background-color: var(--denyColor);
}
