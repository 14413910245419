.MobileFullscreenTabListLayout {
    --TabButtonsHeight: 50px;
    --TabHighlightPadding: 5px;

    position: absolute;
    inset: 0;
    top: 100vh;
    background-color: var(--primaryBackgroundColor);

    visibility: hidden;

    padding: 20px;

    transition: all .25s ease-in-out;
}

.MobileFullscreenTabListLayout.active {
    visibility: visible;
    top: 0;
}

.FullscreenTabList__TabButtons__TabMenuWrapper {
    display: grid;
    grid-template-columns: 1fr 5fr;

    height: var(--TabButtonsHeight);
}

.FullscreenTabList__TabButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: var(--TabButtonsHeight);
    background-color: white;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.FullscreenTabList__TabButtons__backButton {
    width: 100%;
    height: 100%;

    display: grid;
    place-items: start;
}

.FullscreenTabList__TabButtons__backButtonIcon {
    width: var(--TabButtonsHeight);
    height: var(--TabButtonsHeight);

    border-radius: 50%;

    padding: 15px;

    background-color: var(--secondaryBackgroundColor);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.FullscreenTabList__TabButtons__TabHighlight {
    position: relative;
    margin-top: calc(calc(-1 * var(--TabButtonsHeight)) + var(--TabHighlightPadding));
    height: calc(var(--TabButtonsHeight) - calc(var(--TabHighlightPadding) * 2));

    transition: .35s ease;

    border-radius: 25px;
    background-color: var(--accentColor);
}

.FullscreenTabList__TabButtons__TabButtonsWrapper {
    position: fixed;
    inset: 20px;
    z-index: 1;
    top: calc(-1 * var(--TabButtonsHeight) - 10px);
    height: var(--TabButtonsHeight);

    opacity: 0;

    transition: all .25s ease-in-out;
}

.FullscreenTabList__TabButtons__TabButtonsWrapper.selected {
    opacity: 1;
}

.FullscreenTabList__TabButtons__TabButtonsWrapper.showTabs {
    top: calc(20px + env(safe-area-inset-top, 0));
}

.FullscreenTabList__Tab {
    color: var(--primaryTextColor);
    text-align: center;
    width: 100%;
    z-index: 1;

    transition: .35s ease;

    cursor: pointer;
}

.FullscreenTabList__Tab.selected {
    color: white;
}

.FullscreenTabList__Tab.locked {
    color: var(--secondaryColor);
    cursor: not-allowed;
}

.FullscreenTabList__TabPage {
    width: 100%;

    margin-top: calc(var(--TabButtonsHeight) + env(safe-area-inset-top, 0));

    animation: FadeIn 300ms ease-in;
}

.FullscreenTabList__TabPages {
    width: 100%;
    z-index: 10;
}