.MobileCommunityPostLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileCommunityPostLayout__wrapper {
    padding: 10px;
    margin-bottom: 70px;
}


/**

    Comment Input

 */

.CommunityPostCommentInput {
    z-index: 2;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;

    /*
    background-color: rgba(230, 230, 230, 0.7);
    backdrop-filter: blur(8px);
    */
}

.CommunityPostCommentInput__inputWrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 10px 10px 25px;
}

.CommunityPostCommentInput__input {
    width: 100%;
    font-size: 18px;
    padding: 10px 15px;
    outline: none;
    border: none;
    border-radius: var(--borderRadiusCard);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    transition: all .2s;
}

.CommunityPostCommentInput__input.error {
    outline: 2px solid var(--incorrectInputRed);
}

.CommunityPostCommentInput__error {
    padding: 15px;
    position: absolute;
    bottom: 65px;
    opacity: 0.5;
}

.CommunityPostCommentInput__button {
    display: grid;
    place-items: center;

    min-width: 46px;
    min-height: 46px;
    border-radius: 50%;
    background-color: var(--secondaryBackgroundColor);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    margin-left: auto;

    transition: all .1s;
}

.CommunityPostCommentInput__button > svg {
    opacity: 0.8;
}

.CommunityPostCommentInput__button:active {
    filter: brightness(0.9);
}

.CommunityPostCommentInput__uploadedFiles {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    position: absolute;
    bottom: 100%;
    padding: 10px 10px 10px 20px;
    width: 100%;

    background-color: var(--accentColor);
    color: white;
}

.CommunityPostCommentInput__uploadedFilesCancel {
    place-self: end;
    width: 40px !important;
    height: 40px !important;
    padding: 5px;
}
