


.MobileRecoverPasswordDetailsWidget {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.MobileRecoverPasswordDetailsWidget--wrapper {
    width: 100%;
    min-height: calc(100vh - env(safe-area-inset-top, 0));
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordDetailsWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordDetailsWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}

.MobileRecoverPasswordDetailsWidget__header {

}

.MobileRecoverPasswordDetailsWidget label {
    opacity: 0;
}

.MobileRecoverPasswordDetailsWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin: 0 0 90px 0;
}

.MobileRecoverPasswordDetailsWidget p {
    text-align: center;
}

.MobileRecoverPasswordDetailsWidget__info {
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordDetailsWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
    margin: 10px 0;
}

.MobileRecoverPasswordDetailsWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.09);
    margin: 8px 0;
}

.MobileRecoverPasswordDetailsWidget .submitBtn {
    color: white;
    background: var(--accentColor);
    border: var(--accentColor) 3px solid;
}

.MobileRecoverPasswordDetailsWidget .backBtn {
    color: var(--primaryTextColor);
    background: var(--primaryBackgroundColor);
    border: none;
}

.MobileRecoverPasswordDetailsWidget__buttons--wrapper {
    margin-top: 60px;
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordDetailsWidget__appendedInfo {
    display: grid;
    place-items: center;
    color: var(--secondaryTextColor);
}
