.MobilePromptMessageWrapper {
    --promptMenuBorderRadius: 15px;

    touch-action: none;

    visibility: hidden;
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    transition: all .25s ease;
    backdrop-filter: blur(3px) opacity(0);
    display: grid;
    place-items: center;
}

.MobilePromptMessageWrapper.active {
    visibility: visible;

    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px) opacity(1);
}

.MobilePromptMessageWrapper__buttonWrapper {
    padding: 30px;
    box-shadow: var(--cardBoxShadow);
    overflow: hidden;
    opacity: 0;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    z-index: 10;
    min-width: 70%;
    max-width: 80%;

    border-radius: var(--promptMenuBorderRadius);
    background-color: var(--primaryBackgroundColor);

    transition: all .25s ease-in-out;
}

.MobilePromptMessageWrapper__buttonWrapper.active {
    opacity: 1;
}

.MobilePromptMessageWrapper__title {
    width: 100%;
    color: var(--primaryColor);
    text-align: center;
    margin-bottom: 10px;
}

.MobilePromptMessageWrapper__description {
    width: 100%;
    color: var(--primaryColor);
    text-align: center;
    white-space: pre-wrap;

    margin-bottom: 10px;
}

.MobilePromptMessageWrapper__buttonGroup {
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.MobilePromptMessageButton {
    text-align: center;

    padding: 10px 10px;

    border-radius: var(--borderRadiusRounded);
    box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.04);

    width: 100%;
    height: 100%;

    transition: all .25s;
}

.MobilePromptMessageButton:hover {
    opacity: 0.8;
}
