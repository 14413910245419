.MobileWatchModelLayout {
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileWatchModelLayout__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;

    opacity: 0.5;
}

.MobileWatchModelLayout__modifications {
    padding: 15px;
    display: grid;
    grid-gap: 20px;
}

.MobileWatchModelLayout__modificationsTitle {
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
}

.MobileWatchModelLayout__sellButton {
    margin: 0 15px;
    padding: 10px 20px;
    text-align: center;
    color: white;
    background-color: var(--accentColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.MobileWatchModelLayout__sellButton:active {
    opacity: 0.7;
}

.MobileWatchModelLayout__message {
    margin: 0 15px;
}
