

.MobileCompactWritePostLayout {
    --MobileCompactWritePostLayoutHeight: 37px;


    background: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    display: flex;

    border-radius: var(--borderRadiusRounded);
    overflow: hidden;
    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.MobilePostInputFieldUserWidget {
    display: grid;
    place-items: center;
}

.MobilePostInputFieldUserWidget__img {
    height: var(--MobileCompactWritePostLayoutHeight);
    width: var(--MobileCompactWritePostLayoutHeight);
    margin: 5px;
    overflow: hidden;
    border-radius: var(--borderRadiusRounded);
}

.MobilePostInputFieldUserWidget__img img {
    object-fit: cover;
    width: 100%;
    min-height: var(--MobileCompactWritePostLayoutHeight);
}

.MobileInputFieldWidget {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 5px;
}

.MobileInputFieldWidget p {
    flex: 1;
    margin-left: 5px;
}

.MobileInputFieldWidget__plusIcon {
    height: var(--MobileCompactWritePostLayoutHeight);
    display: grid;
    place-items: center;
    margin-right: 10px;
}
