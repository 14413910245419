

.MobileHelpFaqWidget {
    width: 100%;

    padding: 10px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 10px;

    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);

    padding-bottom: 100px;
}

.MobileHelpQuestionAnswerWidget {
    width: 100%;
    height: 74px;
    overflow: hidden;
    background: var(--secondaryBackgroundColor);
    padding: 20px;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    cursor: pointer;
}

.MobileHelpQuestionAnswerWidget.expanded {
    height: auto;
}

.MobileHelpQuestionAnswerWidget__icon--wrapper {
    width: 100%;
    display: grid;
    place-items: center end;
}

.MobileHelpQuestionAnswerWidget__icon {
    position: relative;
    top: -100%;
    transition: transform .25s ease-in-out;
}

.MobileHelpQuestionAnswerWidget__icon.expanded {
    position: static;
    transform: rotateZ(180deg);
}

.MobileHelpQuestionAnswerWidget__question {
    height: 34px;
    width: 95%;
    overflow: hidden;
}

.MobileHelpQuestionAnswerWidget__question p {
    font-weight: bold;
}

.MobileHelpQuestionAnswerWidget__question.expanded {
    height: auto;
    overflow: visible;
}

.MobileHelpQuestionAnswerWidget__answer {
    margin-top: 15px;
    padding-top: 15px;
    border-top: var(--primaryBackgroundColor) 1px solid;
    width: 95%;
}
