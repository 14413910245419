.MobileListWatchLayout__button {
    padding: 10px 20px;
    background-color: var(--accentColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    color: white;
    display: grid;
    place-items: center;

    transition: all .2s;
}

.MobileListWatchLayout__button:active {
    opacity: 0.7;
}

.MobileListWatchLayout__noWatches {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    padding: 20px;
    text-align: center;
}