.MobileEditProfileLayout {
    padding: 20px;

    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

/*
    EditProfileHeader
*/

.EditProfileHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.EditProfileHeader__info {
    position: relative;
    place-self: center;
    text-align: center;
    margin-top: 20px;
    width: 112px;
    height: 112px;
}

.EditProfileHeader__infoImage {
    width: 112px;
    height: 112px;

    object-fit: cover;
    border-radius: 35px;
    padding: 2px;
    border: 3px solid var(--primaryColor);
}

.EditProfileHeader__backIcon {
    place-self: start;
    margin: 10px;
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
}

.EditProfileHeader__checkIcon {
    place-self: start end;
    margin: 10px;
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
    color: var(--secondaryBackgroundColor);
    background-color: var(--accentColor);
    border-radius: 50%;
}

.EditProfileHeader__uploadImage {
    --uploadButtonSize: 35px;

    background-color: var(--secondaryBackgroundColor);
    padding: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--uploadButtonSize);
    height: var(--uploadButtonSize);
    margin-right: -10px;
    margin-bottom: -10px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: var(--primaryColor);
    cursor: pointer;
}

.EditProfileHeader__clearImage {
    --clearButtonSize: 30px;

    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    position: absolute;
    top: 0;
    right: 0;
    width: var(--clearButtonSize);
    height: var(--clearButtonSize);
    margin-right: -5px;
    margin-top: -5px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: var(--secondaryBackgroundColor);
    cursor: pointer;
}

#EditProfileHeader__fileUpload {
    width: 0;
    height: 0;
    visibility: hidden;
}

/*
    EditProfileField
 */

.EditProfileField {
    width: 100%;
}

.EditProfileField__label {
    margin-bottom: 10px;
}

.EditProfileField__input {
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.EditProfileField__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
}
.EditProfileField__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.EditProfileField__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}
.EditProfileField__multilineWrapper > .EditProfileField__multiline,
.EditProfileField__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    color: #363636;

    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}

.EditProfileField__multiline:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
}

/*
    EditProfileButtons
 */

.EditProfileButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
}

.EditProfileButtons__button {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    border: none;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.EditProfileButtons__button:hover {
    opacity: 0.7;
}

.EditProfileButtons__button:hover {
    opacity: 0.7;
}