.MobileSearchLayout {
    padding-bottom: calc(20px + var(--mobileNavbarHeightIOS));
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileSearchLayout__contentWrapper {
    height: 100%;
    background-color: var(--primaryBackgroundColor);
}

/**

    RECENT SEARCHES

 */

.MobileSearchRecentWidget {
    margin: 10px;
    padding: 20px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileSearchRecentWidget__searchHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    opacity: 0.8;

    border-bottom: 1px solid var(--primaryBackgroundColor);
    padding-bottom: 5px;
}

.MobileSearchRecentWidget__searchWrapper {
    display: grid;
    grid-gap: 10px;

    padding: 10px 0;
}

.MobileSearchRecentWidget__search {
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;
}

.MobileSearchRecentWidget__search > svg {
    width: 20px;
    height: 20px;
}

/**
    Recently viewed
 */

.MobileSearchRecentlyViewedWidget {
    margin: 10px;
    padding: 20px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileSearchRecentlyViewedWidget__searchHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    opacity: 0.8;

    border-bottom: 1px solid var(--primaryBackgroundColor);
    padding-bottom: 5px;
}

.MobileSearchRecentlyViewedWidget__searchWrapper {
    display: grid;
    grid-gap: 10px;

    padding: 10px 0;
}

.MobileSearchRecentlyViewedWidget__search {
    display: flex;
    gap: 10px;
    align-items: center;
}

.MobileSearchRecentlyViewedWidget__img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: contain;
}

.MobileSearchRecentlyViewedWidget__search > svg {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

/**

    FILTER WIDGET

 */

.MobileSearchFilterOptionWidget {
    display: grid;
    place-items: center;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 5px 10px;

    transition: all .25s;
}

.MobileSearchFilterOptionWidget.active {
    background-color: var(--accentColor);
    color: white;
}

/**

    RESULTS WIDGET

 */

.MobileSearchResultsWidget {
    display: grid;
    grid-gap: 10px;

    padding: 10px;
}

/**

    RESULT WIDGET

 */

.MobileSearchResultWidget {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 15px;

    display: grid;
}

.MobileSearchResultWidget__categoryInformation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px;
}

.MobileSearchResultWidget__filter {
}

.MobileSearchResultWidget__matches {
    display: grid;
}

.MobileSearchResultWidget__match {
    display: flex;
    align-items: center;

    padding: 5px;
}

.MobileSearchResultWidget__match :not(:last-child) {
    margin-right: 10px;
}

.MobileSearchResultWidget__contentWrapper {
    display: grid;
    grid-gap: 2px;
}

.MobileSearchResultWidget__matchImage {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 6px;
}

.MobileSearchResultWidget__matchTitle {
    display: grid;
    line-height: 100%;
}

.MobileSearchResultWidget__matchDescription {
    opacity: 0.8;
}

.MobileSearchResultWidget__matchSubTitle {
    display: grid;
    place-items: center;
    padding: 5px 8px;

    background-color: var(--accentColor);
    border-radius: 5px;
    color: white;
}

.MobileSearchResultWidget__more {
    display: grid;
    grid-auto-flow: column;
    place-content: space-between;
    align-items: center;
    padding: 10px 5px 0 5px;
}

.MobileSearchResultWidget__seeMore {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 5px;

    color: var(--accentColor);
}

.MobileSearchResultWidget__seeMore > svg {
    width: 14px;
    height: 14px;
}
