.MobileSocialLoungeNavSectionWidget {
    position: relative;
    background-color: var(--secondaryBackgroundColor);

    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    transition: all .15s;
}

.MobileSocialLoungeNavSectionWidget > svg {
    opacity: 0.8;
}

.MobileSocialLoungeNavSectionWidget.selected {
    background-color: var(--accentColor);
    color: white;
}

.MobileSocialLoungeNavSectionWidget.selected > svg {
    opacity: 1;
}

.MobileSocialLoungeNavSectionWidget__alert {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;

    display: grid;
    place-items: center;
    background-color: var(--incorrectInputRed);
    color: white;
    border-radius: 50%;
}