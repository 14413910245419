.MobileBidsLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileBidsLayout__wrapper {
    padding: 20px;
}

.MobileBidsLayout__bidInformation {
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    background-color: var(--secondaryBackgroundColor);
}

.MobileBidsLayout__bidDetails {
    display: grid;
    grid-template-columns: 70px 2fr;
    grid-gap: 15px;
    padding: 15px 15px 10px;
}

.MobileBidsLayout__bidDetailsImg {
    background-color: var(--primaryBackgroundColor);
    border-radius: 8px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 120px;
}

.MobileBidsLayout__bidDetailsText {
    display: grid;
    place-items: center start;
    grid-gap: 6px;
}

.MobileBidsLayout__bidDivider {
    width: 100%;
    height: 2px;
    background-color: var(--primaryBackgroundColor);
}

.MobileBidsLayout__bidSummary {
    display: grid;
    grid-gap: 5px;
    padding: 10px 15px 15px;
}

.MobileBidsLayout__bidSummaryInfo {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.MobileBidsLayout__bids {
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    background-color: var(--secondaryBackgroundColor);
}

.MobileBidsLayout__bid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.MobileBidsLayout__bid:not(:last-child) {
    border-bottom: 2px solid var(--primaryBackgroundColor);
}

.MobileBidsLayout__details {
    margin: 15px 0 15px 15px;
}

.MobileBidsLayout__time {
    place-self: center end;
    margin-right: 15px;
}

.MobileBidsLayout__mostRecentBids {
    margin-left: 10px;
}

.MobileBidsLayout__detailsText {
    color: var(--secondaryTextColor);
}

.MobileBidsLayout__alias {
    color: var(--accentColor);
}