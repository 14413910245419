.MobilePaginatedListLayout {
    display: grid;
    overflow: scroll;

    place-content: start;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.MobilePaginatedListLayout::-webkit-scrollbar {
    display: none;
}