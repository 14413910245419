.MobileCommentActionButtonsWidget {
    display: flex;
}

.MobileCommentActionButtonsWidget__actionButton {
    padding: 5px;
    cursor: pointer;
}

.MobileCommentActionButtonsWidget__likes {
    display: flex;
    align-items: flex-start;
    margin-left: 3px;
}

.MobileCommentActionButtonsWidget__likes svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;
}

.isActive {
    color: var(--accentColor);
}