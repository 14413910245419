.MobileTabListLayout {
    --TabButtonsHeight: 50px;
    --TabHighlightPadding: 5px;

    width: 100%;
    overflow: hidden;
    min-height: var(--TabButtonsHeight);
}

.TabList__TabButtons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: var(--TabButtonsHeight);
    background-color: white;
    border-radius: 25px;
    box-shadow: var(--cardBoxShadow);
}

.TabList__TabButtons__TabHighlight {
    position: relative;
    margin-top: calc(calc(-1 * var(--TabButtonsHeight)) + var(--TabHighlightPadding));
    height: calc(var(--TabButtonsHeight) - calc(var(--TabHighlightPadding) * 2));

    transition: .35s ease;

    border-radius: 25px;
    background-color: var(--accentColor);
}

.TabList__Tab {
    color: var(--primaryTextColor);
    text-align: center;
    z-index: 1;
    width: 100%;

    transition: .35s ease;

    cursor: pointer;
}

.TabList__Tab.selected {
    color: white;
}

.TabList__Tab.locked {
    color: var(--secondaryColor);
    cursor: not-allowed;
}

.TabList__TabPage {
    width: 100%;

    animation: FadeIn 300ms ease-in;
}

.TabList__TabPages {
    width: 100%;
}