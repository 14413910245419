.MobileNewsCardContentWidget {
    min-height: 120px;
    overflow: hidden;

    color: var(--primaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: 0 4px 20px 0 rgba(37, 45, 50, 0.07);
    border-radius: 15px;

    display: flex;
    flex-direction: row;

    transition: all ease 0.4s
}

.MobileNewsCardContentWidget__leftContent {
    flex: 2 0 0;

    display: flex;
    flex-direction: column;
    height: 120px;

    padding: 8px 16px;
}

.MobileNewsCardContentWidget__rightContent {
    flex: 1 0 0;
    height: 120px;

    background-color: black;
    overflow: hidden;
}

.MobileNewsCardContentWidget__content {
    margin-top: 8px;
    max-width: 30ch;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileNewsCardContentWidget__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileNewsCardContentWidget__footer {
    margin-top: auto;
    display: flex;
    gap: 5px;
}

.MobileNewsCardContentWidget__publisher {
    color: var(--altTextColor);
}

.MobileNewsCardContentWidget__created {
    color: var(--altTextColor);
    opacity: 0.8;
}

.MobileNewsCardContentWidget__image {
    object-fit: cover;
    height: 120px;
    min-width: 100%;
    overflow: hidden;
}
