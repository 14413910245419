.TransparentLoader {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
    display: grid;
    place-items: center;
    z-index: 3;
    touch-action: none;

    animation: fade-in .3s ease-in-out;
}

.TransparentLoader__loaderWrapper {
    display: grid;
    grid-gap: 20px;
    place-items: center;
    color: white;
}

.TransparentLoader.fade-out {
    animation: fade-out .2s ease-in-out;
}

@keyframes fade-in {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fade-out {
    from {opacity: 1}
    to {opacity: 0}
}