.MobileCompactWatchCardWidget {
    color: var(--primaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: 0 4px 20px 0 rgba(37, 45, 50, 0.07);
    border-radius: 15px;
    width: 150px;
    height: 200px;
    padding: 10px;

    text-align: center;

    display: grid;
    grid-template-rows: 50px 1fr;
    place-items: center;
}

.MobileCompactWatchCardWidget__title {
    font-weight: var(--fontWeightSemiBold);
    color: var(--primaryTextColor);

    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileCompactWatchCardWidget__image {
    height: 120px;
    width: 120px;
    margin: 5px 0;
    border-radius: 10px;
    object-fit: cover;
}

.MobileCompactWatchCardWidget__reference {
    color: var(--primaryTextColor);
    font-weight: 300;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
}

.MobileCompactWatchCardWidget__serial {
    color: var(--primaryTextColor);
    font-weight: var(--fontWeightSemiBold);
}