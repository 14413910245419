

.MobileLandingScreenButtonsWidget {
    --MobileLandingScreenButtonsWidgetButtonWidth: 65%;
    --MobileLandingScreenButtonsWidgetButtonHeight: 3rem;

    width: 100%;
    position: absolute;
    bottom: 20px;
    display: grid;
    grid-auto-flow: row;
    align-content: space-evenly;
    place-items: center;
    min-height: 30vh;
    height: auto;
    grid-gap: calc(1rem /4);

    z-index: 0.9;
}

.MobileLandingScreenButtonsWidget__btn {
    height: var(--MobileLandingScreenButtonsWidgetButtonHeight);
    width: var(--MobileLandingScreenButtonsWidgetButtonWidth);
    display: grid;
    place-items: center;
    padding: 8px;
    border-radius: var(--borderRadiusRounded);

    cursor: pointer;
}

.MobileLandingScreenButtonsWidget__btnWrapper {
    display: flex;
    flex-wrap: wrap;
    width: var(--MobileLandingScreenButtonsWidgetButtonWidth);
    justify-content: space-evenly;
}

.MobileLandingScreenButtonsWidget__facebookBtn {
    background: rgba(26, 120, 244, 0.85);
    color: white;
}

.MobileLandingScreenButtonsWidget__appleBtn {
    background: rgba(0, 0, 0, 0.85);
    color: white;
}

.MobileLandingScreenButtonsWidget__googleBtn {
    background: rgba(255, 255, 255, 0.9);
    color: black;
}

.MobileLandingScreenButtonsWidget__emailBtn {
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(8px);
    color: white;
}

.MobileLandingScreenButtonsWidget__logoWrapper {
    position: absolute;
    display: flex;
    justify-items: center;
    width: calc(var(--MobileLandingScreenButtonsWidgetButtonWidth) - 10%);
}
