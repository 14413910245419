

.MobileAuctionCardLayout {
    --mobileAuctionCardLayoutMaxHeight: 220px;

    background: var(--secondaryBackgroundColor);
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    border-radius: var(--borderRadiusCard);

    height: var(--mobileAuctionCardLayoutMaxHeight);
    cursor: pointer;
    overflow: hidden;
    box-shadow: var(--cardBoxShadow);
}

.MobileAuctionCardContentWidget {
    padding: 10px;
    height: var(--mobileAuctionCardLayoutMaxHeight);

    display: grid;
    grid-gap: 10px;
}

.MobileAuctionCardImageWidget {
    height: var(--mobileAuctionCardLayoutMaxHeight);
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.MobileAuctionCardImageWidget img {
    height: var(--mobileAuctionCardLayoutMaxHeight);
    max-height: var(--mobileAuctionCardLayoutMaxHeight);
    height: 100%;
    object-fit: cover;
}

.MobileAuctionCardContentUser {
    display: flex;
    align-items: center;
}

.MobileAuctionCardContentUser__username {
    margin-left: 10px;
}

.MobileAuctionCardContentUser__username h6 {
    font-size: 17px;
}

.MobileAuctionCardContentUser__img {
    --mobileAuctionCardLayoutUserImgMaxHeight: 30px;
    --mobileAuctionCardLayoutUserImgMaxWidth: 30px;

    border-radius: calc(var(--mobileAuctionCardLayoutMaxHeight) / 2);
    height: var(--mobileAuctionCardLayoutUserImgMaxHeight);
    width: var(--mobileAuctionCardLayoutUserImgMaxWidth);
    overflow: hidden;

    display: grid;
    place-items: center;
}

.MobileAuctionCardContentUser__img img {
    width: 100%;
    min-height: var(--mobileAuctionCardLayoutUserImgMaxHeight);
    object-fit: cover;
}

.MobileAuctionCardContentInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.MobileAuctionCardContentInfo__reference {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.MobileAuctionCardContentInfo__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileAuctionCardContentStatus {
    display: grid;
    grid-gap: 2px;
}

.MobileAuctionCardContentStatus__leftSection {
    display: flex;
    align-items: center;
}

.MobileAuctionCardContentStatus__leftSection svg {
    --dim: 20px;
    height: var(--dim);
    width: var(--dim);
    margin-right: 15px;
}

.MobileAuctionCardContentStatus__rightSection {
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: var(--accentColor);
}

.MobileAuctionCardContentStatus__expiredAuction {
    color: darkred;
}
