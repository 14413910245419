.MobileNumpad {
    padding: 10px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 246, 250, 1) 100%);
    border-radius: var(--borderRadiusCard);

    margin-top: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 50px);
    place-items: center;
}

.MobileNumpad__number {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.MobileNumpad__number > p {
    transition: all .1s ease-out;
    width: 60px;
    height: 35px;
    border-radius: 8px;
    display: grid;
    place-items: center;

    font-size: 22px;
    line-height: 22px;
}

.MobileNumpad__number:not(:last-child):not(:nth-child(10)):active > p {
    transform: scale(1.4);
    background-color: #f3f3f3;
}

.MobileNumpad__number:last-child:active > p {
    transform: scale(1.2);
}