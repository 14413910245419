@keyframes showNavbar {
    0% {
        top: 100vh;
    }
    100% {
        top: calc(100vh - var(--mobileNavbarHeight) - 20px);
    }
}

@keyframes hideNavbar {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.MobileNavbarLayout {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-auto-flow: column;

    width: 80%;
    max-width: 450px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.83);
    backdrop-filter: blur(var(--mobileNavbarBackgroundBlur));
    box-shadow: 0 4px 8px 9px rgba(0, 0, 0, 0.05);
}

.MobileNavbarLayout--wrapper {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: var(--mobileNavbarHeight);
    /*top: calc(100vh - var(--mobileNavbarHeight) - 20px);*/
    bottom: 20px;
    display: grid;
    place-items: center;
}

.MobileNavbarLayout--wrapper.show {
    animation: showNavbar 200ms ease-in;
}

.MobileNavbarLayout--wrapper.hide {
    opacity: 0;
    pointer-events: none;
    animation: hideNavbar 200ms ease;
}

.MobileNavbar__MenuItem {
    height: calc(var(--mobileNavbarHeight) - 5px);
    display: grid;
    place-items: center;
    cursor: pointer;
    color: var(--secondaryColor);
}

.MobileNavbar__MenuItem > svg {
    transform: scale(0.9);
}

.MobileNavbar__MenuItem:hover {
    color: var(--accentColor);
}

.MobileNavbarLayout .HomeBtn {
    grid-column: 1/5;
}

.MobileNavbarLayout .SocialBtn {
    position: relative;
    grid-column: 5/9;
}

.MobileNavbarLayout .SocialBtn__notification {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: var(--unreadNotificationBackground);
    width: 8px;
    height: 8px;
    border-radius: 4px;
    opacity: 0.8;
}

.MobileNavbarLayout .AddBtn {
    grid-column: 9/13;
}

.MobileNavbarLayout .MarketplaceBtn {
    grid-column: 13/17;
}

.MobileNavbarLayout .ProfileBtn {
    grid-column: 17/21;
}

.MobileNavbarLayout .ProfileBtn__roundedWrapper {
    --squareDimentions: 32px;
    height: var(--squareDimentions);
    width: var(--squareDimentions);
    object-fit: cover;
}

.ProfileBtn__roundedWrapper > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: var(--borderRadiusRounded);
}

.navItem--active {
    color: var(--accentColor);
}

.navItem--active img {
    border: var(--accentColor) 3px solid;
}