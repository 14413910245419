

.SettingsView {
    min-height: calc(100vh - env(safe-area-inset-top, 0));
    background: var(--secondaryBackgroundColor);
}

.MobileSettingsEntry {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(7, 1fr);
    height: 50px;
    cursor: pointer;
    padding: 5px 10px;

}

.MobileSettingsEntry__icon {
    grid-column: 1/2;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    color: var(--secondaryColor);
}

.MobileSettingsEntry__icon svg {
    transform: scale(1.5);
}

.MobileSettingsEntry__title {
    grid-column: 2/6;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.MobileSettingsEntry__title h3 {
    font-size: 20px;
    color: var(--primaryTextColor);
}

.MobileSettingsEntry__appendix {
    grid-column: 6/8;
    display: grid;
    place-items: center;
}

.MobileSettingsLauncher {
    padding: 10% 5%;
}

/*
    Profile deletion
 */

.MobilePrivacySettingsLauncher__delete {
    display: grid;
    place-items: center;
    width: 50px;
    height: 30px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 10px;
}

/*
    Blocked users
 */

.MobileUserBlockSettingsWidget {
    height: 100%;
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    margin-bottom: 30px;
}

.MobileUserBlockSettingsWidget__unblock {
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--incorrectInputRed);
    color: white;
    border-radius: 50%;

    transition: all .2s;
}

.MobileUserBlockSettingsWidget__unblock:active {
    transform: scale(0.9);
}