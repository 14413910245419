.MobileBidLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileBidLayout__bidWrapper {
    height: calc(100vh - var(--toolbarHeight) - env(safe-area-inset-top, 0));

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 20px 20px 40px;
}

.MobileBidLayout__bidInformation {
    margin-top: auto;
    display: grid;
    place-items: center;
    grid-gap: 10px;
}

.MobileBidLayout__bidInformation > * {
    display: grid;
    place-items: center;
}

.MobileBidLayout__bidCurrent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MobileBidLayout__bidInputCurrencyWrapper {
    margin-left: 10px;
    right: -55px;
    opacity: 0.7;
    display: flex;
    align-items: center;
}

.MobileBidLayout__bidInputCurrencyWrapper > svg {
    width: 18px;
    height: 18px;
}

.MobileBidLayout__bidInputCurrency {
    width: 5ch;
    appearance: none;
    background-color: unset;
    border: none;
    border-image: none;
    outline: none;
    color: unset;
    margin-right: -10px;
}

.MobileBidLayout__bidInput {
    font-size: 50px;
    line-height: 52px;
    margin-bottom: 5px;
}

.MobileBidLayout__bidAmountDetails {
    display: grid;
    grid-gap: 4px;
}

.MobileBidLayout__bidPrice {
}

.MobileBidLayout__bidPriceLocal {
    font-size: 15px;
}

.MobileBidLayout__bidIncrementer {

}

.MobileBidLayout__bidError {
    color: var(--incorrectInputRed);
}

.MobileBidLayout__bidButton {
    margin-top: 20px;
    padding: 10px 20px;
    display: grid;
    place-items: center;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--borderRadiusCard);
    background-color: var(--accentColor);
    color: white;
    transition: all .1s;
}

.MobileBidLayout__bidButton:active {
    opacity: 0.7;
}

/**

    BID CONFIRMATION

 */

.MobileBidLayout__bidConfirmationWrapper {
    padding: 20px;
    display: grid;
    grid-gap: 20px;
}

.MobileBidLayout__bidSummary {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    display: grid;
}

.MobileBidLayout__bidSummary > * {
    width: 100%;
    border-bottom: 1px solid var(--primaryBackgroundColor);
    padding: 15px;
}
