.MobileFeedLayout {
    background-color: var(--primaryBackgroundColor);
    overflow: hidden;
    height: calc(100vh - env(safe-area-inset-top, 0))
}

.MobileFeedLayout h1 {
    margin-left: 25px;
}

.MobileFeedHeaderWidget {
    width: 100vw;
}

.MobileFeedTitleWidget__title {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.MobileFeedTitleWidget__viewMore {
    margin-left: auto;
    opacity: 0.8;
    padding: 10px;

    transition: all .2s;
}

.MobileFeedTitleWidget__viewMore:active {
    opacity: 0.6;
}
