.MobileOrderLayout {
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileOrderLayout__trackingLink {
    color: var(--accentColor);
    cursor: pointer;

    transition: all .2s;
}

.MobileOrderLayout__trackingLink:active {
    opacity: 0.8;
}

.MobileOrderLayout__messageSeller {
    width: 50px;
    height: 50px;
    background-color: var(--primaryBackgroundColor);
    color: var(--loaderBackground);
    border-radius: 25px;

    display: grid;
    place-items: center;
}

.MobileOrderLayout__messageSeller > svg {
    display: grid;
    place-items: center;

    width: 28px;
    height: 28px;

    margin-left: -3px;
    margin-bottom: -2px;
}

/*
    Support Button
 */

.MobileOrderLayout__startSupport {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    display: grid;
    place-items: center;
}

.MobileOrderLayout__startSupport > * {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 10px;
}

.MobileOrderLayout__startSupport:active {
    opacity: 0.8;
    cursor: pointer;
}

/**

   Order Header Section

 */

.MobileOrderHeaderSection {
    display: grid;
    grid-gap: 15px;
}

.MobileOrderHeaderSection__header {
    display: grid;
    place-items: center;
    text-align: center;
    grid-gap: 5px;

    padding: 5px;
}

.MobileOrderHeaderSection__divider {
    width: 100%;
    height: 2px;
    background-color: var(--primaryBackgroundColor);
    margin-bottom: 10px;
}

.MobileOrderHeaderSection__orderInfo {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);

    display: grid;
    grid-gap: 10px;

    padding: 20px;
}

.MobileOrderHeaderSection__orderInfoEntry {
    display: grid;
    grid-template-columns: 30px 1fr 1fr;
    grid-gap: 5px;

    place-items: center start;
}

.MobileOrderHeaderSection__orderInfoEntry > svg {
    place-self: center;
    opacity: 0.9;
}

.MobileOrderHeaderSection__orderInfoKey {
    font-weight: 600;
}

.MobileOrderHeaderSection__listingButton {
    background-color: var(--primaryBackgroundColor);
    color: var(--primaryTextColor);
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    display: grid;
    place-items: center;
}

.MobileOrderHeaderSection__payNowButton{
    background-color: var(--accentColor);
    color: var(--primaryBackgroundColor);
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    display: grid;
    place-items: center;
    cursor: pointer;
}

.MobileOrderHeaderSection__listingButton > * {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 10px;
}

.MobileOrderHeaderSection__listingButton:active {
    opacity: 0.8;
    cursor: pointer;
}

/**

    Order Info Widget

 */

.MobileOrderInformationWidget {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);

    display: grid;
    grid-gap: 10px;

    padding: 20px;
}

.MobileOrderInformationWidget__subHeader {
    display: flex;
    align-items: center;
}

.MobileOrderInformationWidget__subHeaderImage > svg {
    display: grid;
    place-items: center;
}

.MobileOrderInformationWidget__subHeaderImage {
    height: 30px;
    width: 30px;
    object-fit: contain;

    margin-right: 5px;

    display: grid;
    place-items: center;
}

.MobileOrderInformationWidget__header {
    display: flex;
    place-items: center start;

    justify-content: space-between;
}

.MobileOrderInformationWidget__entry {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.MobileOrderInformationWidget__entryKey {
    font-weight: 600;
}

.MobileOrderInformationWidget__divider {
    width: 100%;
    height: 2px;
    background-color: var(--primaryBackgroundColor);
}

/**
    Address Widget
 */

.MobileOrderAddressWidget {
    background-color: var(--primaryBackgroundColor);
    padding-bottom: 20px;
 }

.MobileOrderAddressWidget__inputWrapper {
    display: grid;
    grid-gap: 10px;
}

.MobileOrderAddressWidget__inputWrapper > p {
    margin-left: 4px;
}

.MobileOrderAddressWidget__input {
    padding: 15px;
    outline: none;
    border: none;
    font-size: 16px;
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
}

.MobileOrderAddressWidget__required {
    color: var(--incorrectInputRed);
}

.MobileOrderAddressWidget__proceed {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px;
    background-color: var(--accentColor);
    box-shadow: var(--accentColor);
    border-radius: var(--borderRadiusCard);
    color: white;

    transition: all .2s;
}

.MobileOrderAddressWidget__proceed > svg {
    position: absolute;
    left: 10%;
}

.MobileOrderAddressWidget__proceed:active {
    opacity: 0.8;
}

/*
    Order Payment Link
 */

.MobileOrderPaymentLink {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: grid;
    place-items: center;
}

.MobileOrderPaymentLink__button {
    display: grid;
    place-items: center;
    background-color: var(--accentColor);
    border-radius: 20px;
    color: white;
    padding: 20px;
}