
.MobileStripeConnectButtonContainer {
    padding: 20px 20px 30px 20px ;
    display: grid;
    place-items: center;
}

.MobileStripeConnectButtonContainer p {
    padding: 15px 0;
}

.MobileStripeConnectButtonContainer .errorMessage {
    color: var(--incorrectInputRed);
}

.MobileMakeListingStripeConnectButton .error {
    border: var(--incorrectInputRed) 3px solid;
}

.MobileMakeListingStripeConnectButton {
    background-color: var(--accentColor);
    color: var(--primaryBackgroundColor);
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    border-radius: var(--borderRadiusCard);
    text-align: center;

    transition: all .25s;

    display: grid;
    place-items: center;
    cursor: pointer;
    height: 5ch;
    width: 15em;
}