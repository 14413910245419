.MobileCommunityReportWidget {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 20px;

    display: grid;
    grid-gap: 10px;
}

.MobileCommunityReportWidget__buttons {
    display: grid;
    grid-gap: 5px;
}

.MobileCommunityReportWidget__button {
    padding: 10px;
    border-radius: var(--borderRadiusCard);
    background-color: var(--accentColor);
    color: white;
    display: grid;
    place-items: center;
}

.MobileCommunityReportWidget__button.danger {
    background-color: var(--denyColor);
}