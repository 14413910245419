


.MobileSignUpDetailsWidget {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.MobileSignUpDetailsWidget--wrapper {
    width: 100%;
    min-height: calc(100vh - env(safe-area-inset-top, 0));
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
    place-items: center;
}

.MobileSignUpDetailsWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileSignUpDetailsWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}

.MobileSignUpDetailsWidget label {
    opacity: 0;
}

.MobileSignUpDetailsWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.MobileSignUpDetailsWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
}

.MobileSignUpDetailsWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    margin: 8px 0;
}

.MobileSignUpDetailsWidget .submitBtn {
    color: white;
    background: var(--accentColor);
    border: var(--accentColor) 3px solid;
}

.MobileSignUpDetailsWidget .backBtn {
    color: var(--primaryTextColor);
    background: var(--primaryBackgroundColor);
    border: none;
}

.MobileSignUpDetailsWidget__buttons--wrapper {
    margin-top: 60px;
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileSignUpDetailsWidget__inputContent .field {
    margin: 0;
}

.MobileSignUpDetailsWidget .errorBorder {
    border: var(--incorrectInputRed) 2px solid;
}

.MobileSignUpDetailsWidget .errorMsg {
    color: var(--incorrectInputRed);
    width: 100%;
    padding: 25px;
    text-align: center;
}

