


.MobileRecoverPasswordVerificationWidget {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.MobileRecoverPasswordVerificationWidget--wrapper {
    width: 100%;
    min-height: 100vh;
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordVerificationWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordVerificationWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}


.MobileRecoverPasswordVerificationWidget__header {

}

.MobileRecoverPasswordVerificationWidget label {
    opacity: 0;
}

.MobileRecoverPasswordVerificationWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin: 0 0 90px 0;
}

.MobileRecoverPasswordVerificationWidget p {
    text-align: center;
}

.MobileRecoverPasswordVerificationWidget__info {
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordVerificationWidget__inputContent {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    margin-top: 40px;
}

.MobileRecoverPasswordVerificationWidget__inputField {
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordVerificationWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
    margin: 10px 0;
    border-radius: 15px;
}

.MobileRecoverPasswordVerificationWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.09);
    margin: 8px 0;
}

.MobileRecoverPasswordVerificationWidget .submitBtn {
    color: white;
    background: var(--accentColor);
    border: var(--accentColor) 3px solid;
}

.MobileRecoverPasswordVerificationWidget .backBtn {
    color: var(--primaryTextColor);
    background: var(--primaryBackgroundColor);
    border: none;
}

.MobileRecoverPasswordVerificationWidget__buttons--wrapper {
    margin-top: 60px;
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileRecoverPasswordVerificationWidget__appendedInfo {
    display: grid;
    place-items: center;
    color: var(--incorrectInputRed);
}

.error {
    border: var(--incorrectInputRed) 3px solid;
}

.MobileRecoverPasswordVerificationWidget__resendContainer p {
    margin-top: 35px;
}
