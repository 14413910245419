.MobileVaultWatchWatchInfoWidget {
    padding: 0 15px;
}

.MobileVaultWatchWatchInfoWidget__wrapper {
    padding: 15px;

    border-radius: 16px;
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 0 30px;
    grid-gap: 10px;
}

.MobileVaultWatchWatchInfoWidget__wrapper.open {
    grid-auto-flow: row;
    grid-template-columns: auto;
}

.MobileVaultWatchWatchInfoWidget__title {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 10px;
    place-items: center start;
}

.MobileListingWatchInfoWidget__title > h6 {
    width: 100%;
}

.MobileVaultWatchWatchInfoWidget__infoSection {
    display: grid;
    overflow: hidden;

    max-height: 0;
}

.MobileVaultWatchWatchInfoWidget__infoSection.open {
    max-height: 1000px;

    transition: all .4s ease-in-out;
}

.MobileVaultWatchWatchInfoWidget__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid var(--altSeparatorColor);
    place-items: center start;
    padding: 8px 0;
}

.MobileVaultWatchWatchInfoWidget__info:last-child {
    border-bottom: none;
}

.MobileVaultWatchWatchInfoWidget__key,
.MobileVaultWatchWatchInfoWidget__value {
}

.MobileVaultWatchWatchInfoWidget__key {

}

.MobileVaultWatchWatchInfoWidget__value {

}

.MobileVaultWatchWatchInfoWidget__bottomSection {
    place-self: center end;

    transition: transform .25s ease-in-out;
}

.MobileVaultWatchWatchInfoWidget__bottomSection.open {
    transform: rotateZ(180deg);
}

/*
    Modification Widget
 */

.MobileVaultWatchModificationWidget {
    display: grid;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileVaultWatchModificationWidget__section {
    display: grid;
    padding: 20px;
    grid-gap: 3px;
    border-bottom: 1px solid var(--primaryBackgroundColor);
}

.MobileVaultWatchModificationWidget__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
}

.MobileVaultWatchModificationWidget__value {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}