.MobileDeleteProfileLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileDeleteProfileLayout__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}