.MobileLoaderWidget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    inset: 0;
    height: 100vh;
	
	pointer-events: none;
}

.MobileLoaderWidget.fullscreen {
    background: var(--primaryBackgroundColor);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 14;
    width: 100vw;
}