.MobilePopupMenuWrapper {
    --popupMenuBorderRadius: 15px;

    touch-action: none;

    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    transition: all .25s ease;
    backdrop-filter: blur(3px) opacity(0);
}

.MobilePopupMenuWrapper.active {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px) opacity(1);
}

.MobilePopupMenuWrapper__buttonWrapper {
    margin: 0px 25px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: var(--cardBoxShadow);
    overflow: hidden;
    visibility: hidden;

    transform: translateY(100%);

    grid-template-columns: 1fr;

    z-index: 10;

    transition: all .25s ease-in-out;
}

.MobilePopupMenuWrapper__buttonWrapper.active {
    visibility: visible;
    bottom: 0px;
    margin: 25px 25px 35px;
    transform: translateY(0);
}

.MobilePopupMenuWrapper__title {
    width: 100%;
    color: var(--secondaryBackgroundColor);
    text-align: center;
}

.MobilePopupMenuWrapper__subTitle {
    width: 100%;
    color: var(--secondaryBackgroundColor);
    text-align: center;

    margin-top: 10px;
    margin-bottom: 15px;
}

.MobilePopupMenuWrapper__buttonGroup {
    border-radius: var(--popupMenuBorderRadius);
    background-color: var(--secondaryBackgroundColor);

    overflow: hidden;
}

.MobilePopupMenuWrapper__buttonGroup:not(:last-child) {
    margin-bottom: 10px;
}

.MobilePopupMenuOption {
    text-align: center;

    padding: 15px 10px;

    width: 100%;
    height: 100%;

    transition: all .25s;
}

.MobilePopupMenuOption:hover {
    background-color: var(--primaryBackgroundColor);
}

.MobilePopupMenuOption:not(:last-child) {
    border-bottom: var(--altSeparatorColor) 1px solid;
}