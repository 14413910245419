.MobileOfferingLayout__timeLeft {
    display: inline-block;
    color: var(--accentColor);
    margin: 10px;
    background-color: var(--secondaryBackgroundColor);
    padding: 5px 15px;
    border-radius: var(--borderRadiusCard);
}


/**

    Documentation Info Widget

 */

.OfferingDocumentationInfoWidget {
    display: grid;
    grid-gap: 10px;
    margin-top: 10px;
}

.OfferingDocumentationInfoWidget__documentationWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    place-items: center;

    margin-top: 30px;

    position: relative;
}

.OfferingDocumentationInfoWidget__notes {
    display: grid;
    grid-gap: 3px;
}

.OfferingDocumentationInfoWidget__documentation {
    position: relative;

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
    place-items: center;

    text-align: center;
}

.OfferingDocumentationInfoWidget__documentation.invalid {
    opacity: 0.4;
}

.OfferingDocumentationInfoWidget__documentationCount {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    background-color: var(--secondaryTextColor);
    width: 20px;
    height: 20px;

    display: grid;
    place-items: center;
    color: white;
    border-radius: 10px;
}

.OfferingDocumentationInfoWidget__documentationTitle {
    place-self: start center;
    font-size: 14px;
}

.OfferingDocumentationInfoWidget__documentationValid {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px !important;
    height: 20px !important;

    background-color: var(--accentColor);
    border-radius: 10px;
    fill: white !important;
}

.OfferingDocumentationInfoWidget__documentationIcon {
    width: 48px !important;
    height: 48px !important;
}

/**
    Support Info Widget
 */

.OfferingSupportInfoWidget__startSupport {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;
    margin-top: 20px;

    display: grid;
    place-items: center;
}

.OfferingSupportInfoWidget__startSupport > * {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 10px;
}

.OfferingSupportInfoWidget__startSupport:hover,
.OfferingSupportInfoWidget__startSupport:active {
    opacity: 0.8;
    cursor: pointer;
}

.MobileOfferingLayout__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;

    opacity: 0.5;
}

.MobileAuctionLayout__timeLeft {
    display: inline-block;
    color: var(--accentColor);
    margin: 10px;
    background-color: var(--secondaryBackgroundColor);
    padding: 5px 15px;
    border-radius: var(--borderRadiusCard);
}

/**

    Support Info Widget

 */

.OfferingSupportInfoWidget__startSupport {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;
    margin-top: 20px;

    display: grid;
    place-items: center;
}

.OfferingSupportInfoWidget__startSupport > * {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 10px;
}

.OfferingSupportInfoWidget__startSupport:hover,
.OfferingSupportInfoWidget__startSupport:active {
    opacity: 0.8;
    cursor: pointer;
}

.MobileOfferingLayout__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;

    opacity: 0.5;
}
