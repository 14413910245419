.Warning {
    display: grid;
    place-items: center;
    position: absolute;
    inset: 0;
    height: 100vh;
    pointer-events: none;
}

.Warning.fullscreen {
    z-index: 4;
    background-color: var(--primaryBackgroundColor);
}

.Warning__wrapper {
    display: grid;
    place-items: center;
}

.Warning__warningIcon {
    height: 48px;
    width: 48px;

    color: black;
    opacity: 0.3;
}

.Warning__warningMessage {
    margin-top: 10px;
}