.MobileCommentSectionWidget {
    border-top: 1px solid var(--primaryBackgroundColor);
    background: var(--secondaryBackgroundColor);
    padding: 15px;
    display: grid;
    grid-gap: 10px;
}



.MobileCommentSectionWidget__noComments {
    --marginOffset: 10px;
    text-align: center;
    width: calc(100% - (var(--marginOffset) * 2));
    margin: var(--marginOffset);
}

.MobileCommentSectionWidget__fetchMore {
    padding-left: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all .2s;
}

.MobileCommentSectionWidget__fetchMore > p {
    font-weight: 600;
}