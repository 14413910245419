.MobileConfirmationSlider {
    position: relative;
    background-color: var(--accentColor);
    border-radius: 10px;
    color: white;
    display: grid;
    place-items: center;
    height: 50px;
}

.MobileConfirmationSlider.danger {
    background-color: var(--incorrectInputRed);
}

.MobileConfirmationSlider.finish {
    background-color: var(--acceptColor);
}

.MobileConfirmationSlider.danger.finish {
    background-color: var(--denyColor);
}

.MobileConfirmationSlider__sliderLabel {
    opacity: 100%;
    transition: opacity .3s;
}

.MobileConfirmationSlider__sliderLabelFinished {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 20px;
}

.MobileConfirmationSlider__sliderLabelFinished > div {
    display: grid;
    place-items: center;
}

.MobileConfirmationSlider__sliderThumb {
    position: absolute;
    left: 0;
    width: 50px;
    height: 40px;
    margin: 0 5px;
    border-radius: 10px;
    display: grid;
    place-items: center;

    z-index: 1;

    transition: background-color .2s;
}

.MobileConfirmationSlider__sliderThumb.active {
    background-color: rgba(0, 0, 0, 0.2);
}

.MobileConfirmationSlider__sliderOverlay {
    position: absolute;
    inset: 0;
    opacity: 0.3;
    border-radius: 10px;

    background: var(--accentColor) linear-gradient(to right, var(--accentColor) 0%, #a1d9ff 20%, var(--accentColor) 40%, var(--accentColor) 100%) no-repeat;
    background-size: 800px 104px;

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: SlideToConfirmAnimation;
    animation-timing-function: linear;
}

.MobileConfirmationSlider__sliderOverlay.danger {
    background: var(--denyColor) linear-gradient(to right, var(--denyColor) 0%, #ff8787 20%, var(--denyColor) 40%, var(--denyColor) 100%) no-repeat;
}

.MobileConfirmationSlider__sliderOverlay.finish {
    animation-duration: 0.5s;
    opacity: 0.1;
}

@keyframes SlideToConfirmAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}