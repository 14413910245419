.MarketplaceView {
    padding: 0 0 15vh 0;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MarketplaceView h1 {
    margin-left: 15px;
}

.MobileMarketplaceToolbarWidget {
}

.MobileMarketplaceToolbarWidget__wrapper {
    display: grid;
    grid-gap: 20px;
    padding: 20px 20px 0;
}

.MobileMarketplaceToolbarWidget__topSection {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
    align-items: center;
}

.MobileMarketplaceToolbarWidget__backButton {
    display: grid;
    place-items: center start;
}

.MobileMarketplaceToolbarWidget__input {
    width: 100%;
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    outline: none;
    border: none;

    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/**
    explore
 */

.MobileMarketplaceExploreWidget {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-auto-flow: row dense;
}

.MobileMarketplaceSearchWidget {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    grid-auto-flow: row dense;
}

.MobileMarketplaceLayout__notFound {
    margin-top: 20px;
    display: grid;
    place-items: center;
}