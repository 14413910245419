.MobileCarouselLayout {
    overflow: visible;
}

.MobileCarouselContentWidget {
    display: grid;
    overflow: scroll;
    place-content: start;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.MobileCarouselContentWidget::-webkit-scrollbar {
    display: none;
}