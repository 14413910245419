.MobileCommentWidget {
    width: 100%;

    border-radius: 15px;
    overflow: hidden;
}

.MobileCommentWidget__header {
    display: flex;
    margin-bottom: 5px;
}

.MobileCommentWidget__userImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.MobileCommentWidget__userImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MobileCommentWidget__headerInfo {
    flex: 1;
    margin-left: 10px;
    cursor: pointer;
}

.MobileCommentWidget__headerInfo .p4 {
    font-weight: 600;
    font-size: 14px;
}

.MobileCommentWidget__headerInfo .p7 {
    font-size: 10px;
}

.MobileCommentWidget__content {
    margin: 0 0 10px 40px;
}

.MobileCommentWidget__commentMsg {
    background: var(--altBackgroundColor);
    padding: 10px;
    border-radius: 3px 15px 15px 15px;
    display: inline-block;

}

.MobileCommentWidget__commentMsg p {

}

.MobileCommentWidget__appendedImg {
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0 0 0;
    width: 125px;
    cursor: pointer;
}

.MobileCommentWidget__appendedImg img {
    object-fit: cover;
    height: 125px;
    max-width: 125px;
    border-radius: 10px;

}

.MobileCommentWidget input {
    background: var(--altBackgroundColor);
}

.MobileCommentWidget__replyForm {
    display: flex;
    margin: 0 0 25px 0;
}

.MobileCommentWidget__replyForm input {
    background: var(--altBackgroundColor);
    flex: 1;
}

.MobileCommentWidget__uploadIll {
    color: var(--secondaryColor);
    display: grid;
    place-items: center;
    margin-left: 10px;
    justify-content: flex-end;
    height: 40px;
}

.MobileCommentWidget__replyForm svg {
    --dim: 28px;
    height: var(--dim);
    width: var(--dim);
    cursor: pointer;
}
