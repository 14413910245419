.MobileEmailVerificationContinueWidget {
    width: 100%;
    min-height: 100vh;
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
    place-items: center;
}

.MobileEmailVerificationContinueWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileEmailVerificationContinueWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}

.MobileEmailVerificationContinueWidget__form {
    display: grid;
    grid-gap: 10px;
}

.MobileEmailVerificationContinueWidget label {
    opacity: 0;
}

.MobileEmailVerificationContinueWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin: 0 0 50px 0;
}

.MobileEmailVerificationContinueWidget p {
    text-align: center;
}

.MobileEmailVerificationContinueWidget__info {
    display: grid;
    place-items: center;
}

.MobileEmailVerificationContinueWidget__inputContent {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 15px;
    margin-top: 40px;
}

.MobileEmailVerificationContinueWidget__inputField {
    display: grid;
    place-items: center;
}

.MobileEmailVerificationContinueWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
    margin: 10px 0;
    border-radius: 15px;
}

.MobileEmailVerificationContinueWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.09);
    margin: 8px 0;
}