.ImageCropper {
    z-index: 100;
    position: fixed;
    inset: 0;
    top: env(safe-area-inset-top, 0px);
    background-color: white;
}

.ImageCropper__wrapper {

}

.ImageCropper__complete {
    z-index: 101;
    background-color: var(--accentColor);
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 10px;
    color: white;
    transition: all .2s;
}

.ImageCropper__complete:active {
    transform: scale(0.9);
}

