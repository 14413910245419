.MobileDisputeCreateLayout {
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileDisputeCreateLayout__content {
    display: grid;
    grid-gap: 20px;
    padding: 20px;
}

.MobileDisputeCreateLayout__info {
    font-size: 15px;
    line-height: 18px;
    opacity: 0.9;
}

.MobileDisputeCreateLayout__create {
    background-color: var(--accentColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    padding: 15px 20px;
    color: white;
    text-align: center;
    transition: all .2s;
}

.MobileDisputeCreateLayout__create:active {
    opacity: 0.7;
}

.MobileDisputeCreateLayout__upload {
    background-color: white;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    padding: 15px 20px;
    text-align: center;
    transition: all .2s;
}

.MobileDisputeCreateLayout__upload:active {
    opacity: 0.7;
}

.MobileDisputeCreateLayout__evidenceWrapper {
    display: grid;
    grid-gap: 10px;
}

.MobileDisputeCreateLayout__evidences {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.MobileDisputeCreateLayout__evidence {
    position: relative;
    border-radius: 10px;
    aspect-ratio: 1;
    background-color: white;
    overflow: hidden;
}

.MobileDisputeCreateLayout__evidence > img {
    object-fit: cover;
    aspect-ratio: 1;
}

.MobileDisputeCreateLayout__removeEvidence {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    color: white;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    backdrop-filter: blur(3px);
}

.MobileDisputeCreateLayout__removeEvidence > svg {
    width: 15px;
    height: 15px;
}

/**
    Multiline
 */

.MobileDisputeCreateLayout__multiline {
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    position: relative;
}

.MobileDisputeCreateLayout__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    position: relative;
    border-radius: var(--borderRadiusCard);

    border-bottom: 1px solid var(--primaryBackgroundColor);
}
.MobileDisputeCreateLayout__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.MobileDisputeCreateLayout__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    overflow-wrap: break-word;

    max-width: 93vw;
}
.MobileDisputeCreateLayout__multilineWrapper > .MobileDisputeCreateLayout__multiline,
.MobileDisputeCreateLayout__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    overflow-wrap: break-word;

    font: inherit;

    max-width: 93vw;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}
