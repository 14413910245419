.MobileWideFlatCardContent__card {
    color: var(--primaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: 0 4px 20px 0 rgba(37, 45, 50, 0.07);
    border-radius: 15px;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    height: 100px;

    transition: all ease 0.4s
}

.MobileWideFlatCardContent__headerWrapper {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
}

.MobileWideFlatCardContent__textWrapper {
    margin-left: 4px;
}

.MobileWideFlatCardContent__textWrapper > h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.MobileWideFlatCardContent__headerImage {
    width: 40px;
    height: 40px;
    margin-right: 6px;

    border-radius: 50%;
}

.MobileWideFlatCardContent__title {
    word-wrap: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;

    line-height: 100%;
    margin-bottom: 4px;
}

.MobileWideFlatCardContent__leftContent {
    flex: 2 0 0;

    display: flex;
    flex-direction: column;

    padding: 12px;
}

.MobileWideFlatCardContent__rightContent {
    flex: 1 0 0;
    max-height: 120px;
}

.MobileWideFlatCardContent__buttons {
    color: var(--altTextColor);
    margin-top: auto;

    display: flex;
    align-content: flex-end;
}

.MobileWideFlatCardContent__buttons > * {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 15px;
}

.MobileWideFlatCardContent__image {
    height: 100%;
    min-width: 130px;
    object-fit: cover;
}
