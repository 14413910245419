.MobileUserPostLayout__wrapper {
    padding: 10px;
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

/**

    Comment Input

 */

.MobileUserPostCommentInput {
    z-index: 2;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: var(--loungeChatInputHeight);

    background-color: var(--secondaryBackgroundColor);
    opacity: 0.98;
}

.MobileUserPostCommentInput__multiline {
    position: relative;
    overflow-wrap: break-word;
    max-height: 8rem;
    overflow-y: scroll;
}

.MobileUserPostCommentInput__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    width: 100%;
    position: relative;
    overflow-wrap: break-word;
    max-height: 8rem;
    overflow-y: scroll;
}
.MobileUserPostCommentInput__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;
    overflow-wrap: break-word;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.MobileUserPostCommentInput__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    overflow-wrap: break-word;
}
.MobileUserPostCommentInput__multilineWrapper > .MobileUserPostCommentInput__multiline,
.MobileUserPostCommentInput__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    max-height: 8rem;
    overflow-y: scroll;
    outline: none;
    text-decoration: none;
    overflow-wrap: break-word;
    border: 1px solid var(--primaryBackgroundColor);
    border-radius: var(--borderRadiusCard);

    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}

.MobileUserPostCommentInput__inputWrapper {
    max-width: 100vw;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 50px 50px;
    grid-gap: 10px;
    place-items: end;
    padding: 10px 10px 25px;
}

.MobileUserPostCommentInput__input {
    width: 100%;
    font-size: 18px;
    padding: 10px 15px;
    outline: none;
    border: 1px solid var(--primaryBackgroundColor);
    border-radius: var(--borderRadiusCard);

    transition: all .2s;
}

.MobileUserPostCommentInput__button {
    display: grid;
    place-items: center;

    min-width: 46px;
    min-height: 46px;
    border-radius: 50%;
    background-color: var(--primaryBackgroundColor);

    margin-left: auto;

    transition: all .1s;
}

.MobileUserPostCommentInput__button > svg {
    opacity: 0.8;
}

.MobileUserPostCommentInput__button:active {
    filter: brightness(0.9);
}

.MobileUserPostCommentInput__infoWrapper {
    position: absolute;
    bottom: 100%;
    width: 100%;
}

.MobileUserPostCommentInput__uploadedFiles {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    width: 100%;
    padding-left: 20px;

    background-color: var(--accentColor);
    color: white;
}

.MobileUserPostCommentInput__uploadedFilesCancel {
    place-self: end;
    width: 40px !important;
    height: 40px !important;
    padding: 10px;
}

.MobileUserPostCommentInput__reply {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    width: 100%;
    padding-left: 20px;

    background-color: var(--loaderBackground);
    color: white;
}

.MobileUserPostCommentInput__replyCancel {
    place-self: end;
    width: 40px !important;
    height: 40px !important;
    padding: 10px;
}