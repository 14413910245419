

.MobileLandingScreenLogoWidget {
    width: 100%;
    display: grid;
    place-items: center;
    color: white;
    position: absolute;
    top: calc(env(safe-area-inset-top, 0px) + 5vh);
    grid-gap: 15px;
}

.MobileLandingScreenLogoWidget__logo svg {
    max-width: 317px;
}

.MobileLandingScreenLogoWidget__logo {
    width: 317px;
    height: 54px;
    display: grid;
    place-items: center;
    color: #eee;
}

.MobileLandingScreenLogoWidget__ext {
}

.MobileLandingScreenLogoWidget__logoKlokkeriet {
    display: grid;
    place-items: center;
}

.MobileLandingScreenLogoWidget__logoKlokkeriet svg {
    width: 110px;
    height: 15px;
}

.MobileLandingScreenLogoWidget__logoSeparator {
    position: absolute;
    margin-left: -25px;
    margin-top: -2px;
    color: #C1C1C1;
}

.MobileLandingScreenLogoWidget__logoSeparator h6 {
    font-size: 13px;
}

