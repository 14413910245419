.MobileSubCommentSectionWidget__fetchMore {
    padding-left: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all .2s;
}

.MobileSubCommentSectionWidget__fetchMore > p {
    font-weight: 600;
}

.MobileSubCommentWidget {
    width: 100%;

    overflow: hidden;

    padding-left: 45px;
}

.MobileSubCommentWidget__header {
    display: flex;
    margin-bottom: 5px;
}

.MobileSubCommentWidget__userImg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.MobileSubCommentWidget__userImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MobileSubCommentWidget__headerInfo {
    flex: 1;
    margin-left: 10px;
    cursor: pointer;
}

.MobileSubCommentWidget__headerInfo .p4 {
    font-weight: 600;
    font-size: 13px;
}

.MobileSubCommentWidget__headerInfo .p7 {
    font-size: 9px;
}

.MobileSubCommentWidget__content {
    margin: 5px 0 10px 35px;
    display: inline-block;
}

.MobileSubCommentWidget__commentMsg {
    background: var(--altBackgroundColor);
    padding: 10px;
    border-radius: 3px 15px 15px 15px;
    display: inline-block;
}

.MobileSubCommentWidget__commentMsg p {

}



.MobileSubCommentWidget__replyForm {
    display: flex;
    margin: 0 0 25px 0;
}

.MobileSubCommentWidget__replyForm input {
    background: var(--altBackgroundColor);
    flex: 1;
}

.MobileSubCommentWidget__uploadIll {
    color: var(--secondaryColor);
    display: grid;
    place-items: center;
    margin-left: 10px;
    justify-content: flex-end;
    height: 40px;
}

.MobileSubCommentWidget__replyForm svg {
    --dim: 28px;
    height: var(--dim);
    width: var(--dim);
    cursor: pointer;
}