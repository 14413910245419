.MobileReportUserLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileReportUserLayout__content {
    display: grid;
    grid-gap: 20px;
    padding: 20px;
}

/**
    Multiline
 */

.MobileReportUserLayout__multiline {
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    position: relative;
}

.MobileReportUserLayout__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    position: relative;
    border-radius: var(--borderRadiusCard);

    border-bottom: 1px solid var(--primaryBackgroundColor);
}
.MobileReportUserLayout__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.MobileReportUserLayout__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    overflow-wrap: break-word;

    max-width: 93vw;
}
.MobileReportUserLayout__multilineWrapper > .MobileReportUserLayout__multiline,
.MobileReportUserLayout__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    overflow-wrap: break-word;

    font: inherit;

    max-width: 93vw;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}