.MobileMakeListingLayout {
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileMakeListingLayout__confirm {
    padding: 0 20px;
}

.MobileMakeListingLayout__watch {
    margin: 20px;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow: hidden;
    align-items: center;
}

.MobileMakeListingLayout__img {
    object-fit: cover;
}

.MobileMakeListingLayout__info {
    padding: 20px;
    display: grid;
    grid-gap: 10px;
}

.MobileMakeListingLayout__info > * {
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.AddWatchFormMarketSectionWidget__bidInputCurrency {
    width: 6ch;
    font-size: 15px;
    font-weight: 600;
    appearance: none;
    background-color: unset;
    border: none;
    border-image: none;
    outline: none;
    color: unset;
    margin-right: -15px;
}

.AddWatchFormMarketSectionWidget__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
}
.AddWatchFormMarketSectionWidget__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.AddWatchFormMarketSectionWidget__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
}
.AddWatchFormMarketSectionWidget__multilineWrapper > .AddWatchFormMarketSectionWidget__multiline,
.AddWatchFormMarketSectionWidget__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    color: #363636;

    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}
