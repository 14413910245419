.MobileOrderTrackingLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
	padding-bottom: 80px;
}

.MobileOrderTrackingLayout__subTitles {
    margin-left: 10px;
    margin-bottom: -5px;
}

.MobileOrderTrackingLayout__saveButton {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    display: grid;
    place-items: center;
}

.MobileOrderTrackingLayout__saveButton > * {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 10px;
}

.MobileOrderTrackingLayout__saveButton:active {
    opacity: 0.8;
    cursor: pointer;
}

/**


SUCCESS ANIMATION THINGY


 */

.MobileOrderTrackingLayout__successAnimation {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
}

.MobileOrderTrackingLayout__checkmarkWrapper {
    display: grid;
    grid-gap: 20px;
}

.MobileOrderTrackingLayout__checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--acceptColor);
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px var(--acceptColor);
    animation: MobileOrderTrackingLayout__checkmarkFill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.MobileOrderTrackingLayout__checkmarkCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--acceptColor);
    fill: var(--primaryBackgroundColor);
    animation: MobileOrderTrackingLayout__checkmarkStroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;

}

.MobileOrderTrackingLayout__checkmarkCheck {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: MobileOrderTrackingLayout__checkmarkStroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes MobileOrderTrackingLayout__checkmarkStroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes MobileOrderTrackingLayout__checkmarkScale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes MobileOrderTrackingLayout__checkmarkFill {
    100% {
        box-shadow: inset 0px 0px 0px 30px var(--acceptColor);
    }
}
