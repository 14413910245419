/*
    Facade
 */

.MobileButton {
}

/*
    Layout
 */

.MobileButtonLayout {
}

/*
    Button Wrapper
 */

.MobileButtonWidget {
    position: relative;
    display: grid;
    place-items: center;

    height: 50px;
    outline: none;
    border: none;

    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.MobileButtonWidget:active {
    opacity: 0.7;
}

/*
    Button Text
 */

.MobileButtonText {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
}

/*
    Button Loader
 */

.MobileButtonLoader {
    position: absolute;
    display: grid;
    place-items: center;
}

.MobileButtonLoader__wrapper {
    position: relative;
    display: grid;
    place-items: center;
}

.MobileButtonLoader__contentWrapper {
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 10px;
}

/*
    Button Icon
 */

.MobileButtonIcon {
    display: grid;
    place-items: center;
}

.MobileButtonIcon > svg {
    display: grid;
    place-items: center;
}