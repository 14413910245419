
@keyframes untoggleBtnHandle {
    0% {
        left: calc(50px - calc(var(--BtnHeight) - 4px) - 1px);
    }
    100% {
        left: 0;
    }
}

@keyframes toggleBtnHandle {
    0% {
        left: 1px;
    }
    100% {
        left: calc(50px - calc(var(--BtnHeight) - 4px) - 1px);
    }
}

.MobileToggleSliderButtonWidget {
    --BtnHeight: 30px;
}

.MobileToggleSliderButtonWidget__btn {
    width: 50px;
    height: var(--BtnHeight);
    border-radius: var(--borderRadiusRounded);
    border: var(--accentBackgroundColor) 1px solid;
    cursor: pointer;
    background: var(--accentBackgroundColor);
}

.MobileToggleSliderButtonWidget__btnHandle {
    border-radius: var(--borderRadiusRounded);
    background: var(--secondaryBackgroundColor);
    height: calc(var(--BtnHeight) - 4px);
    width: calc(var(--BtnHeight) - 4px);
    position: relative;
    top: 1px;
    left: 1px;
}

.MobileToggleSliderButtonWidget__btnHandle.toggle {
    animation: toggleBtnHandle 200ms ease;
    left: calc(50px - calc(var(--BtnHeight) - 4px) - 3px);
}

.MobileToggleSliderButtonWidget__btnHandle.untoggle {
    left: 1px;
    animation: untoggleBtnHandle 200ms ease;
}

.MobileToggleSliderButtonWidget__btn.toggle {
    background: var(--accentColor);
}
