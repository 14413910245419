.MobileOfferingCommentWidget {
    display: grid;
    gap: 10px;
    align-items: center;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    padding: 15px;
}

.MobileOfferingCommentWidget__header {
    display: flex;
    align-items: center;
}

.MobileOfferingCommentWidget__info {
    display: grid;
    grid-gap: 3px;
}

.MobileOfferingCommentWidget__user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.MobileOfferingCommentWidget__image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.MobileOfferingCommentWidget__more {
    margin-left: auto;
    place-self: start;

    transition: all .2s;
}

.MobileOfferingCommentWidget__more:active {
    opacity: 0.7;
}
