.MobileLoungeChatNewConversationWidget {
	opacity: 0;
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	inset: 0;
	overflow: hidden;
	z-index: 5;
	
	transition: all .1s;
}

.MobileLoungeChatNewConversationWidget.active {
	opacity: 1;
	visibility: visible;
}

.MobileLoungeChatNewConversationWidget__divider {
	height: 2px;
	background-color: var(--primaryBackgroundColor);
	width: 100%;
	border-radius: 1px;
	margin-bottom: 10px;
}

.MobileLoungeChatNewConversationWidget__contentWrapper {
	position: absolute;
	
	height: 85vh;
	width: 100vw;
	top: 100vh;
	
	background-color: var(--secondaryBackgroundColor);
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;

	transition: all .2s;
	transition-delay: .1s;

	-webkit-overflow-scrolling: touch;
	pointer-events: auto;

	overflow: scroll;
	
	padding: 20px;
}

.MobileLoungeChatNewConversationWidget__contentWrapper.active {
	top: 15vh;
}

/**
	header
 */

.MobileLoungeChatNewConversationWidget__header {
	display: grid;
	grid-gap: 15px;
	place-items: center;
	
	padding-bottom: 10px;
	border-bottom: 2px solid var(--primaryBackgroundColor);
}

.MobileLoungeChatNewConversationWidget__headerTitle {
	place-self: center start;
	font-size: 20px;
}

.MobileLoungeChatNewConversationWidget__headerButton {
	background-color: var(--primaryBackgroundColor);
	border-radius: 20px;
	width: 40px;
	height: 40px;
	display: grid;
	place-items: center;
	
	transition: all .1s;
}

.MobileLoungeChatNewConversationWidget__headerButton:active {
	filter: brightness(0.9);
}

.MobileLoungeChatNewConversationWidget__headerButton > svg {
	opacity: 0.8;
}

.MobileLoungeChatNewConversationWidget__createButton {
	background-color: #2ecc71;
	color: white;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	display: grid;
	place-items: center;

	transition: all .1s;
}

.MobileLoungeChatNewConversationWidget__createButton:active {
	filter: brightness(0.9);
}

.MobileLoungeChatNewConversationWidget__searchbarWrapper {
	width: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 30px;
	place-items: center;
}

.MobileLoungeChatNewConversationWidget__searchWrapper {
	display: grid;
	grid-template-columns: 1fr 40px;
	grid-gap: 20px;
	align-items: center;
}

.MobileLoungeChatNewConversationWidget__searchbarWrapper > svg {
	position: absolute;

	right: 0;
}

.MobileLoungeChatNewConversationWidget__searchbar {
	position: relative;
	margin: 10px 0;
	width: 100%;
	padding: 10px 0;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 20px;
}

.MobileLoungeChatNewConversationWidget__searchbarLabel {
	position: absolute;
	opacity: 0;
	top: 20px;
	left: 0;
	z-index: 1;

	transition: all .1s;
}

.MobileLoungeChatNewConversationWidget__searchbarLabel.active {
	top: 8px;
	opacity: 1;
	color: rgba(0, 0, 0, 0.5);
}

.MobileLoungeChatNewConversationWidget__groupDetailsWrapper {
	display: grid;
	grid-template-columns: 1fr 40px;
	grid-gap: 20px;
	align-items: center;
}

/**
	members
 */

.MobileLoungeChatNewConversationWidget__searchableMembers {
	position: relative;
	overflow: scroll;
	display: grid;
	grid-gap: 10px;
	padding: 10px 0 20px;
}

.MobileLoungeChatNewConversationWidget__memberButton {
	opacity: 0.5;
}

.MobileLoungeChatNewConversationWidget__groupNameInput {
	margin: 20px 0;
	width: 100%;
	padding: 10px 0;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 20px;
}

.MobileLoungeChatNewConversationWidget__initialMembers {
	display: flex;
	align-items: start;
	overflow-x: scroll;

	touch-action: pan-x;

	padding: 20px;
	margin-left: -20px;
	margin-right: -20px;
}

.MobileLoungeChatNewConversationWidget__initialMembers * {
	touch-action: pan-x;
}

.MobileLoungeChatNewConversationWidget__initialMembers::-webkit-scrollbar {
	display: none;
}

.MobileLoungeChatNewConversationWidget__initialMember:not(:last-child) {
	margin-right: 20px;
}

.MobileLoungeChatNewConversationWidget__initialMember {
	position: relative;
	max-width: 80px;
}

.MobileLoungeChatNewConversationWidget__initialMemberImage {
	min-width: 80px;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
}

.MobileLoungeChatNewConversationWidget__initialMemberDelete {
	background-color: #2A292D;
	color: white;
	position: absolute;
	right: -5px;
	top: -5px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: grid;
	place-items: center;
}

.MobileLoungeChatNewConversationWidget__initialMemberName {
	text-align: center;
	font-weight: var(--fontWeightBold);
}