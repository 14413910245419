.MobileSearchableDropdownSearchWidget {
    display: grid;
    position: relative;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
}

.MobileSearchableDropdownSearchWidget__rightWrapper {
    right: 10px;
    height: 100%;

    position: absolute;
    text-align: right;
    opacity: 0.6;

    display: grid;
    place-items: center;
    grid-gap: 10px;
    grid-auto-flow: column;
}

.MobileSearchableDropdownSearchWidget__rightWrapper > h4 {
    color: var(--incorrectInputRed);
    font-size: 18px;

    line-height: 10px;
    margin-bottom: -5px;
}

.MobileSearchableDropdownSearchWidget__arrow {
    place-self: center start;

    transition: all .3s;
}

.MobileSearchableDropdownSearchWidget__arrow.dropdownActive {
    transform: rotateZ(180deg);
}

.MobileSearchableDropdownSearchWidget__inputField {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: none !important;

    outline: none;
    border: none;

    width: 100%;
}

.MobileSearchableDropdownOptionsWidget {
    position: relative;
}

.MobileSearchableDropdownOptionsWidget__scrollWrapper {
    position: absolute;
    top: 0;
    opacity: 0;

    z-index: 3;

    overflow-y: scroll;

    width: 100%;

    border-radius: var(--borderRadiusCard);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: var(--secondaryBackgroundColor);

    max-height: 0;

    transition: all .3s;

    display: grid;
    grid-template-columns: 1fr 20px;
}

.MobileSearchableDropdownOptionsWidget__optionsWrapper {
    display: grid;
    place-items: center start;
}

.MobileSearchableDropdownOptionsWidget__scrollWrapper.dropdownActive {
    max-height: 180px;
    top: 10px;
    opacity: 1;
}

.MobileSearchableDropdownOptionWidget {
    width: 100%;

    padding: 10px 20px;
}

.MobileSearchableDropdownOptionWidget:not(:last-child) {
    border-bottom: 2px solid var(--primaryBackgroundColor);
}