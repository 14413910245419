.MobileInputPromptWrapper {
    --promptMenuBorderRadius: 15px;

    touch-action: none;
    display: grid;
    place-items: center;

    position: fixed;
    overflow: hidden;
    inset: 0;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    backdrop-filter: blur(3px) opacity(0);

    transition: opacity .25s ease;
}

.MobileInputPromptWrapper.active {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px) opacity(1);
}

.MobileInputPromptWrapper__buttonWrapper {
    padding: 30px;
    box-shadow: var(--cardBoxShadow);
    overflow: hidden;
    opacity: 1;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    z-index: 3;
    min-width: 70%;
    max-width: 80%;

    border-radius: var(--promptMenuBorderRadius);
    background-color: var(--primaryBackgroundColor);

    transition: all .25s ease-in-out;
}

.MobileInputPromptWrapper__buttonWrapper.active {
    opacity: 1;
}

.MobileInputPromptWrapper__title {
    width: 100%;
    color: var(--primaryColor);
    text-align: center;
    margin-bottom: 20px;
}

.MobileInputPromptWrapper__input {
    width: 100%;
    border: 1px solid var(--altSeparatorColor) !important;
    border-radius: var(--borderRadiusRounded);
    outline: none;
    padding: 10px 15px;
}

.MobileInputPromptWrapper__buttonGroup {
    width: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.MobileInputPromptButton {
    text-align: center;

    padding: 10px 10px;

    border-radius: var(--borderRadiusRounded);
    box-shadow: 0 2px 12px 4px rgba(0, 0, 0, 0.04);

    width: 100%;
    height: 100%;

    transition: all .25s;
}

.MobileInputPromptButton:hover {
    opacity: 0.8;
}