.InputField {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;
}

.InputField__wrapper {
    position: relative;

    display: grid;
    place-items: center;
}

.InputField__rightWrapper {
    right: 10px;

    position: absolute;
    text-align: right;
    opacity: 0.6;

    display: grid;
    place-items: center;
    grid-auto-flow: column;
}

.InputField__rightWrapper > h4 {
    color: var(--incorrectInputRed);
    margin-left: 10px;
    font-size: 18px;

    line-height: 10px;
    margin-bottom: -5px;
}