.ImageSliderCarouselWidget {
    --ImageSliderCarouselWidgetHeight: 70vh;

    display: grid;
    place-items: center;
    position: relative;
    
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
}

.ImageSliderCarouselWidget__sliderWrapper {
    background-color: #111;
    overflow: hidden;
}

.ImageSliderCarouselWidget__sliderWrapper.fullscreen {
    position: fixed;
    z-index: 4;
    inset: 0;

    padding: 20px;

    touch-action: pan-x pinch-zoom;
    -ms-touch-action: pan-x pinch-zoom;
}

.ImageSliderCarouselWidget__closeButton {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 5;

    height: 35px;
    width: 35px;

    display: grid;
    place-items: center;

    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;

    backdrop-filter: blur(5px);
}

.ImageSliderCarouselWidget__imageContainer {
    overflow: hidden;
}

.ImageSliderCarouselWidget__image {
    position: relative;
    object-fit: cover;
}

.ImageSliderCarouselWidget__image.fullscreen {
    object-fit: contain;
}

.ImageSliderCarouselWidget__imageBlur {
    z-index: -1;
    position: relative;
    object-fit: cover;
    height: calc(var(--ImageSliderCarouselWidgetHeight) + 50px) !important;
    min-width: calc(100% + 50px);
    inset: -25px;
    opacity: 0.6;

    filter: blur(10px);
}

.ImageSliderCarouselWidget__indicator {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 8px;
}

.ImageSliderCarouselWidget__indicator.selected {
    background-color: var(--secondaryBackgroundColor);

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ImageSliderCarouselWidget__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

/*
    Mosaic
 */

.ImageSliderMosaicWidget {
    position: fixed;
    inset: 0;
    top: env(safe-area-inset, 0);
    overflow: scroll;
    background-color: var(--primaryBackgroundColor);
    z-index: 100;
    overscroll-behavior: contain;
}

.ImageSliderMosaicWidget__webContainer {
    position: fixed;
    inset: 0;
    z-index: 10;
    overflow: visible;
    overscroll-behavior: contain;
    display: grid;
    place-items: center;
    background-color: var(--loaderBackground);
}

.ImageSliderMosaicWidget__webContainer > * {
    width: 100%;
    height: 100%;
}

.ImageSliderMosaicWidget__mosaic {
    padding: 10px;
    padding-top: calc(10px + env(safe-area-inset-top, 0));
    padding-bottom: 40px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.ImageSliderMosaicWidget__image {
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 10px;
    background-color: white;
    box-shadow: var(--cardBoxShadow);
    width: 100%;
}

.ImageSliderMosaicWidget__imageWrapper {
	position: relative;
}

.ImageSliderMosaicWidget__fullscreen {
	pointer-events: none;
	z-index: 2;
	position: absolute;
	display: grid;
	place-items: center;
	top: 10px;
	left: 10px;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
}

.ImageSliderMosaicWidget__imageWrapper:nth-child(-n + 2) {
    grid-column: 1 / 3;
}

/*
    Title
 */

.ImageSliderMosaicTitleWidget {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: env(safe-area-inset-top, -1px);
    z-index: 2;
    height: var(--toolbarHeight);
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(23, 1fr);
    place-items: center;
    backdrop-filter: blur(var(--mobileToolbarBackgroundBlur));
    box-shadow: 0 -4px 8px 9px rgba(0, 0, 0, 0.05);
}

.ImageSliderMosaicTitleWidget__back {
    display: grid;
    place-items: center;
    grid-column: 2/4;
}
