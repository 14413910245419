.MobileCommunitiesLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    padding-bottom: 20px;
    background-color: var(--primaryBackgroundColor);
}

.MobileCommunitiesFeedWidget {
    padding-bottom: 50px;
}

.MobileCommunitiesDiscoverWidget {
    margin-bottom: 100px;
}

.MobileCommunitiesListWidget {
    margin-bottom: 100px;
}

.MobileCommunitiesDiscoverWidget h4 {
    margin: 25px 25px 0;
}

.MobileCommunitiesFeedWidget__wrapper {
    padding: 0 20px 20px;

    display: grid;
    grid-gap: 10px;
    margin-bottom: 80px;
}

.MobileCommunitiesFeedWidget__communityInfo {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;
    padding-right: 5px;
}

.MobileCommunitiesFeedWidget__communityInfo > img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.MobileCommunitiesFeedWidget__communityInfo > p {
    margin-left: auto;
}

/**

    Small Community Widget

 */

.MobileCommunitiesSmallWidget {
    width: 100px;
    height: 100px;

    display: grid;
    place-items: center;
    position: relative;

    border-radius: var(--borderRadiusCard);
    overflow: hidden;
    box-shadow: var(--cardBoxShadow);
}

.MobileCommunitiesSmallWidget__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MobileCommunitiesSmallWidget__numMembers {
    position: absolute;
    bottom: 3px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 5px;
    color: white;
}

.MobileCommunitiesSmallWidget__numMembers > svg {
    width: 12px;
    height: 12px;
    margin-right: 3px;
}

.MobileCommunitiesSmallWidget__nameWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;

    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(150, 150, 150, 0.2));

    display: grid;
    place-items: center;
}

.MobileCommunitiesSmallWidget__name {
    position: absolute;
    padding: 5px;
    text-align: center;
    color: white;

    display: grid;
    place-items: center;

    font-weight: 500;
}

/**

    Wide Widget

 */

.MobileCommunitiesWideWidget {
    width: 100%;
    height: 150px;

    display: grid;
    place-items: center;
    position: relative;

    border-radius: var(--borderRadiusCard);
    overflow: hidden;
    box-shadow: var(--cardBoxShadow);
}

.MobileCommunitiesWideWidget__img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.MobileCommunitiesWideWidget__nameWrapper {
    position: absolute;
    width: 100%;
    height: 70%;
    bottom: 0;

    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));

    display: grid;
    place-items: end start;
}

.MobileCommunitiesWideWidget__name {
    position: absolute;
    padding: 15px;
    text-align: center;
    color: white;
    line-height: 22px;

    display: grid;
    place-items: center;
}

/**
    Communities Selection
 */

.MobileCommunitiesSelectionWidget {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    place-items: center;
    padding: 20px 20px 0;
}

.MobileCommunitiesSelectionWidget__selection {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    padding: 10px 10px;

    display: grid;
    grid-auto-flow: column;
    place-items: center;

    width: 100%;

    transition: all .15s;
}

.MobileCommunitiesSelectionWidget__selection > svg {
    width: 15px;
    height: 15px;
}

.MobileCommunitiesSelectionWidget__selection > p {
    white-space: nowrap;
    overflow: hidden;
}

.MobileCommunitiesSelectionWidget__selection.selected {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
}
