@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');



@import "ui/webClient/styles/anim/anim-FadeIn.css";
@import "ui/webClient/styles/anim/anim-ScaleIn.css";

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;

    padding: env(safe-area-inset-top, 0) 0 0;
}

html {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
    background: white;
}

body {
    overscroll-behavior: none !important;
}

:root {
    /****************************************************** COLOR *******************************************************/
    --mobileNavbarBackgroundColor: #FFFFFF;
    --mobileToolbarBackgroundColor: #FAFAFA;
    --mobileToolbarBackgroundBlur: 15px;
    --mobileNavbarBackgroundBlur: 16px;

    --mobileNavbarHeight: 55px;
    --mobileNavbarHeightIOS: 55px;
    --mobileNavbarBottomOffset: 16px; /* 1px == off */
    --toolbarHeight: 54px;

    --primaryTextColor: black;
    --secondaryTextColor: #6F6F6F;
    --altTextColor: #A1A1A1;
    --primaryColor: black;
    --secondaryColor: #6F6F6F;
    --primaryBackgroundColor: #f5f6fa;
    --secondaryBackgroundColor: #ffffff;
    --accentBackgroundColor: #f1f1f1;
    --accentColor: #18A0FB;
    --altAccentColor: #4DB7FF;
    --altBackgroundColor: #F2F2F2;
    --altSeparatorColor: #C1C1C1;
    --loaderBackground: #2A292D;
    --unreadNotificationBackground: #F84F31;

    --incorrectInputRed: #F47070;

    --acceptColor: #06c666;
    --denyColor: #f93939;

    --orderStatusPendingEscrow: #A1A1A1;
    --orderStatusPendingShipping: #A1A1A1;
    --orderStatusInTransit: #ffcc00;
    --orderStatusPendingConfirmation: #058646;
    --orderStatusSuccess: #058646;
    --orderStatusInvalid: #F47070;


    /*** Tools variables ***/
    --borderRadiusCard: 25px;
    --borderRadiusRounded: 180px;
    --fontWeightBold: 700;
    --fontWeightSemiBold: 600;
    --fontWeightMedium: 500;
    --fontWeightNormal: 400;
    --fontWeightLight: 300;
    --cardBoxShadow: 0 0 8px 8px rgba(100, 100, 100, 0.015);

    /*** Profile Activity Colors ***/
    --profileActiveColor: #38bf08;
}

h1 {
    font-family: 'Cairo', sans-serif;
    font-size: 28px;
    font-weight: var(--fontWeightBold);
}

h2 {
    font-family: 'Cairo', sans-serif;
    font-size: 22px;
    font-weight: var(--fontWeightSemiBold);
    margin: 0;
}

h3 {
    font-family: 'Cairo', sans-serif;
    font-size: 18px;
    font-weight: var(--fontWeightSemiBold);
}

h4 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 18px;
    font-weight: var(--fontWeightSemiBold)
}

h5 {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    line-height: 18px;
}

h5:link {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
}

h6 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
}

p {
    font-weight: var(--fontWeightNormal);
    font-size: 16px;
    line-height: 17px;
}

.p1 {
    font-style: normal;
    font-weight: var(--fontWeightBold);
    font-size: 16px;
    line-height: 19px;
}

.p2 {
    font-style: normal;
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
    line-height: 19px;
}

.p3 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 14px;
    line-height: 17px;
}

.p4 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 14px;
    line-height: 17px;
}

.p5 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 12px;
    line-height: 14px;
}

.p6 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 12px;
    line-height: 12px;
}

.p7 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 10px;
    line-height: 10px;
}

.p8 {
    font-style: normal;
    font-weight: var(--fontWeightNormal);
    font-size: 8px;
    line-height: 8px;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.StatusBarBackground {
    z-index: 1000;
    position: fixed;
    top: 0;
    height: env(safe-area-inset-top, 0);
    width: 100vw;
}

