.MobileAddWatchPopupLayout {
    --AddWatchBorder: 74px;
    --AddWatchPadding: 20px;
    --AddWatchWidth: 100vw;
    --AddWatchColor: rgba(100, 100, 100, 0.2);
    --AddWatchButtonSize: calc(var(--AddWatchWidth) / 2 - 30px);

    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;

    z-index: 2;

    opacity: 0;

    display: grid;
    place-items: end center;

    touch-action: none;

    transition: all .25s;
}

.MobileAddWatchPopupLayout.iOS {
    --AddWatchBorder: 74px;
}

.MobileAddWatchPopupLayout.active {
    top: 0;
    opacity: 1;
}

.AddWatchWrapper__backgroundWrapper {
    position: relative;

    width: 100%;
    height: 100%;

    place-self: start;

    opacity: 0;

    background-color: rgba(100, 100, 100, 0.6);

    transition: all .25s;
}

.AddWatchWrapper__backgroundWrapper.active {
    opacity: 1;
}

.AddWatchWrapper {
    position: absolute;

    width: 100%;
    top: calc(100vh - (var(--AddWatchWidth) / 2) - 40px - var(--mobileNavbarHeight));
    bottom: 0;

    background: linear-gradient(var(--secondaryBackgroundColor), var(--primaryBackgroundColor));
    border-radius: 25px 25px 0 0;
    box-shadow: var(--cardBoxShadow);

    max-height: calc((var(--AddWatchWidth) / 2) + var(--mobileNavbarHeight) + 40px);

    transition: all .25s;
}

.AddWatchWrapper.active {
    bottom: 0;
}

.AddWatchWrapper.showButton {
    top: calc(100vh - (var(--AddWatchWidth) / 2) - 40px - var(--mobileNavbarHeight) - 90px);
    max-height: calc((var(--AddWatchWidth) / 2) + var(--mobileNavbarHeight) + 40px + 90px);
}

.AddWatchWrapper__addButton {
    display: grid;
    place-items: center;
    padding: 10px;
    width: 100%;
    color: var(--secondaryBackgroundColor);

    opacity: 0;
    height: 0;

    transition: all .25s;
}

.AddWatchWrapper__addButton.showButton {
    opacity: 1;
    height: 55px;
}

.AddWatchWrapper__addButton > p {
    background-color: var(--accentColor);
    padding: 16px 72px;
    border-radius: 25px;
    box-shadow: var(--cardBoxShadow);
}

.AddWatchWrapper__buttonWrapper {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    grid-gap: 15px;
    padding: 25px 15px;
}

.AddWatchVault,
.AddWatchSale {
    position: relative;

    height: var(--AddWatchButtonSize);
    width: var(--AddWatchButtonSize);

    border: 6px solid var(--AddWatchColor);
    border-radius: 40px;
    background-color: var(--secondaryBackgroundColor);

    transition: all .25s;

    color: var(--AddWatchColor);

    display: grid;
    place-items: center;
}

.AddWatchSale > svg,
.AddWatchVault > svg {
    position: absolute;
    bottom: 20px;

    width: calc(var(--AddWatchButtonSize) / 2);
    height: calc(var(--AddWatchButtonSize) / 2);

    transform: scale(0.9);
    transition: all .25s;
}

.AddWatchSale > p,
.AddWatchVault > p {
    position: absolute;
    top: 20px;
    color: var(--altSeparatorColor);

    transform: scale(0.8);

    transition: all .25s;
}

.AddWatchVault:hover,
.AddWatchSale:hover {
    border-color: var(--accentColor);
}

.AddWatchVault:hover > p,
.AddWatchSale:hover > p  {
    color: var(--accentColor);
}

.AddWatchVault:hover .AddWatchVault__blueIcon,
.AddWatchSale:hover .AddWatchSale__blueIcon  {
    opacity: 1;
    transform: scale(1);
}

.AddWatchVault:focus,
.AddWatchSale:focus {
    border-color: var(--accentColor);
}

.AddWatchVault:focus > p,
.AddWatchSale:focus > p  {
    color: var(--accentColor);
}

.AddWatchVault:focus .AddWatchVault__blueIcon,
.AddWatchSale:focus .AddWatchSale__blueIcon  {
    opacity: 1;
    transform: scale(1);
}

.AddWatchVault {
}

.AddWatchSale {
}

.AddWatchSale__icon,
.AddWatchVault__icon {
    transition: all .25s;
    opacity: 1;
}

.AddWatchVault__blueIcon,
.AddWatchSale__blueIcon {
    transition: all .25s;
    opacity: 0;

    z-index: 1;
}