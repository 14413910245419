.MobileAddCommunityLayout {
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileAddCommunityLayout__divider {
    height: 1px;
    width: 100%;
    background-color: var(--loaderBackground);
    border-radius: 4px;
    opacity: 0.2;
}

/**

    SEARCH MEMBER VIEW

 */

.AddCommunityNewMemberSearchWidget {
    top: 60px;

    z-index: 3;
    position: sticky;
    padding: 10px;
    width: 100%;

    display: grid;
}

.AddCommunityNewMemberSearchWidget__input {
    width: 100%;
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    outline: none;
    border: none;

    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.AddCommunityNewMemberSearchWidget__searchableMembersWrapper {
    display: grid;
    grid-gap: 10px;
    padding: 10px 0;
}

.MobileAddCommunityLayout__membersToInviteWrapper {
    display: grid;
    grid-gap: 10px;
    padding: 0 20px 20px;
}

.MobileAddCommunityLayout__membersToInviteWrapper > p {
    margin-left: 5px;
}

/**


    FORM WIDGET


 */

.AddCommunityFormWidget__wrapper {
    display: grid;
    grid-gap: 20px;
    padding: 20px;
}

.AddCommunityFormWidget__wrapper > p {
    margin-left: 5px;
}

    /**
    Button
     */

.AddCommunityFormWidget__continueButton {
    background-color: var(--accentColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    color: white;
    padding: 10px 20px;

    display: grid;
    place-items: center;

    transition: all .1s;
}

.AddCommunityFormWidget__continueButton:active {
    opacity: 0.8;
}

.AddCommunityFormWidget__inviteButton {
    background-color: var(--primaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: 0 2px 18px 7px rgb(100 100 100 / 8%);

    padding: 10px 20px;

    display: grid;
    place-items: center;

    transition: all .1s;
}

.AddCommunityFormWidget__inviteButton:active {
    opacity: 0.8;
}

.AddCommunityFormWidget__deleteButton {
    background-color: var(--incorrectInputRed);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 10px 20px;
    color: white;

    display: grid;
    place-items: center;

    transition: all .1s;
}

.AddCommunityFormWidget__deleteButton:active {
    opacity: 0.8;
}

/**

    Textarea

 */

.AddCommunityFormWidget__multiline {
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    position: relative;
}

.AddCommunityFormWidget__multilineRequired {
    position: absolute;
    top: 15px;
    right: 15px;

    color: var(--incorrectInputRed);
    margin-left: 10px;
    font-size: 18px;
    line-height: 10px;
    margin-bottom: -5px;

    opacity: 0.6;
}

.AddCommunityFormWidget__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    position: relative;
    border-radius: var(--borderRadiusCard);

    border-bottom: 1px solid var(--primaryBackgroundColor);
}
.AddCommunityFormWidget__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.AddCommunityFormWidget__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    overflow-wrap: break-word;

    max-width: 93vw;
}
.AddCommunityFormWidget__multilineWrapper > .AddCommunityFormWidget__multiline,
.AddCommunityFormWidget__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    overflow-wrap: break-word;

    font: inherit;

    max-width: 93vw;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}

/**
    Cover photo
 */

.AddCommunityFormWidget__coverPhotoWrapper {
    width: 100%;
    display: grid;
    place-items: center;
    grid-gap: 20px;
}

.AddCommunityFormWidget__coverPhoto {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);

    width: 300px;
    height: 150px;

    display: grid;
    place-items: center;
}

.AddCommunityFormWidget__coverPhoto > svg {
    width: 40px;
    height: 40px;

    opacity: 0.6;
}

/**

    Form Input

 */

.AddCommunityFormInput {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;
}

.AddCommunityFormInput__wrapper {
    position: relative;

    display: grid;
    place-items: center;
}

.AddCommunityFormInput__rightWrapper {
    right: 10px;

    position: absolute;
    text-align: right;
    opacity: 0.6;

    display: grid;
    place-items: center;
    grid-auto-flow: column;
}

.AddCommunityFormInput__rightWrapper > h4 {
    color: var(--incorrectInputRed);
    margin-left: 10px;
    font-size: 18px;

    line-height: 10px;
    margin-bottom: -5px;
}

/**
    Radio
 */

.AddCommunityFormWidget__radio {
    user-select: none;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-row-gap: 5px;
    place-items: center start;
    margin-left: 10px;
}