

.MobileLandingScreenBackgroundWidget {

}

.MobileLandingScreenBackgroundWidget__img.show {
    opacity: 1;
    visibility: visible;


    animation: FadeIn 2s ease;
}

.MobileLandingScreenBackgroundWidget__img.hide {
    opacity: 0;
    visibility: visible;


    animation: FadeOut 2s ease;
}

.MobileLandingScreenBackgroundWidget__img.disable {
    visibility: hidden;
}

.MobileLandingScreenBackgroundWidget__img {
    height: calc(100vh - env(safe-area-inset-top, 0));
    min-width: 100%;
    width: auto;
    visibility: hidden;

    object-fit: cover;

    position: absolute;
    top: env(safe-area-inset-top, 0);
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    z-index: 0;
}
