/**

    Action Required

 */

.MobileActionRequired__actionRequired {
    display: flex;
}

.MobileActionRequired__actionRequired :not(:last-child) {
    margin-right: 10px;
}

.MobileActionRequired {
    border-radius: var(--borderRadiusCard);
    background-color: var(--secondaryBackgroundColor);
}

.MobileActionRequired.medium {
    border: 4px solid #ffcc00
}

.MobileActionRequired.high {
    border: 4px solid var(--incorrectInputRed);
}

.MobileActionRequired.cool {
    border: 4px solid var(--accentColor);
}

.MobileActionRequired.success {
    border: 4px solid var(--acceptColor);
}

.MobileActionRequired__actionRequiredButton {
    width: 50px;
    height: 50px;

    padding: 10px;

    background-color: var(--primaryBackgroundColor);
    color: var(--loaderBackground);
    border-radius: 25px;

    display: grid;
    place-items: center;

    transition: all .2s;
}

.MobileActionRequired__actionRequiredButton:active {
    transform: scale(0.85);
}

.MobileActionRequired__actionRequiredButton.green {
    background-color: var(--acceptColor);
    color: white;
}

.MobileActionRequired__actionRequiredButton.red {
    background-color: var(--denyColor);
    color: white;
}
