.MobileWritePostLayout {
    position: fixed;
    touch-action: none;
    -ms-touch-action: none;
    height: 100vh;
    width: 100vw;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    opacity: 0;

    transition: all .4s;
}

.MobileWritePostLayout.active {
    opacity: 1;
}

.MobileWritePostLayout__wrapper {
    touch-action: none;
    -ms-touch-action: none;

    position: absolute;
    inset: 0;
    top: 100vh;
    background-color: var(--secondaryBackgroundColor);

    display: grid;
    grid-template-rows: 70px 1fr;

    transition: all .4s;
}

.MobileWritePostLayout__wrapper.active {
    top: env(safe-area-inset-top, 0);
}

/**
    Header
 */

.MobileWritePostToolbar {
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    place-items: center;

    padding: 20px;

    border-bottom: 1px solid var(--primaryBackgroundColor);
}

.MobileWritePostToolbar__exitButton {
    place-self: center start;
    width: 30px;
    height: 30px;
    padding: 7px;
}

.MobileWritePostToolbar__postButton {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 5px 10px;
    border-radius: var(--borderRadiusCard);
    place-self: center end;
    opacity: 0.3;

    transition: all .25s;
}

.MobileWritePostToolbar__postButton.available {
    background-color: var(--accentColor);
    opacity: 1;
}

/**
    Text Area
 */

.MobileWritePostInput {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.MobileWritePostInput__textLimit {
    position: absolute;
    bottom: 10px;
    right: 13px;
}

.MobileWritePostInput__addPhoto {
    position: absolute;
    right: 10px;
    top: 5px;

    width: 28px !important;
    height: 28px !important;
}

.MobileWritePostInput__imageWrapper {
    position: relative;
}

.MobileWritePostInput__image {
    object-fit: contain;
}

.MobileWritePostInput__imageClose {
    position: absolute;

    display: grid;
    place-items: center;

    width: 40px;
    height: 40px;
    right: 10px;
    top: 10px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    color: white;
}

.MobileWritePostInput__multilineWrapper {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
    position: relative;

    border-bottom: 1px solid var(--primaryBackgroundColor);
}
.MobileWritePostInput__multilineWrapper::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
}
.MobileWritePostInput__multilineWrapper > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
    overflow-wrap: break-word;

    max-width: 92vw;
}
.MobileWritePostInput__multilineWrapper > .MobileWritePostInput__multiline,
.MobileWritePostInput__multilineWrapper::after {
    /* Identical styling required!! */
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-decoration: none;
    overflow-wrap: break-word;

    font: inherit;

    max-width: 92vw;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
}
