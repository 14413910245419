


.MobileMenuLayout {
    background: var(--secondaryBackgroundColor);
    min-height: calc(100vh - env(safe-area-inset-top, 0));
}

.MobileMenuExitBtnWidget {
    --ExitBtnHeight: 70px;

    width: 100%;
}

.MobileMenuExitBtnWidget__button {
    position: absolute;
    width: var(--ExitBtnHeight);
    height: var(--ExitBtnHeight);
    display: grid;
    place-items: center;
    cursor: pointer;
}

.MobileMenuHeaderWidget {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    place-items: center;
    padding: 35px 0;
}

.MobileMenuHeaderWidget__imgBorder {
    width: var(--MMHW_ImgDimTotal);
    height: var(--MMHW_ImgDimTotal);
    border: var(--primaryColor) var(--MMHW_ImgBorder) solid;
    border-radius: var(--MMHW_ImgBorderRadius);
    overflow: hidden;
    display: grid;
    place-items: center;
}

.MobileMenuHeaderWidget__imgBorderFill {
    width: calc(var(--MMHW_ImgDimTotal) - var(--MMHW_ImgBorderFill) - var(--MMHW_ImgBorderFill));
    height: calc(var(--MMHW_ImgDimTotal) - var(--MMHW_ImgBorderFill) - var(--MMHW_ImgBorderFill));
    border-radius: calc(var(--MMHW_ImgBorderRadius) - 2px);
    overflow: hidden;
    display: grid;
    place-items: center;
}

.MobileMenuHeaderWidget__imgContainer {
    --MMHW_ImageDim: 108px;
    --MMHW_ImgBorderRadius: 35px;
    --MMHW_ImgBorder: 2px;
    --MMHW_ImgBorderFill: 4px;
    --MMHW_ImgDimTotal: calc(var(--MMHW_ImageDim) + var(--MMHW_ImgBorder) + var(--MMHW_ImgBorder) + var(--MMHW_ImgBorderFill) + var(--MMHW_ImgBorderFill));

    display: grid;
    place-items: center;
    border-radius: var(--MMHW_ImgBorderRadius);
    overflow: hidden;
    height: var(--MMHW_ImgDimTotal);
    width: var(--MMHW_ImgDimTotal);
}

.MobileMenuHeaderWidget__imgContainer img {
    object-fit: cover;
    min-height: calc(var(--MMHW_ImgDimTotal) - var(--MMHW_ImgBorder) - var(--MMHW_ImgBorder));
    min-width: 100%;
}

.MobileMenuHeaderWidget h2 {
    margin-top: 10px;
    max-width: 80ch;
}