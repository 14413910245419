.MobileOfferingCommentsWidget {
}

.MobileOfferingCommentsWidget__comments {
    display: grid;
    place-items: center;
    grid-gap: 10px;
    width: 100%;

    padding: 0 15px;
    margin-top: 15px;
}

.MobileOfferingCommentsWidget__comments > * {
    width: 100%;
}

.MobileOfferingCommentsWidget__commentDetails {
    display: grid;
    justify-content: space-between;
    height: 100%;
}

.MobileOfferingCommentsWidget__commentDetails > p {
    place-self: start end;
}

.MobileOfferingCommentsWidget__commentDetails > svg {
    place-self: end end;
    opacity: 0.7;
}

.MobileOfferingCommentsWidget__viewMore {
    color: var(--accentColor);
    text-decoration: underline;
    text-align: center;
    margin-top: 15px;
}

.MobileOfferingCommentsWidget__noComments {
    text-align: center;
    opacity: 0.6;
}

.MobileOfferingCommentsWidget__fetchMore {
    padding: 20px;
    text-align: center;

    transition: all .2s;
}

.MobileOfferingCommentsWidget__fetchMore:active {
    opacity: 0.6;
}
