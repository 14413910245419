


.MobileLoginWithEmailWidget {
    width: 100%;
}

.MobileLoginWithEmailWidget--wrapper {
    width: 100%;
    min-height: calc(100vh - env(safe-area-inset-top, 0));
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
}

.MobileLoginWithEmailWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileLoginWithEmailWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}

.MobileLoginWithEmailWidget label {
    opacity: 0;
}

.MobileLoginWithEmailWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.MobileLoginWithEmailWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
}

.MobileLoginWithEmailWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    margin: 8px 0;
}

.MobileLoginWithEmailWidget .submitBtn {
    color: white;
    background: var(--accentColor);
    border: var(--accentColor) 3px solid;

}

.MobileLoginWithEmailWidget .signUpBtn {
    color: var(--primaryTextColor);
    background: var(--primaryBackgroundColor);
    border: none;
}

.MobileLoginWithEmailWidget__forgotPassword {
    width: 100%;
    display: grid;
    place-items: center;
    cursor: pointer;
    margin: 2rem 0;
    padding: 1rem 0;
    text-align: center;
}

.MobileLoginWithEmailWidget__buttons--wrapper {
    width: 100%;
    display: grid;
    place-items: center;

    /*
    position: absolute;
    top: 70vh;
    left: 0;
    right: 0;
    padding: 20px;
     */
}

.MobileLoginWithEmailWidget__altLoginBtn {
    padding: 10px;
    cursor: pointer;
}

.MobileLoginWithEmailWidget .visibilityIcon {
    cursor: pointer;
}

.MobileLoginWithEmailWidget .inputPassword.wrongPassword {
    border: var(--incorrectInputRed) 2px solid;
}

.MobileLoginWithEmailWidget__errorMessage {
    color: var(--incorrectInputRed);
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

