.MobileOrderCardContentWidget {
	background-color: var(--secondaryBackgroundColor);
	box-shadow: var(--cardBoxShadow);
	border-radius: var(--borderRadiusCard);
	
	display: grid;
	grid-template-rows: 18px 4px 1fr;
	grid-gap: 10px;
	
	padding: 20px;
}

.MobileOrderCardContentWidget__title {
	display: flex;
	align-items: center;
}

.MobileOrderCardContentWidget__subTitle {
	margin-left: auto;
}

.MobileOrderCardContentWidget__statusBarWrapper {
	height: 4px;
	width: 100%;
	background-color: var(--primaryBackgroundColor);
	border-radius: 2px;
}

.MobileOrderCardContentWidget__statusBar {
	height: 4px;
	border-radius: 2px;
}

.MobileOrderCardContentWidget__informationWidget {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 20px;
}

.MobileOrderCardContentWidget__image {
	display: grid;
	place-items: center;
	object-fit: cover;
	min-height: 100px;
	
	background-color: var(--primaryBackgroundColor);
	border-radius: var(--borderRadiusCard);
}

.MobileOrderCardContentWidget__information {
	display: grid;

	grid-gap: 10px;
}

.MobileOrderCardContentWidget__informationTable {
	display: flex;
	flex-direction: column;

	gap: 3px;
}

.MobileOrderCardContentWidget__informationEntry {
	display: grid;
	grid-template-columns: 1fr 1.3fr;
	word-break: break-word;
}

.MobileOrderCardContentWidget__informationEntry :first-child {
	font-weight: 600;
}

.MobileOrderCardContentWidget__informationEntry :last-child {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.MobileOrderCardContentWidget__tracking {
	place-self: end;
	text-align: right;
}

.MobileOrderCardContentWidget__trackingTitle {
	color: var(--accentColor);
}
