.MobileSocialLoungeConversationsWidget {
	display: grid;
	grid-gap: 10px;
	padding: 0 20px 20px;
	padding-bottom: 100px;
}

.MobileLoungeChatConversationWidget__contentWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	height: 50px;
	margin-left: auto;
}

.MobileLoungeChatConversationWidget__time {
	opacity: 0.6;
	align-self: flex-end;
	text-align: right;
	margin: 0 !important;
}

.MobileLoungeChatConversationWidget__lastMessage {
	display: flex;
	align-items: center;
}

.MobileLoungeChatConversationWidget__lastMessage > img {
	width: 20px;
	min-width: 20px;
	max-width: 20px;

	height: 20px;
	min-height: 20px;
	max-height: 20px;

	margin-right: 5px;

	border-radius: 50%;
}

.MobileLoungeChatConversationWidget__lastMessage > p {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}

.MobileLoungeChatConversationWidget__numMessages {
	background-color: var(--accentColor);
	color: white;
	border-radius: 10px;
	font-size: 14px;
	line-height: 20px;
	min-width: 20px;
	height: 20px;
	padding: 0 5px;
	display: grid;
	place-items: center;
}

.MobileSocialLoungeConversationsWidget__actionButton {
	position: fixed;
	top: calc(88vh - var(--mobileNavbarHeight));
	right: 20px;
	/*bottom: calc(var(--mobileNavbarHeightIOS) + 20px);*/
	bottom: calc(76px + 20px);
	background-color: var(--secondaryBackgroundColor);
	box-shadow: var(--cardBoxShadow);
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	z-index: 2;
	
	transition: all .2s;
}

.MobileSocialLoungeConversationsWidget__actionButton:active {
	transform: scale(0.8);
}

.MobileSocialLoungeConversationsWidget__actionButton > svg {
	width: 30px;
	height: 30px;
	display: grid;
	place-items: center;
}
