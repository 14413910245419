@import "../../../styles/anim/anim-FadeIn.css";
@import "../../../styles/anim/anim-FadeOut.css";

.toast--wrapper {
    padding: 0 15px;
    display: grid;
    place-items: center;
    height: 2.5em;
    max-width: 100%;
    border-radius: 25px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    background: rgba(230, 230, 230, 0.7);
    overflow: hidden;

    opacity: 1;
    animation: FadeIn 200ms ease-in;


    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.toast {
    position: fixed;
    top: 80vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.toast-message {
    display: grid;
    place-items: center;
    color: #222;
    text-align: center;
}

.hide {
    opacity: 0;
    animation: FadeOut 500ms ease-in;
}

