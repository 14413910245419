.MobileWatchAnalyticsWidget {
    display: grid;
    grid-gap: 10px;
}

.MobileWatchAnalyticsWidget__chartWrapper {
    height: 200px;
}

.MobileWatchAnalyticsWidget__chartLoader {
    display: grid;
    place-items: center;

    height: 200px;
}

.MobileWatchAnalyticsWidget__empty {
    display: grid;
    grid-gap: 10px;
    text-align: center;
    place-items: center;
    padding: 10px 0;
}