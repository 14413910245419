.MobileWatchListingCardWidget {
    display: grid;
    place-items: center;
    height: 280px;

    border-radius: var(--borderRadiusCard);
    background: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
}

.MobileWatchListingCardWidget__topSection {
    display: grid;
    grid-template-columns: 28px 1fr;
    place-items: start;
    grid-gap: 10px;

    width: 100%;
    padding: 10px 10px 0;
}

.MobileWatchListingCardWidget__ownerImage {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50%;
}

.MobileWatchListingCardWidget__details {

}

.MobileWatchListingCardWidget__details > h6 {
    font-weight: var(--fontWeightSemiBold);
    margin-bottom: 2px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileWatchListingCardWidget__details > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.MobileWatchListingCardWidget__middleSection {
    display: grid;
    place-items: center;
    padding: 0 10px;
    width: 100%;
}

.MobileWatchListingCardWidget__mainImage {
    height: 180px;
    width: 100%;
    border-radius: var(--borderRadiusCard);
    object-fit: cover;
}

.MobileWatchListingCardWidget__bottomSection {
    width: 100%;

    display: flex;
    align-items: center;
    padding: 0 10px 4px;
}

.MobileWatchListingCardWidget__price {
    margin-left: auto;
}

.MobileWatchListingCardWidget__buttonWrapper {
    display: grid;
    place-items: center;
}

.MobileWatchListingCardWidget__heartOutlineIcon {
    width: 15px;
    height: 15px;

    animation: ScaleIn .1s ease-in;
}

.MobileWatchListingCardWidget__heartIcon {
    width: 15px;
    height: 15px;

    animation: ScaleIn .1s ease-in;

    color: var(--accentColor);
}

.MobileWatchListingCardWidget__moreIcon {
    width: 20px;
    height: 20px;
}
