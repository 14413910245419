/**

    Watch Collection Wrapper Widget

 */

.MobileWatchCollectionWrapperWidget {
    display: grid;
    grid-template-rows: 20px 1fr;
    grid-gap: 10px;
}

.MobileWatchCollectionWrapperWidget__title {
    margin-left: 10px;
}

.MobileWatchCollectionGridWidget {
    display: grid;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileWatchCollectionGridWidget__collectionItem {
    aspect-ratio: 1 / 1;
    width: 100%;

    display: grid;
    place-items: center;

    border-radius: 15px;
    box-shadow: var(--cardBoxShadow);

    overflow: hidden;

    object-fit: cover;
}

.MobileWatchCollectionGridWidget__lastItem {
    position: relative;

    aspect-ratio: 1 / 1;
    width: 100%;

    display: grid;
    place-items: center;

    border-radius: 15px;
    box-shadow: var(--cardBoxShadow);

    overflow: hidden;

    object-fit: cover;
}

.MobileWatchCollectionGridWidget__lastItemImage {
    position: absolute;

    width: 100%;
    height: 100%;

    object-fit: cover;

    filter: blur(5px);
}

.MobileWatchCollectionGridWidget__lastItemCounter {
    position: absolute;

    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    background-color: rgba(0, 0, 0, 0.3);

    color: white;
}