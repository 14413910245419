.MobileFullscreenListViewContentWidget {
    overflow: scroll;
    position: fixed;
    inset: 0;
    top: 100vh;
    background-color: var(--primaryBackgroundColor);
    z-index: 5;
    padding: 20px;

    transition: all .25s;
}

.MobileFullscreenListViewContentWidget.active {
    top: env(safe-area-inset-top, 0);
}

.MobileFullscreenListViewContentWidget__backButton {
    z-index: 6;

    display: grid;
    grid-template-columns: 50px 1fr 1fr;

    grid-gap: 20px;
    place-items: center start;

    margin-bottom: 20px;

    font-size: 22px !important;
}

.MobileFullscreenListViewContentWidget__sticky {
    position: sticky;
    top: 50px;

    grid-area: sticky;

    width: 100%;

    transition: all .25s;

    place-self: center end;
}

.MobileFullscreenListViewContentWidget__sticky.decrease {
    width: 83%;
}

.MobileFullscreenListViewContentWidget__title {
    width: 100%;
    text-align: left;
    grid-area: title;
}

.MobileFullscreenListViewContentWidget__backButtonIcon {
    position: sticky;
    top: 0;
    overflow: visible;

    grid-area: back;

    width: 47px;
    height: 47px;

    border-radius: 50%;

    padding: 15px;

    background-color: var(--secondaryBackgroundColor);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}