.MobileFilterListLayout {
    overflow: hidden;
}

.MobileFilterListContentWidget {
    overflow-y: scroll;
    -ms-overflow-style: none;

    padding: 10px;
}

.MobileFilterListContentWidget__buttonsWrapper {
    display: grid;
    place-items: start;
    grid-gap: 5px;

    text-align: center;
}

.MobileFilterListContentWidget::-webkit-scrollbar {
    display: none;
}

.MobileFilterListContentWidget__button {
    display: grid;
    place-items: center;
    height: 25px;

    background-color: #E6E6E6;
    border-radius: var(--borderRadiusCard);

    transition: all .2s;
}

.MobileFilterListContentWidget__button.applied {
    background-color: var(--loaderBackground);
    color: white;
}

.MobileFilterListContentWidget__clear {
    display: grid;
    place-items: center;
    height: 25px;
    width: 40px;

    background-color: var(--loaderBackground);
    color: white;
    border-radius: var(--borderRadiusCard);
}

.MobileFilterListContentWidget__clear > svg {
    width: 18px;
    height: 18px;
}

.MobileFilterListContentWidget__button:last-child {
    margin-right: 10px;
}

/**
    Selection
 */

.MobileFilterListSelectionWidget {
    opacity: 0;
    position: fixed;
    touch-action: none;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;

    transition: opacity .2s;
}

.MobileFilterListSelectionWidget.active {
    opacity: 1;
}

.MobileFilterListSelectionWidget__wrapper {
    position: absolute;
    inset: 0;
    top: auto;
    max-height: 90vh;

    background-color: var(--secondaryBackgroundColor);
    border-radius: 20px 20px 0 0;

    display: flex;
    flex-direction: column;

    transform: translateY(100%);
    transition: transform .2s;
}

.MobileFilterListSelectionWidget__wrapper.active {
    transform: translateY(0);
}

.MobileFilterListSelectionWidget__header {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    padding: 20px;
}

.MobileFilterListSelectionWidget__headerFilter {
    font-size: 22px;
}

.MobileFilterListSelectionWidget__headerTitle {
    display: grid;
    grid-gap: 5px;
}

.MobileFilterListSelectionWidget__headerDivider {
    height: 2px;
    width: calc(100% - 40px);
    place-self: center;
    background-color: var(--primaryBackgroundColor);
}

.MobileFilterListSelectionWidget__headerButtons {
    place-self: end;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
}

.MobileFilterListSelectionWidget__headerButton {
    width: 40px;
    height: 40px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.MobileFilterListSelectionWidget__headerButton {
    width: 40px;
    height: 40px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.MobileFilterListSelectionWidget__searchWrapper {
    padding: 20px 20px;
    width: 100%;
}

.MobileFilterListSelectionWidget__search {
    border: none;
    outline: none;
    font-size: 18px;
}

.MobileFilterListSelectionWidget__options {
    display: grid;
    grid-gap: 5px;
    padding: 10px 20px 20px;

    overflow: scroll;
}

.MobileFilterListSelectionWidget__option {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    grid-gap: 10px;
    padding: 10px 5px;

    transition: all .2s;
}

.MobileFilterListSelectionWidget__option.selected {
    background-color: var(--primaryBackgroundColor);
    border-radius: 10px;
}

.MobileFilterListSelectionWidget__radio {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid var(--primaryBackgroundColor);
    padding: 2px;

    transition: all .2s;
}

.MobileFilterListSelectionWidget__radioJuice {
    transform: scale(0);

    transition: all .2s;
}

.MobileFilterListSelectionWidget__radioJuice.selected {
    transform: scale(1);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--accentColor);
}

.MobileFilterListSelectionWidget__apply {
    margin-top: auto;
    max-height: 0;
    padding: 0;

    transition: all .2s;
}

.MobileFilterListSelectionWidget__apply > p {
    padding: 0 10px;
    background-color: var(--accentColor);
    border-radius: 10px;
    width: 100%;
    height: 40px;
    display: grid;
    place-items: center;
    color: white;
}

.MobileFilterListSelectionWidget__apply.active {
    max-height: 100px;
    padding: 10px 20px 20px;
}

/**
    Range selection
 */

.MobileFilterListRangeWidget__options {
    display: grid;
    max-width: 100vw;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 20px 20px;

    overflow: scroll;
}

.MobileFilterListRangeWidget__clear {
    margin-top: auto;
    padding: 0 20px 20px;
    transition: all .2s;
}

.MobileFilterListRangeWidget__clear > p {
    padding: 0 10px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 10px;
    width: 100%;
    height: 40px;
    display: grid;
    place-items: center;
}
.MobileFilterListRangeWidget__rangeOption {
    display: grid;
    grid-gap: 10px;
}

.MobileFilterListRangeWidget__rangeInput {
    display: grid;
    font-size: 18px;
    outline: none;
    border: 2px solid var(--primaryBackgroundColor);
    border-radius: 10px;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 100%;
}