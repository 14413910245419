/*
    MobileCommunityView
 */

.MobileCommunityLayout {
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileCommunityLayout__privateCommunityAlert {
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
    opacity: 0.8;
    margin-top: 40px;
}

/*
    CommunityContent
*/

.CommunityContent {
    padding: 0 20px 20px;
}

/*
    CommunityHeader
*/

.CommunityHeader {
    position: relative;
    display: grid;
    place-items: center;
}

.CommunityHeader__bottomRibbon {
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    border-radius: var(--borderRadiusCard) var(--borderRadiusCard) 0 0;
    background-color: var(--primaryBackgroundColor);
    bottom: -1px;
}

.CommunityHeader__bottomGradient {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    bottom: 0;
}

.CommunityHeader__topGradient {
    position: absolute;
    left: 0;
    right: 0;
    height: env(safe-area-inset-top);
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
}

.CommunityHeader__info {
    display: grid;
    grid-template-columns: 1fr;
    color: white;
    max-width: 20ch;

    z-index: 1;

    place-self: end start;
}

.CommunityHeader__image {
    width: 100%;
    max-height: 200px;

    object-fit: cover;
}

.CommunityHeader__headerWrapper {
    display: grid;
    width: 100%;
    height: calc(200px + env(safe-area-inset-top));
    padding: 15px 15px 35px;
}

.CommunityHeader__infoName {
    line-height: 28px;
}

.CommunityHeader__infoAlias {

}

.CommunityHeader__infoFriends {
    margin-top: 10px;
    padding: 5px 0;

    width: 10ch;
    text-align: center;

    border-radius: var(--borderRadiusCard);
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
}

.CommunityHeader__infoYou {
    margin-top: 10px;
    padding: 5px 0;

    width: 10ch;
    text-align: center;

    border-radius: var(--borderRadiusCard);
    background-color: var(--loaderBackground);
    color: var(--secondaryBackgroundColor);
}

/**
    CommunityToolbar
 */

.CommunityToolbar {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    padding: calc(10px + env(safe-area-inset-top)) 10px 10px;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: unset;
    box-shadow: unset;

    transition: all .2s;
}

.CommunityToolbar.show {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(var(--mobileToolbarBackgroundBlur));
    box-shadow: 0 -4px 8px 9px rgba(0, 0, 0, 0.05);
}

.CommunityToolbar__title {
    display: grid;
    place-items: center;
    opacity: 0;
    transition: all .2s;
}

.CommunityToolbar__title.show {
    opacity: 1;
}

.CommunityToolbar__iconWrapper {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.3);
    display: grid;
    place-items: center;
    color: white;

    transition: all .2s;
}

.CommunityToolbar__iconWrapper.show {
    background-color: unset;
    backdrop-filter: unset;
    color: #4a4a4a;
}

.CommunityToolbar__buttonsWrapper {
    display: flex;
    justify-content: space-between;
    height: 40px;
    top: 10px;
}

.CommunityToolbar__iconWrapper.right {
    place-self: start end;
}

/*
    CommunityInfo
*/

.CommunityInfo {
    padding-top: 0;
}

.CommunityInfoHeader {
    --CommunityInfoMaxHeight: 180px;

    display: grid;
    grid-template-columns: 1fr auto;
    gap: 5px;

    padding: 15px;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    max-height: var(--CommunityInfoMaxHeight);
    position: relative;
    overflow: hidden;

    transition: all .25s ease;
}

.CommunityInfoHeader.selected {
    max-height: 1000px;
}

.CommunityInfoHeader__infoText {
    display: grid;
    gap: 5px;
    overflow: hidden !important;
    max-height: calc(var(--CommunityInfoMaxHeight) - 30px);

    mask-image: none;

    transition: all .25s ease;
}

.CommunityInfoHeader__infoText.selected {
    max-height: 1000px;

    mask-image: none;
}

.CommunityInfoHeader__infoText.fade {
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.CommunityInfoHeader__item {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 10px;
}

.CommunityInfoHeader__bio {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 10px;
    overflow: hidden;

    /* line-height of bio * lines to show */
    max-height: calc(18px * 5);

    transition: all .25s ease;
}

.CommunityInfoHeader__bio.selected {
    overflow: visible;
    max-height: 1000px;
}

.CommunityInfoHeader__key {
    color: var(--accentColor);
    line-height: 18px;
}

.CommunityInfoHeader__value {
	max-height: 800px;
    line-height: 18px;
    overflow: auto;
    word-wrap: break-word;
	white-space: pre-wrap;
}

.CommunityInfoHeader__buttonOptions {
    padding-top: 5px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: calc(var(--CommunityInfoMaxHeight) - 30px);

    transition: all .25s ease;
}

.CommunityInfoHeader__buttonOptions.selected {
    max-height: 1000px;
}

.CommunityInfoHeader__editIcon {
    place-self: start;
}

.CommunityInfoHeader__moreIcon {
    place-self: end;

    transition: all .25s ease;
}

.CommunityInfoHeader__moreIcon.selected {
    transform: rotateZ(180deg);
}

/**

    CommunitySocialButtons

 */

.CommunitySocialButtons {
    margin: 10px -20px 0;
}

.CommunitySocialButton {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);

    padding: 5px 15px;

    display: grid;
    place-items: center;
    grid-auto-flow: column;
    grid-gap: 10px;

    width: 110px;
    height: 28px;
}

.CommunitySocialButton > p {
    display: grid;
    place-items: center;
    place-self: start;
}

.CommunitySocialButton > svg {
    width: 18px;
    height: 18px;
    place-self: start;
}

/*
    CommunityInfoButtons
 */

@media only screen and (max-width: 350px) {
    .CommunityInfoButtons {
        gap: 10px !important;
    }

    .CommunityInfoButton__name {
        font-size: 9px;
    }

    .CommunityInfoButton__amount {
        font-size: 13px;
    }

    .CommunityInfoButton {
        border-radius: 30% !important;
    }
}

.CommunityInfoButtons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    gap: 20px;
}

.CommunityInfoButton {
    display: grid;

    background: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    text-align: center;

    cursor: pointer;
}

.CommunityInfoButton.selected {
    color: var(--accentColor);
}

.CommunityInfoButton::before {
    content: "";
    padding-bottom: 100%;
    display: block;

    grid-area: 1 / 1 / 2 / 2;
}

.CommunityInfoButton__info {
    grid-area: 1 / 1 / 2 / 2;
    display: grid;
    place-items: center;
}

.CommunityInfoButton__infoWrapper {
    display: grid;
    place-items: center;
    grid-gap: 5px;
}

.CommunityInfoButton__name {
}

.CommunityInfoButton__amount {
}

/*
    CommunityItem
 */

.CommunityItem {
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 200px;
}

/*
    Feed
 */

.CommunityVaultSection {
    padding: 20px;
    margin-bottom: 70px;
}

.CommunityPostsSection {
    padding: 20px;
    margin-bottom: 70px;

    display: grid;
    grid-gap: 20px;
}


.CommunityMembersSection {
    padding: 20px;
    margin-bottom: 70px;

    display: grid;
    grid-gap: 10px;
}

.CommunityTopicsSection {
    padding: 20px;
    margin-bottom: 70px;
}

.CommunityFeedSection {
    padding: 20px;
    margin-bottom: 70px;

    display: grid;
    grid-gap: 20px;
}

.CommunityFeedSection__postsWidget {
    display: grid;
    grid-gap: 20px;
}

/**

        Community Invite Member

 */

.CommunityInviteMember {
    top: 60px;

    z-index: 4;
    position: sticky;
    padding: 10px;
    width: 100%;

    display: grid;
}

.CommunityInviteMember__input {
    width: 100%;
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    outline: none;
    border: none;

    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.CommunityInviteMember__searchableMembersWrapper {
    display: grid;
    grid-gap: 10px;
    padding: 10px 0;
}

/**

    Community Members Sections
 */

.CommunityMembersSection__userGroup {
    padding: 10px 15px;
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);

    display: grid;
    grid-gap: 10px;
}

.CommunityMembersSection__userPictures {
    display: flex;
    align-items: center;
}

.CommunityMembersSection__userPictures > img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: -7px;
    box-shadow: -1px 1px 2px 1px rgba(100, 100, 100, 0.25);

    background-color: var(--primaryBackgroundColor);
}

.CommunityMembersSection__userPictures > p {
    padding-left: 20px;
}

.CommunityMembersSection__userGroupArrow {
    margin-left: auto;

    width: 15px !important;
    height: 15px !important;
}

.CommunityMembersSection__input {
    width: 100%;
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    outline: none;
    border: none;

    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/**

    CommunityPost

 */

.CommunityPost__commentsWidget {
    background-color: var(--primaryBackgroundColor);
    position: fixed;
    inset: 0;
    z-index: 4;
    overflow: scroll;
    touch-action: none;
    overscroll-behavior: none;

    transition: all .2s;

    animation-name: slidein;
    animation-duration: .3s;
    animation-timing-function: ease-out;
}

.CommunityPost__commentsWrapper {
    padding: 15px;

    overflow: scroll;
}

.CommunityPost__comments {
    display: grid;
    grid-gap: 15px;

    margin-bottom: 80px;
}

.CommunityPost__commentsBack {
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: 50%;
    box-shadow: var(--cardBoxShadow);
}

.CommunityPost__commentsBack > svg {
    padding: 5px;
    width: 35px !important;
    height: 35px !important;
}

@keyframes slidein {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

/**

    Community post input

 */

.CommunityPostInput {
    z-index: 2;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 80px;

    /*
    background-color: rgba(230, 230, 230, 0.7);
    backdrop-filter: blur(8px);
    */
}

.CommunityPostInput__inputWrapper {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 10px 10px 25px;
}

.CommunityPostInput__input {
    width: 100%;
    font-size: 18px;
    padding: 10px 15px;
    outline: none;
    border: none;
    border-radius: var(--borderRadiusCard);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    transition: all .2s;
}

.CommunityPostInput__input.error {
    outline: 2px solid var(--incorrectInputRed);
}

.CommunityPostInput__error {
    padding: 15px;
    position: absolute;
    bottom: 65px;
    opacity: 0.5;
}

.CommunityPostInput__button {
    display: grid;
    place-items: center;

    min-width: 46px;
    min-height: 46px;
    border-radius: 50%;
    background-color: var(--secondaryBackgroundColor);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    margin-left: auto;

    transition: all .1s;
}

.CommunityPostInput__button > svg {
    opacity: 0.8;
}

.CommunityPostInput__button:active {
    filter: brightness(0.9);
}

.CommunityPostInput__uploadedFiles {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    position: absolute;
    bottom: 100%;
    padding: 10px 10px 10px 20px;
    width: 100%;

    background-color: var(--accentColor);
    color: white;
}

.CommunityPostInput__uploadedFilesCancel {
    place-self: end;
    width: 40px !important;
    height: 40px !important;
    padding: 5px;
}

