
.MobilePostCommentWidget {
    display: flex;
}

.MobilePostCommentWidget input {
    background: var(--altBackgroundColor);
    flex: 1;
}

.MobilePostCommentWidget__uploadIll {
    color: var(--secondaryColor);
    display: grid;
    place-items: center;
    margin-left: 10px;
    justify-content: flex-end;
}

.MobilePostCommentWidget svg {
    --dim: 28px;
    height: var(--dim);
    width: var(--dim);
    cursor: pointer;
}
