.MobileVaultWatchDetailsWidget {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 10px;

    padding: 0 15px;
}

.MobileVaultWatchDetailsWidget__socials {
    display: flex;
    margin-bottom: -5px;
    margin-left: 10px;
}

.MobileVaultWatchDetailsWidget__social {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    margin-right: 15px;
}

.MobileVaultWatchDetailsWidget__more {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    margin-right: 10px;
    margin-left: auto;
}

.MobileVaultWatchDetailsWidget__social > svg,
.MobileVaultWatchDetailsWidget__more > svg {
    display: grid;
    place-items: center;
}

.MobileVaultWatchDetailsWidget > .MobileVaultWatchDetailsWidget__details,
.MobileVaultWatchDetailsWidget > .MobileVaultWatchDetailsWidget__description {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 15px;
    display: grid;
    grid-gap: 5px;
    place-items: center;
}

.MobileVaultWatchDetailsWidget__description {
    white-space: pre-wrap;
    padding: 20px;
}

.MobileVaultWatchDetailsWidget > .MobileVaultWatchDetailsWidget__details > * {
    text-align: center;
    line-height: 20px;
}

.MobileVaultWatchDetailsWidget__details__name {
    font-weight: 700;
}

.MobileVaultWatchDetailsWidget__details__currentPrice {
    line-height: 10px !important;
}

.MobileVaultWatchDetailsWidget__details__price {
    font-size: 20px;
}

.MobileAuctionDetailsWidget__details__price__local {
    font-size: 16px;
}

.MobileVaultWatchDetailsWidget__brand {
}

.MobileVaultWatchDetailsWidget__purchaseOptions {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 15px;
}

.MobileVaultWatchDetailsWidget__purchaseOptions > div {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    width: 100%;
    text-align: center;

    transition: all .25s;
}

.MobileVaultWatchDetailsWidget__purchaseOptions > div:hover,
.MobileVaultWatchDetailsWidget__purchaseOptions > div:active,
.MobileVaultWatchDetailsWidget__contactSeller:hover,
.MobileVaultWatchDetailsWidget__contactSeller:active,
.MobileVaultWatchDetailsWidget__placeBid:hover,
.MobileVaultWatchDetailsWidget__placeBid:active {
    opacity: 0.8;
    cursor: pointer;
}

.MobileVaultWatchDetailsWidget__contactSeller {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;
}

.MobileVaultWatchDetailsWidget__placeBid {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    position: sticky;
    top: calc(54px + 10px);
    z-index: 1;
}

.MobileVaultWatchDetailsWidget__seller__subscribeButton {
    background-color: var(--altBackgroundColor);
    border-radius: var(--borderRadiusCard);
    padding: 5px 15px;

    transition: all .25s;
}

.MobileVaultWatchDetailsWidget__seller__subscribeButton:active,
.MobileVaultWatchDetailsWidget__seller__subscribeButton:hover {
    opacity: 0.8;
    cursor: pointer;
}

.MobileVaultWatchDetailsWidget__bookmarkActive {
    color: var(--altAccentColor);
}

.MobileVaultWatchDetailsWidget__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;
    margin: 10px 0;
}
