/*
    Onboarding Navigation Widget
 */

.MobileOnboardingNavigationWidget {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 40px;
    height: calc(100vh - env(safe-area-inset-top, 0));
    width: 100%;

    background-color: white;
}

.MobileOnboardingNavigationWidget__header {
    display: grid;
    justify-content: start;
    align-content: center;
    grid-gap: 5px;
    margin-top: 70px;
    margin-bottom: 30px;
}

.MobileOnboardingNavigationWidget__title {
    font-size: 30px;
    line-height: 30px;

    padding-top: 10px;
}

.MobileOnboardingNavigationWidget__description {
    white-space: pre-wrap;
}

.MobileOnboardingNavigationWidget__bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.MobileOnboardingNavigationWidget__pageInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.MobileOnboardingNavigationWidget__pageInfoIndicator {
    width: 10px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    transition: all .2s;
}

.MobileOnboardingNavigationWidget__pageInfoIndicator.active {
    background-color: rgba(0, 0, 0, 0.7);
}

.MobileOnboardingNavigationWidget__page {
    flex-grow: 1;
}

.MobileOnboardingNavigationWidget__nextPage {
    display: flex;
    place-self: center end;
    gap: 10px;
}

.MobileOnboardingNavigationWidget__button {
    width: 50px;
    height: 50px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 25px;
    display: grid;
    place-items: center;

    transition: all .2s;
}

.MobileOnboardingNavigationWidget__button:active {
    opacity: 0.8;
}

.MobileOnboardingNavigationWidget__button.disable {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
}

.MobileOnboardingNavigationWidget__button.last {
    background-color: var(--accentColor);
    color: white;
}

/*
    Onboarding Page Widget
 */

.MobileOnboardingPageWidget {
    width: 100%;
    height: 100%;
    animation: fadePage .2s ease-in;
}

@keyframes fadePage {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*
    Onboarding Welcome Widget
 */

.MobileOnboardingWelcomeWidget {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
	padding-top: 80px;
}

.MobileOnboardingWelcomeWidget__wrapper {
    display: grid;
    grid-gap: 10px;
    margin-top: 40px;
    margin-bottom: 30px;
    justify-items: start;
}

.MobileOnboardingWelcomeWidget__separator {
    width: 100%;
    height: 1px;
    background-color: var(--secondaryColor);
    opacity: 0.1;
}

.MobileOnboardingWelcomeWidget__message {
    background-color: var(--primaryBackgroundColor);
    padding: 15px;
    border-radius: var(--borderRadiusCard);
    max-width: 80%;
}

.MobileOnboardingWelcomeWidget__message.right {
    place-self: end;
    background-color: var(--accentColor);
    color: white;
}

.MobileOnboardingWelcomeWidget__logo {
    width: 70%;
    color: #222;
}

.MobileOnboardingWelcomeWidget__journey {
    font-size: 20px;
    line-height: 20px;
    color: black;
    place-self: start;
}

.MobileOnboardingWelcomeWidget__welcome {
    font-size: 26px;
    line-height: 26px;
    text-align: center;
    border-bottom: 2px solid white;
    padding: 20px;
}

.MobileOnboardingWelcomeWidget__disclaimer {
}

.MobileOnboardingWelcomeWidget__skipAll {
    place-self: center end;
    opacity: 0.9;
    padding: 10px 15px;
	border-radius: var(--borderRadiusCard);
	background-color: var(--primaryBackgroundColor);
	box-shadow: var(--cardBoxShadow);
	font-size: 13px;
	
	transition: all .2s;
}

.MobileOnboardingWelcomeWidget__skipAll:active {
	opacity: 0.8;
	transform: scale(0.9);
}

/*
    Onboarding Input Widget
 */

.MobileOnboardingInputWidget {
    display: grid;
    grid-gap: 5px;
}

.MobileOnboardingInputWidget__label {
    margin-left: 10px;
}

.MobileOnboardingInputWidget__input {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: 1px solid var(--primaryBackgroundColor);

    font-size: 16px;
    line-height: 16px;
}

.MobileOnboardingInputWidget__input.error {
    border: 2px solid var(--orderStatusInvalid);
}

/*
    Onboarding Profile Picture Widget
 */

.MobileOnboardingProfilePictureWidget {
    position: relative;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
}

.MobileOnboardingProfilePictureWidget__previewImageWrapper {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.MobileOnboardingProfilePictureWidget__previewImage {
    display: grid;
    place-items: center;
    width: 275px;
    height: 275px;
    object-fit: cover;
    border-radius: 50%;
    padding: 5px;
    font-size: 100px;
    font-weight: bolder;

    background-color: #ebedef;

    border: 5px solid var(--primaryBackgroundColor);
}

.MobileOnboardingProfilePictureWidget__buttons {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.MobileOnboardingProfilePictureWidget__uploadButton {

}

/*
    Onboarding Tutorial Widget
 */

.MobileOnboardingTutorialWidget {}

.MobileOnboardingTutorialWidget__iframe {
    display: block;
    margin: -20px;
    width: 100vw;
    padding-bottom: 10px;
}

/*
    Onboarding Button Widget
 */

.MobileOnboardingButtonWidget {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    grid-gap: 10px;
    padding: 10px;
    height: 50px;

    background-color: var(--primaryBackgroundColor);
    border-radius: var(--borderRadiusCard);

    transition: all .2s;
}

.MobileOnboardingButtonWidget > svg {
    display: grid;
    place-items: center;
    position: absolute;
    margin-left: 7%;
}

.MobileOnboardingButtonWidget > p {
    place-self: center;
}

.MobileOnboardingButtonWidget.accent {
    background-color: var(--accentColor);
    color: white;
}

.MobileOnboardingButtonWidget:active {
    opacity: 0.7;
}

/*
    Onboarding Button Widget
 */

.MobileOnboardingErrorWidget {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    align-items: center;

    grid-gap: 10px;
    padding: 10px;

    background-color: var(--orderStatusInvalid);
    border-radius: 10px;
    color: white;

    transition: all .2s;
}

.MobileOnboardingErrorWidget > svg {
    display: grid;
    place-items: center;
    position: absolute;
    margin-left: 7%;
    width: 20px;
    height: 20px;
}

.MobileOnboardingErrorWidget > p {
    place-self: center;
}

/*
    Onboarding Alias Widget
 */

.MobileOnboardingAliasWidget {
    display: grid;
    grid-gap: 20px;
}

/*
   Onboarding Profile Widget
 */

.MobileOnboardingProfileWidget {
    display: grid;
    grid-gap: 20px;
}

/*
    Onboarding Selection Widget
 */

.MobileOnboardingSelectionWidget {
    padding: 20px 0;
    display: grid;
    grid-gap: 20px;
}

.MobileOnboardingSelectionWidget__selection {
    display: flex;
    align-items: center;
    gap: 10px;
}

.MobileOnboardingSelectionWidget__radio {
    display: grid;
    place-items: center;

    width: 24px;
    height: 24px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 50%;
}

.MobileOnboardingSelectionWidget__selected {
    width: 12px;
    height: 12px;
    background-color: var(--loaderBackground);
    opacity: 0.8;
    border-radius: 50%;
}

.connectBtnError {
    border: var(--incorrectInputRed) 3px solid;
}
