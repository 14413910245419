.MobileArticleLayout {
    overflow: hidden;
    background-color: var(--primaryBackgroundColor);
}

.MobileArticleLayout__content {
    position: absolute;
    inset: 0;

    margin-top: var(--toolbarHeight);

    overflow: hidden;
}

.MobileArticleLayout__iframe {
    width: 100%;
    height: 100%;
}

.MobileArticleLayout {
    height: 100%;
    width: 100%;
}
