.MobileAddWatchFormLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
    padding-bottom: 40px;
}

.AddWatchFormWidget {

}

.AddWatchFormWidget__buttons {
    display: grid;
    place-items: center;
    grid-gap: 20px;
    padding: 0 20px;
}

.AddWatchFormWidget__nextButton {
    background-color: var(--accentColor);
    color: white !important;
    border: var(--accentColor) 3px solid;
    box-shadow: var(--cardBoxShadow);
}

.AddWatchFormWidget__nextButton.disabled {
    background-color: var(--secondaryBackgroundColor);
    color: black !important;
    opacity: 0.4;
    border: none;
}

.AddWatchFormWidget__backButton {
    border: none;
    box-shadow: var(--cardBoxShadow);
}

.AddWatchFormPaymentSectionWidget__radio {
    user-select: none;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-row-gap: 5px;
    place-items: center start;
    margin-left: 10px;
}

.AddWatchFormPaymentSectionWidget__stripe {
    display: grid;
    grid-gap: 10px;

    margin-left: 10px;
    margin-top: -15px;
    margin-bottom: 20px;
}

.AddWatchFormPaymentSectionWidget__title {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
}

.AddWatchFormPaymentSectionWidget__button {
    padding: 10px 20px;
    border-radius: var(--borderRadiusCard);
    background-color: var(--accentColor);
    color: white;
    text-align: center;
}

.AddWatchFormPaymentSectionWidget__error {
    color: var(--incorrectInputRed);
    text-align: center;
    padding: 10px;
}

/**
    Section Widget
 */

.AddWatchFormSectionWidget {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding: 0 15px 15px;

    width: 100%;
}

.AddWatchFormSectionWidget > * {
    width: 100%;
}

.AddWatchFormSectionWidget > h1,
.AddWatchFormSectionWidget > h3 {
    margin-left: 10px;
}

.AddWatchFormSectionWidget > p {
    margin-left: 10px;
}

/**

    Market Section

 */

.AddWatchFormMarketSectionWidget__radio {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-row-gap: 5px;
    place-items: center start;
    margin-left: 10px;
}

.AddWatchFormMarketSectionWidget__price {
    position: relative;

    display: grid;
    place-items: center;
    grid-template-columns: 1fr 60px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;

    overflow: hidden;
}

.AddWatchFormMarketSectionWidget__price > input {
    padding: 15px 10px 15px 20px;

    font-size: 16px;

    outline: none;
    border: none;

    width: 100%;
}

.AddWatchFormMarketSectionWidget__price > h4 {
    position: absolute;
    right: 70px;

    color: var(--incorrectInputRed);
    font-size: 18px;

    line-height: 10px;
    margin-bottom: -5px;

    opacity: 0.6;
}

.AddWatchFormMarketSectionWidget__price.hidden, .AddWatchFormMarketSectionWidget__price.hidden > input[type="number"] {
    background-color: #FAFAFF;
    color: var(--altSeparatorColor);
}

.AddWatchFormMarketSectionWidget__priceCurrency {
    height: 100%;

    display: grid;
    place-items: center;

    width: 60px;

    border-left: 2px solid var(--primaryBackgroundColor);
}

/**
    Form Input
 */

.AddWatchFormInput {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;
}

.AddWatchFormInput__wrapper {
    position: relative;

    display: grid;
    place-items: center;
}

.AddWatchFormInput__rightWrapper {
    right: 10px;

    position: absolute;
    text-align: right;
    opacity: 0.6;

    display: grid;
    place-items: center;
    grid-auto-flow: column;
}

.AddWatchFormInput__rightWrapper > h4 {
    color: var(--incorrectInputRed);
    margin-left: 10px;
    font-size: 18px;

    line-height: 10px;
    margin-bottom: -5px;
}

/**
    Form Date Input
 */

.AddWatchFormDateInput {
    position: relative;
}

.AddWatchFormDateInput > input {
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;
}

.AddWatchFormPaymentSectionWidget > input[name="birthDay"] {
    padding: 15px 20px;

    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    background-color: var(--secondaryBackgroundColor);
    outline: none;
    border: none;
}

.AddWatchFormDateInput > h4 {
    position: absolute;

    right: 40px;
    top: 0;
    margin-right: 10px;
    height: 100%;

    display: grid;
    place-items: center;

    color: var(--incorrectInputRed);
    font-size: 18px;
    opacity: 0.6;
    margin-top: 3px;
}

    /**
        Form Dropdown
     */

.AddWatchFormDropdown {
    width: 100%;
}

/**
    Form Slider
 */

.AddWatchFormSlider {
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: 1fr 50px;
}

.AddWatchFormSlider__info {
    display: grid;
    grid-gap: 5px;
    margin-left: 5px;
}

.AddWatchFormSlider__title {
    font-weight: 600;
}

/**
    Simple Form Slider
 */

.AddWatchFormSimpleSlider {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddWatchFormSimpleSlider__info {
    display: grid;
    grid-gap: 10px;
}


/**
    Form Image Upload
 */

.AddWatchFormImagesSectionWidget__extraImageUploads {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    place-items: center;
    padding: 5px;
    height: 70px;
}

.AddWatchFormImagesSectionWidget__otherImages {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.AddWatchFormImagesSectionWidget__otherImage {
    position: relative;
    aspect-ratio: 1;
}

.AddWatchFormImagesSectionWidget__otherImageRemove {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    color: white;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    backdrop-filter: blur(3px);
}

.AddWatchFormImagesSectionWidget__otherImageRemove > svg {
    width: 10px;
    height: 10px;
}

.AddWatchFormImagesSectionWidget__otherImage > img {
    object-fit: cover;
    aspect-ratio: 1;

    border-radius: var(--borderRadiusCard);
}

.AddWatchFormImagesSectionWidget__upload {
    display: flex;
    gap: 10px;
    align-items: center;
}

.AddWatchFormImagesSectionWidget__uploadMain {
    display: grid;
    place-items: center;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
    box-shadow: var(--cardBoxShadow);

    transition: all .2s;
}

.AddWatchFormImagesSectionWidget__mainHelper {
    margin-top: 5px;
}

.AddWatchFormImagesSectionWidget__uploadMain:active {
    transform: scale(0.9);
    opacity: 0.8;
}

.AddWatchFormImagesSectionWidget__imageSection {
    display: grid;
    grid-gap: 10px;
    padding: 5px;
}

.AddWatchFormImagesSectionWidget__imageSection > h1 {
    margin-left: 0;
}

.AddWatchFormImagesSectionWidget__uploaded {
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
}

.AddWatchFormImagesSectionWidget__uploadedImage {
    position: relative;
    aspect-ratio: 1;
    background-color: white;
    overflow: hidden;
    border-radius: var(--borderRadiusCard);

    transition: all .1s;
}

.AddWatchFormImagesSectionWidget__uploadedImage > img {
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: var(--borderRadiusCard);
    border: none;
    background-color: white;
    box-shadow: var(--cardBoxShadow);
    outline: 0 solid var(--accentColor);
}

.AddWatchFormImagesSectionWidget__uploadedImage.main {
    border: 4px solid var(--accentColor);
}

.AddWatchFormImagesSectionWidget__info {
    display: grid;
    grid-gap: 5px;
    padding: 5px;
}

.AddWatchFormImagesSectionWidget__removeUploadedImage {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    color: white;
    display: grid;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    backdrop-filter: blur(3px);
}

.AddWatchFormImagesSectionWidget__removeUploadedImage > svg {
    width: 15px;
    height: 15px;
}

.AddWatchFormImage {
    display: grid;

    width: 100%;
    height: 100%;
}

.AddWatchFormImage.expand {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
}

.AddWatchFormImage__upload {
    width: 100%;
    height: 100%;

    overflow: hidden;

    padding: 2px;
    border-radius: 8px;
    border: 2px dotted var(--altSeparatorColor);
}

.AddWatchFormImage__upload.expand {
    width: 150px;
    height: 140px;
    margin-left: 5px;
}

.AddWatchFormImage__uploadButton {
    width: 100%;
    height: 100%;

    border-radius: 8px;
    display: grid;
    place-items: center;
}

.AddWatchFormImage__uploadButtonInfo {
    width: 100%;
    height: 100%;

    position: relative;
    display: grid;
    place-items: center;

    text-align: center;
}

.AddWatchFormImage__description {
    word-wrap: break-word;
    font-size: 11px;
}

.AddWatchFormImage__description.expand {
    position: absolute;
    padding: 0 10px;

    top: 20px;
}

.AddWatchFormImage__count {
    place-self: start center;
}

.AddWatchFormImage__uploadButtonInfo > svg {
    margin-top: 20px;
    place-self: center;
}

.AddWatchFormImage__input {
    display: none;
}

.AddWatchFormImage__icon {
    width: 80%;
    aspect-ratio: 1/1;
    place-self: end;
}

.AddWatchFormImage__icon > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}



/**
    Form Modification Picker
 */

.AddWatchFormModificationPicker {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 10px;

    position: relative;
}

.AddWatchFormModificationPicker::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.AddWatchFormModificationPicker > *:first-child{
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.AddWatchFormModificationPicker__modification {
    width: 100%;
    height: 100%;

    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);

    display: grid;
    place-items: center;

    box-sizing: border-box;

    border: 5px solid;
    border-color: var(--secondaryBackgroundColor);

    transition: all .2s;
}

.AddWatchFormModificationPicker__modification.selected {
    border-color: var(--accentColor);
}

.AddWatchFormModificationPicker__inputWrapper {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    margin-top: 20px;
}

.AddWatchFormInfoSectionWidget__sliders {
    display: grid;
    grid-gap: 15px;
}

/**
    Accordion
 */

.AddWatchFormAccordion {

}

.AddWatchFormAccordion__header {
    display: grid;
    padding: 5px;
    margin-bottom: 20px;
}

.AddWatchFormAccordion__headerTitle {
    display: flex;
    align-items: center;
}

.AddWatchFormAccordion__headerOptional {
    opacity: 0.8;
}

.AddWatchFormAccordion__content {
    display: none;
    grid-gap: 15px;

    transition: all .4s;
}

.AddWatchFormAccordion__content.active {
    display: grid;
}