.MobileConversationLayout {
    --loungeChatToolbarHeight: 60px;
    --loungeChatInputHeight: 90px;

    overflow: hidden;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
    overscroll-behavior: contain;
}

.MobileConversationLayout__wrapper {
    overflow: hidden;
    overscroll-behavior: contain;
}

/*
####### MobileLoungeChatToolbarWidget
 */

.MobileLoungeChatToolbarWidget {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    height: var(--loungeChatToolbarHeight);
    z-index: 2;

    position: fixed;
    top: env(safe-area-inset-top, 0);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;

    overflow: hidden;
    overscroll-behavior: contain;

    padding: 0 10px;
}

.MobileLoungeChatToolbarWidget :not(:last-child):not(.MobileLoungeChatToolbarWidget__img) {
    margin-right: 20px;
}

.MobileLoungeChatToolbarWidget__info {
	display: flex;
	align-items: center;
}

.MobileLoungeChatToolbarWidget__back {
    margin: 10px;
}

.MobileLoungeChatToolbarWidget__img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;

    margin-right: 10px;
}

.MobileLoungeChatToolbarWidget__details {
    display: flex;
    flex-direction: column;
}

.MobileLoungeChatToolbarWidget__details > .p2 {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 3px;
}

.MobileLoungeChatToolbarWidget__menu {
    margin: 10px 10px 10px auto;
}

/*
####### MobileLoungeChatWidget
 */

.MobileLoungeChatWidget {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    padding: 10px;

    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;

    margin-top: var(--loungeChatToolbarHeight);
    height: calc(100vh - var(--loungeChatInputHeight) - var(--loungeChatToolbarHeight) - env(safe-area-inset-top, 0));
}

.MobileLoungeChatWidget__divider {
    display: grid;
    place-items: center;
    padding: 10px;
    opacity: 0.7;
    font-weight: var(--fontWeightBold);
}

/*
####### MobileLoungeMessageWidget
 */

.MobileLoungeMessageWidget {
    display: flex;
    max-width: 80vw;
}

.MobileLoungeMessageWidget.self {
    justify-content: right;
    place-self: flex-end;
}

.MobileLoungeMessageWidget__imgWrapper {
    position: relative;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    overflow: hidden;

    place-self: flex-end flex-start;
}

.MobileLoungeMessageWidget__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MobileLoungeMessageWidget__messageDetails {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 7px;
    place-items: center;

    margin-left: 10px;
    margin-bottom: 5px;
}

.MobileLoungeMessageWidget__messageDetails.self {
    margin-right: 10px;
}

.MobileLoungeMessageWidget__fullName {
    opacity: 0.8;
}

.MobileLoungeMessageWidget__timestamp {
    opacity: 0.6;
}

.MobileLoungeMessageWidget__separator {
    font-size: 8px;
    opacity: 0.4;
}

.MobileLoungeMessageWidget__read {
    opacity: 0.6;
    width: 15px !important;
    height: 15px !important;
}

.MobileLoungeMessageWidget__messageWrapper {
    display: grid;
    justify-items: left;
}

.MobileLoungeMessageWidget__messageWrapper.self {
    justify-items: right;
}

.MobileLoungeMessageWidget__messageWrapper > :not(:last-child) {
    margin-bottom: 5px;
}

.MobileLoungeMessageWidget__messages {
    display: grid;
    justify-items: left;
}

.MobileLoungeMessageWidget__messages.self {
    justify-items: right;
}

.MobileLoungeMessageWidget__message {
    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
    padding: 10px 15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.MobileLoungeMessageWidget__message:not(:last-child):not(:first-child) {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: var(--borderRadiusCard);
    border-top-right-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__message.self:not(:last-child):not(:first-child) {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: var(--borderRadiusCard);
    border-top-left-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__message:not(:last-child) {
    border-bottom-left-radius: 6px;
}

.MobileLoungeMessageWidget__message:last-child {
    border-top-left-radius: 6px;
}

.MobileLoungeMessageWidget__message.self {
    background-color: var(--accentColor);
    color: white;
}

.MobileLoungeMessageWidget__message.self > .LinkParser > a {
    color: white;
    opacity: 0.8;
}

.MobileLoungeMessageWidget__message.self:not(:last-child) {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__message.self:last-child {
    border-top-right-radius: 6px;
    border-top-left-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__message:not(:last-child) {
    margin-bottom: 2px;
}

.MobileLoungeMessageWidget__message:only-child {
    border-bottom-left-radius: var(--borderRadiusCard);
    border-top-left-radius: var(--borderRadiusCard);
    border-bottom-right-radius: var(--borderRadiusCard);
    border-top-right-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__message.self:only-child {
    border-bottom-left-radius: var(--borderRadiusCard);
    border-top-left-radius: var(--borderRadiusCard);
    border-bottom-right-radius: var(--borderRadiusCard);
    border-top-right-radius: var(--borderRadiusCard);
}

.MobileLoungeMessageWidget__content {
    max-width: 80vw;
}

.MobileLoungeMessageWidget__sliderWrapper {
    overflow: hidden;
}

.MobileLoungeMessageWidget__sliderWrapper.fullscreen {
    position: fixed;
    z-index: 4;
    inset: 0;

    touch-action: pan-x pinch-zoom;
    -ms-touch-action: pan-x pinch-zoom;

    background: #111;
}

.MobileLoungeMessageWidget__indicator {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 8px;
}

.MobileLoungeMessageWidget__indicator.selected {
    background-color: var(--secondaryBackgroundColor);

    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.MobileLoungeMessageWidget__imageContainer {
    overflow: hidden;
}

.MobileLoungeMessageWidget__image {
    position: relative;
    object-fit: cover;
}

.MobileLoungeMessageWidget__image.fullscreen {
    object-fit: contain;
}

/*
####### MobileLoungeInputWidget
 */

.MobileLoungeInputWidget {
    z-index: 2;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    min-height: var(--loungeChatInputHeight);

    background-color: var(--secondaryBackgroundColor);
    opacity: 0.98;
}

.MobileLoungeInputWidget__multiline {
	position: relative;
	overflow-wrap: break-word;
	max-height: 8rem;
	overflow-y: scroll;
}

.MobileLoungeInputWidget__multilineWrapper {
	/* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
	display: grid;
	width: 100%;
	position: relative;
	overflow-wrap: break-word;
	max-height: 8rem;
	overflow-y: scroll;
}
.MobileLoungeInputWidget__multilineWrapper::after {
	/* Note the weird space! Needed to preventy jumpy behavior */
	content: attr(data-replicated-value) " ";
	
	/* This is how textarea text behaves */
	white-space: pre-wrap;
	overflow-wrap: break-word;
	
	/* Hidden from view, clicks, and screen readers */
	visibility: hidden;
}
.MobileLoungeInputWidget__multilineWrapper > textarea {
	/* You could leave this, but after a user resizes, then it ruins the auto sizing */
	resize: none;
	
	/* Firefox shows scrollbar on growth, you can hide like this. */
	overflow: hidden;
	overflow-wrap: break-word;
}
.MobileLoungeInputWidget__multilineWrapper > .MobileLoungeInputWidget__multiline,
.MobileLoungeInputWidget__multilineWrapper::after {
	/* Identical styling required!! */
	width: 100%;
	padding: 10px 15px;
	max-height: 8rem;
	overflow-y: scroll;
	outline: none;
	text-decoration: none;
	overflow-wrap: break-word;
	border: 1px solid var(--primaryBackgroundColor);
	border-radius: var(--borderRadiusCard);
	
	font: inherit;
	
	/* Place on top of each other */
	grid-area: 1 / 1 / 2 / 2;
}

.MobileLoungeInputWidget__inputWrapper {
	max-width: 100vw;
	width: 100vw;
	display: grid;
	grid-template-columns: 1fr 50px 50px;
    min-height: var(--loungeChatInputHeight);
	grid-gap: 10px;
	place-items: start;
    padding: 10px 10px 25px;
}

.MobileLoungeInputWidget__input {
    width: 100%;
    font-size: 18px;
    padding: 10px 15px;
    outline: none;
    border: 1px solid var(--primaryBackgroundColor);
    border-radius: var(--borderRadiusCard);

    transition: all .2s;
}

.MobileLoungeInputWidget__button {
    display: grid;
    place-items: center;

    min-width: 46px;
    min-height: 46px;
    border-radius: 50%;
    background-color: var(--primaryBackgroundColor);

    margin-left: auto;

    transition: all .1s;
}

.MobileLoungeInputWidget__button > svg {
    opacity: 0.8;
}

.MobileLoungeInputWidget__button:active {
    filter: brightness(0.9);
}

.MobileLoungeInputWidget__uploadedFiles {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: center;

    width: 100%;
    padding-left: 20px;

    background-color: var(--accentColor);
    color: white;
}

.MobileLoungeInputWidget__uploadedFilesCancel {
    place-self: end;
    width: 40px !important;
    height: 40px !important;
    padding: 10px;
}

/*
    Settings
 */

.MobileLoungeChatSettingsWidget {
    visibility: hidden;
    background-color: var(--secondaryBackgroundColor);
    position: absolute;
    inset: 0;
    top: env(safe-area-inset-top, 0);
    left: 100vw;
    z-index: 3;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    touch-action: none;
    overscroll-behavior: contain;

    transition: all .2s;
}

.MobileLoungeChatSettingsWidget.active {
    visibility: visible;
    left: 0;
}

.MobileLoungeChatSettingsWidget__header {
    position: relative;
    display: grid;
    grid-template-rows: 40px 1fr;
    padding: 20px;
}

.MobileLoungeChatSettingsWidget__top {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    place-items: center;
}

.MobileLoungeChatSettingsWidget__titleWrapper {
    display: grid;
    place-items: center;
    grid-gap: 3px;
    text-align: center;
}

.MobileLoungeChatSettingsWidget__headerButton {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.MobileLoungeChatSettingsWidget__bottom {
    position: relative;
    display: grid;
    place-items: center;
    margin-top: 20px;
}

.MobileLoungeChatSettingsWidget__bottomImageWrapper {
    display: grid;
    place-items: center;
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 70px;
}

.MobileLoungeChatSettingsWidget__bottomImage {
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    width: 140px;
    height: 140px;
    object-fit: cover;

    border-radius: 70px;
    border: 3px solid var(--loaderBackground);
}

.MobileLoungeChatSettingsWidget__changeImage {
    display: grid;
    place-items: center;

    position: absolute;

    bottom: 3px;
    right: 3px;

    width: 40px;
    height: 40px;

    background-color: var(--loaderBackground);
    color: white;
    border-radius: 50%;
}

.MobileLoungeChatSettingsWidget__changeImage.unset {
    width: 80px;
    height: 80px;
    bottom: unset;
    right: unset;
}

.MobileLoungeChatSettingsWidget__changeImage.unset > svg {
    width: 40px;
    height: 40px;
}

.MobileLoungeChatSettingsWidget__settingsWrapper {
    overflow: scroll;
    padding: 0 20px 20px;
}

.MobileLoungeChatSettingsWidget__settings {
    display: grid;
    grid-gap: 5px;
    margin-top: 20px;
}

.MobileLoungeChatSettingsWidget__settings > h4 {
    padding: 5px 0;
}

.MobileLoungeChatSettingsWidget__setting {
    padding: 10px 0;
}

.MobileLoungeChatSettingsWidget__setting.bad {
    color: var(--incorrectInputRed);
}

.MobileLoungeChatSettingsWidget__divider {
    width: 100%;
    height: 2px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 4px;
}

.MobileLoungeChatSettingsWidget__manageMembers {
    display: grid;
    grid-gap: 10px;
}

.MobileLoungeChatSettingsWidget__media {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.MobileLoungeChatSettingsWidget__links {
    display: grid;
    grid-gap: 10px;
}

.MobileLoungeChatSettingsWidget__sentLinks {
    font-weight: 700;
    font-size: 20px;
}

.MobileLoungeChatSettingsWidget__link {
    background-color: var(--accentColor);
    font-size: 13px !important;
    padding: 10px;
    color: white;
    border-radius: 10px;
}

.MobileLoungeChatSettingsWidget__sentImages {
    font-weight: 700;
    font-size: 20px;
}

.MobileLoungeChatSettingsWidget__images {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
}

.MobileLoungeChatSettingsWidget__image {
    aspect-ratio: 1;
}

.MobileLoungeChatSettingsWidget__image > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: var(--borderRadiusCard);
}

/*
    Receipts Widget
 */

.MobileLoungeReceiptsWidget {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
}

.MobileLoungeReceiptsWidget__image {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.MobileLoungeReceiptsWidget__image:not(:first-child) {
    margin-left: -3px;
}

.MobileLoungeReceiptsWidget__max {
    display: grid;
    place-items: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    font-size: 9px;
    line-height: 9px;
    min-width: 16px;
    height: 16px;
    border-radius: 5px;
    background-color: var(--loaderBackground);
    color: white;
}
