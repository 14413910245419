
.MobilePostCardLayout {
    color: var(--secondaryTextColor);
    background: var(--secondaryBackgroundColor);
    box-shadow: 0 0 8px 8px rgba(100, 100, 100, 0.015);

    overflow: hidden;
    border-radius: 25px;

    display: grid;
    grid-auto-flow: row;
}

.MobilePostCardLayout:hover {
    color: var(--secondaryTextColor);
}

/* Might be redundant */
.MobilePostCardLayout a:active, .MobilePostCardLayout a:visited {
    color: var(--secondaryTextColor);
}

.PostCardHeaderWidget--wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(24, 1fr);
    overflow: hidden;
    width: 90%;
}

.PostCardHeaderWidget {
    display: grid;
    place-items: center;
    height: 80px;
}

.PostCardHeaderWidget__leftSection {
    grid-column: 1/auto;
    height: 55px;
    width: 100%;
    display: grid;
    place-items: center;
}

.PostCardHeaderWidget__middleSection {
    height: 55px;
    display: flex;
    width: 100%;
    align-content: center;
    grid-column: 3/22;
}

.PostCardHeaderWidget__middleSection--wrapper {
    grid-column: 3/21;
    font-weight: bold;
    height: 55px;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
}

.PostCardHeaderWidget__rightSection {
    grid-column: 24/25;
}

.PostCardHeaderWidget__userImg {
    display: grid;
    place-items: center;
    border-radius: var(--borderRadiusRounded);
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.PostCardHeaderWidget__img {
    min-width: 100%;
    max-height: 40px;
    height: 100%;
    object-fit: cover;
}

.PostCardHeaderWidget__title {
    max-height: 37px;
    display: flex;
    align-items: center;
}

.PostCardHeaderWidget__title h5 {
    color: var(--primaryTextColor);
    width: 70ch;
}

.PostCardHeaderWidget__timestamp {
    font-size: 14px;
    color: var(--altTextColor);
	display: grid;
	grid-gap: 1px;
}

.PostCardContentTextWidget {
    color: var(--primaryTextColor);
    font-weight: lighter;
    width: 90%;
    margin: 0 5% 4% 5%;
    white-space: pre-wrap;
	word-break: break-word;
}

.PostCardContentImageWidget {
    display: grid;
    place-items: center;
    width: 100%;
    margin: 10px 0 1px 0;
}

.PostCardContentImageWidget img {
    display: grid;
    place-items: center;
    max-height: 720px;
}

.PostCardSep {
    border-top: var(--primaryBackgroundColor) 2px solid;
}

.PostCardFooterWidget--wrapper {
    display: grid;
    place-items: center;
}

.PostCardFooterWidget {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 12px 0;
    width: 95%;
}

.PostCardFooterWidget__item--wrapper {
    display: grid;
    place-items: center;
}

.PostCardFooterWidget__item {
    display: flex;
}

.PostCardFooterWidget__item p {
    margin-left: 10%;
    display: grid;
    place-items: center;
}

.PostCardFooterWidget__item > svg {
    width: 24px;
    height: 24px;
}

.PostCardFooterWidget__comments {
}

.PostCardFooterWidget__likes {
}

.PostCardFooterWidget__shares {
}

.PostCardFooterWidget--isActive {
    color: var(--accentColor);
}
