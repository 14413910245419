.MobileLoungeNotificationsWidget {
    padding-bottom: 100px;
}

/*
    Notification
 */

.MobileLoungeNotificationWidget {
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileLoungeNotificationWidget__img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: var(--borderRadiusCard);
    object-fit: cover;
}

.MobileLoungeNotificationWidget__title {
    font-weight: var(--fontWeightSemiBold);
}

.MobileLoungeNotificationWidget__details {
    display: grid;
    grid-gap: 3px;
}

.MobileLoungeNotificationWidget__details > p {

}

.MobileLoungeNotificationWidget__content {
    display: grid;
    place-items: center end;
}

.MobileLoungeNotificationWidget__content > svg {
    opacity: 0.6;
}

/*
    Friend notification
 */

.MobileLoungeFriendNotificationWidget {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileLoungeFriendNotificationWidget__img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: var(--borderRadiusCard);
    object-fit: cover;
}

.MobileLoungeFriendNotificationWidget__title {
    font-weight: var(--fontWeightSemiBold);
}

.MobileLoungeFriendNotificationWidget__details {
    display: grid;
    grid-gap: 3px;
}

.MobileLoungeFriendNotificationWidget__details > p {

}

.MobileLoungeFriendNotificationWidget__resolution {
    margin-left: auto;
    margin-right: 10px;
    display: grid;
    place-items: center;
    padding: 5px;
    background-color: var(--denyColor);
    color: white;
    border-radius: 50%;
}

.MobileLoungeFriendNotificationWidget__resolution > svg {
    width: 14px;
    height: 14px;
}

.MobileLoungeFriendNotificationWidget__resolution.accept {
    background-color: var(--acceptColor);
}

.MobileLoungeFriendNotificationWidget__buttons {
    margin-left: auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    place-items: center end;
}

.MobileLoungeFriendNotificationWidget__button {
    --acceptColor: #06c666;
    --denyColor: #f93939;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;

    color: white;

    transition: all .2s;
}

.MobileLoungeFriendNotificationWidget__button:active {
    transform: scale(0.9);
    filter: brightness(0.9);
}

.MobileLoungeFriendNotificationWidget__button.accept {
    background-color: var(--acceptColor);
}

.MobileLoungeFriendNotificationWidget__button.deny {
    background-color: var(--denyColor);
}

/*
    Community Invite Notification
 */

/*
    Friend notification
 */

.MobileLoungeCommunityInvitationNotificationWidget {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

    background-color: var(--secondaryBackgroundColor);
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileLoungeCommunityInvitationNotificationWidget__img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: var(--borderRadiusCard);
    object-fit: cover;
}

.MobileLoungeCommunityInvitationNotificationWidget__title {
    font-weight: var(--fontWeightSemiBold);
}

.MobileLoungeCommunityInvitationNotificationWidget__details {
    display: grid;
    grid-gap: 3px;
}

.MobileLoungeCommunityInvitationNotificationWidget__details > p {

}

.MobileLoungeCommunityInvitationNotificationWidget__resolution {
    margin-left: auto;
    margin-right: 10px;
    display: grid;
    place-items: center;
    padding: 5px;
    background-color: var(--denyColor);
    color: white;
    border-radius: 50%;
}

.MobileLoungeCommunityInvitationNotificationWidget__resolution > svg {
    width: 14px;
    height: 14px;
}

.MobileLoungeCommunityInvitationNotificationWidget__resolution.accept {
    background-color: var(--acceptColor);
}

.MobileLoungeCommunityInvitationNotificationWidget__buttons {
    margin-left: auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    place-items: center end;
}

.MobileLoungeCommunityInvitationNotificationWidget__button {
    --acceptColor: #06c666;
    --denyColor: #f93939;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;

    color: white;

    transition: all .2s;
}

.MobileLoungeCommunityInvitationNotificationWidget__button:active {
    transform: scale(0.9);
    filter: brightness(0.9);
}

.MobileLoungeCommunityInvitationNotificationWidget__button.accept {
    background-color: var(--acceptColor);
}

.MobileLoungeCommunityInvitationNotificationWidget__button.deny {
    background-color: var(--denyColor);
}
