


.MobileSignUpPasswordWidget {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-content: space-between;
}

.MobileSignUpPasswordWidget--wrapper {
    width: 100%;
    min-height: 100vh;
    background: var(--primaryBackgroundColor);
    padding: 20px;
    display: grid;
    grid-gap: 40px;
    place-items: center;
    align-content: center;
}

.MobileSignUpPasswordWidget__logo {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondaryTextColor);
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileSignUpPasswordWidget__logo svg {
    max-width: 110px;
    max-height: 29px;
}

.MobileSignUpPasswordWidget label {
    opacity: 0;
}


.MobileSignUpPasswordWidget h1 {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.MobileSignUpPasswordWidget input {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.05);
}

.MobileSignUpPasswordWidget button {
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    margin: 8px 0;
}

.MobileSignUpPasswordWidget .submitBtn {
    color: white;
    background: var(--accentColor);
    border: var(--accentColor) 3px solid;
}

.MobileSignUpPasswordWidget .backBtn {
    color: var(--primaryTextColor);
    background: var(--primaryBackgroundColor);
    border: none;
}

.MobileSignUpPasswordWidget__buttons--wrapper {
    margin-top: 60px;
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileSignUpPasswordWidget__inputContent {
    width: 100%;
}

.MobileSignUpPasswordWidget__inputContent .field {
    margin: 0;
}

.MobileSignUpPasswordWidget .errorBorder {
    border: var(--incorrectInputRed) 2px solid;
}

.MobileSignUpPasswordWidget .errorMsg {
    color: var(--incorrectInputRed);
    width: 100%;
    padding: 25px;
    text-align: center;
}


@keyframes untoggleLuaBtnHantle {
    0% {
        left: calc(50px - calc(var(--LuaBtnHeight) - 4px) - 1px);
    }
    100% {
        left: 0;
    }
}

@keyframes toggleLuaBtnHantle {
    0% {
        left: 1px;
    }
    100% {
        left: calc(50px - calc(var(--LuaBtnHeight) - 4px) - 1px);
    }
}

.MobileSignUpPasswordWidget__luaAgreement {
    --LuaBtnHeight: 30px;

    margin-top: 25px;
    padding: 0 15px;
    display: flex;
    width: 100%;
    height: var(--LuaBtnHeight);
}

.MobileSignUpPasswordWidget__luaAgreement p {
    flex: 1;
    margin-right: 25px;
}

.MobileSignUpPasswordWidget__signupAgreementWrapper {
    display: grid;
    grid-gap: 2px;
}

.MobileSignUpPasswordWidget__signupAgreement {
    text-align: center;
    opacity: 0.9;
}

.MobileSignUpPasswordWidget__signupAgreement--link {
    color: var(--accentColor);
    transition: all .2s;
}

.MobileSignUpPasswordWidget__signupAgreement--link:active {
    opacity: 0.6;
}
