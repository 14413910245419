.MobileAllArticlesLayout__wrapper {
    padding-top: 20px;
    padding-bottom: 50px;
    min-height: calc(100vh - env(safe-area-inset-top) - 20px);
    background-color: var(--primaryBackgroundColor);
}

.MobileLatestArticleWidget {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.MobileLatestArticleWidget__image {
    width: 100%;
    max-height: 230px;
    background-color: var(--primaryBackgroundColor);
    object-fit: cover;
    border-radius: var(--borderRadiusCard);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.MobileLatestArticleWidget__content {
    display: grid;
    grid-gap: 10px;
}

.MobileLatestArticleWidget__title {
    font-size: 15px;
}

.MobileLatestArticleWidget__ingress {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MobileLatestArticleWidget__contentWrapper {
    display: grid;
    grid-gap: 10px;
    padding: 15px;
}

.MobileLatestArticleWidget__from {
    display: flex;
    gap: 10px;
    align-items: center;
    opacity: 0.6;
}

.MobileLatestArticleWidget__publisher {
    font-size: 12px;
    line-height: 14px;
}

.MobileLatestArticleWidget__time {
    font-size: 12px;
    line-height: 14px;
    margin-left: auto;
}
