.MobileOfferingDetailsWidget {
    display: grid;
    grid-auto-columns: 1fr;
    grid-gap: 10px;

    padding: 0 15px;
}

.MobileOfferingDetailsWidget__socials {
    display: flex;
    margin-bottom: -5px;
    margin-left: 10px;
}

.MobileOfferingDetailsWidget__social {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    margin-right: 15px;
    width: 30px;
}

.MobileOfferingDetailsWidget__more {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4px;
    align-items: center;
    margin-right: 10px;
    margin-left: auto;
}

.MobileOfferingDetailsWidget__social > svg,
.MobileOfferingDetailsWidget__more > svg {
    display: grid;
    place-items: center;
}

.MobileOfferingDetailsWidget > .MobileOfferingDetailsWidget__details,
.MobileOfferingDetailsWidget > .MobileOfferingDetailsWidget__description {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 15px;
    display: grid;
    grid-gap: 5px;
    place-items: center;
}

.MobileOfferingDetailsWidget__description {
    white-space: pre-wrap;
    padding: 20px;
}

.MobileOfferingDetailsWidget > .MobileOfferingDetailsWidget__details > * {
    text-align: center;
    line-height: 20px;
}

.MobileOfferingDetailsWidget__details__name {
    font-weight: 700;
}

.MobileOfferingDetailsWidget__details__currentPrice {
    line-height: 10px !important;
}

.MobileOfferingDetailsWidget__details__price {
    font-size: 20px;
}

.MobileOfferingDetailsWidget__details__price__local {
    font-size: 16px;
}

.MobileOfferingDetailsWidget__brand {
}

.MobileOfferingDetailsWidget__purchaseOptions {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    grid-gap: 15px;
}

.MobileOfferingDetailsWidget__purchaseOptions > div {
    background-color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    width: 100%;
    text-align: center;

    transition: all .25s;
}

.MobileOfferingDetailsWidget__purchaseOptions > div:hover,
.MobileOfferingDetailsWidget__purchaseOptions > div:active,
.MobileOfferingDetailsWidget__contactSeller:hover,
.MobileOfferingDetailsWidget__contactSeller:active,
.MobileOfferingDetailsWidget__placeBid:hover,
.MobileOfferingDetailsWidget__placeBid:active {
    opacity: 0.8;
    cursor: pointer;
}

.MobileOfferingDetailsWidget__sellerWrapper {
    display: grid;
    grid-gap: 10px;
}

.MobileOfferingDetailsWidget__contactSeller {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;
}

.MobileOfferingDetailsWidget__placeBid {
    background-color: var(--accentColor);
    color: var(--secondaryBackgroundColor);
    box-shadow: var(--cardBoxShadow);
    border-radius: var(--borderRadiusCard);
    padding: 10px 15px;
    text-align: center;

    transition: all .25s;

    position: sticky;
    top: calc(54px + 10px);
    z-index: 1;
}

.MobileOfferingDetailsWidget__seller__subscribeButton {
    background-color: var(--altBackgroundColor);
    border-radius: var(--borderRadiusCard);
    padding: 5px 15px;

    transition: all .25s;
}

.MobileOfferingDetailsWidget__seller__subscribeButton:active,
.MobileOfferingDetailsWidget__seller__subscribeButton:hover {
    opacity: 0.8;
    cursor: pointer;
}

.MobileOfferingDetailsWidget__bookmarkActive {
    color: var(--altAccentColor);
}

.MobileOfferingDetailsWidget__divider {
    background-color: var(--altSeparatorColor);
    height: 1px;
    width: 85%;
    border-radius: 20px;
    place-self: center;
    margin: 10px 0;
}
