.MobileAllBrandsLayout {
    padding-bottom: 100px;
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
}

.MobileAllBrandsLayout__section {
    display: grid;
    grid-gap: 10px;
}

.MobileAllBrandsLayout__sectionTitle {
    margin-left: 10px;
}

.MobileAllBrandsLayout__sectionHeader {
    display: flex;
    align-items: center;
    padding-right: 10px;
    gap: 20px;
}

.MobileAllBrandsLayout__sectionDivider {
    height: 1px;
    width: 100%;
    background-color: var(--loaderBackground);
    opacity: 0.2;
}

.BrandsWidget {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    place-items: center;

    background-color: white;
    padding: 10px;
    border-radius: var(--borderRadiusCard);
    box-shadow: var(--cardBoxShadow);
}

.BrandsWidget__image {
    height: 50px;
    object-fit: contain;
}

.BrandsWidget__info {
    display: grid;
    place-items: center;
    grid-gap: 5px;
}

.BrandsWidget__subTitle {
    opacity: 0.5;
}
