.MobileAddWatchLayout {
    min-height: calc(100vh - env(safe-area-inset-top));
    background-color: var(--primaryBackgroundColor);
	padding-bottom: 50px;
}

.MobileAddWatchLayout__content {
}

.MobileAddWatchLayout__vault {
    padding: 20px;
}

.MobileAddWatchLayout__newWatch {
    position: sticky;
    top: calc(var(--toolbarHeight) + env(safe-area-inset-top, 0));
    z-index: 4;
    display: grid;
    place-items: center;
    padding: 20px 20px 0;
}

.MobileAddWatchLayout__newWatchButton {
    padding: 10px 20px;
    border-radius: var(--borderRadiusCard);
    background-color: var(--accentColor);
    box-shadow: var(--cardBoxShadow);
    border: none;
    color: var(--secondaryBackgroundColor);
    font-size: 14px;
    width: 100%;
}

/**
    All Brands
 */

.MobileAllBrandsWidget {
}

.MobileAllBrandsWidget__brandList {
    display: grid;
    margin: 10px 20px;
    grid-gap: 10px;
}

.MobileAllBrandsWidget__brand {
    display: grid;
    place-items: center start;

    padding: 15px 20px;
    width: 100%;
}

.MobileAllBrandsWidget__brand > p {
    width: 100%;
}

.MobileAllBrandsWidget__brand:not(:last-child) {
    border-bottom: 2px solid var(--primaryBackgroundColor);
}

/**
    Search bar
 */

.MobileAddWatchSearchWidget {
    top: calc(var(--toolbarHeight) + 50px + env(safe-area-inset-top, 0));

    z-index: 3;
    position: sticky;
    padding: 20px;
    width: 100%;

    display: grid;
}

.MobileAddWatchSearchWidget__input {
    width: 100%;
    padding: 10px 20px;

    border-radius: var(--borderRadiusCard);
    outline: none;
    border: none;

    box-shadow: rgba(0, 0, 0, 0.01) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

/**
    Search View
 */

.MobileAddWatchSearchView__results {
    padding: 20px;
    display: grid;
    grid-gap: 10px;
}

/**
    Expanded Search View
 */

.MobileAddWatchExpandedSearch {
    display: grid;
    padding: 20px;
    grid-gap: 10px;
}
