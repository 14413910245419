.MobileLoginRequiredPromptTextWidget {
    width: 100%;
    display: grid;
    place-items: center;
    margin: 15px 0;
}

.MobileLoginRequiredPromptButtonWidget .loginBtn {
    color: white;
    background: var(--accentColor);
    box-shadow: 0 2px 18px 7px rgba(100, 100, 100, 0.08);
    border: var(--accentColor) 3px solid;
}